import ServiceBase from '../services/ServiceBase';
import BaseUrlConstants from '../constants/BaseUrlConstants';

const URL = `${BaseUrlConstants.BASE_URL}`;

class SkillService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Get a skill by unique identifier.
     * @param {number} skillId The identifier of the skill.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getById(skillId) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}skills/${skillId}`,
            method: 'GET',
        });
    }

    /**
     * Get skills of a user.
     * @param {number} userId The identifier of the user.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getByUser(userId) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}users/${userId}/skills`,
            method: 'GET',
        });
    }

    /**
     * Get all unarchived skills.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getAll() {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}skills?archived=false`,
            method: "GET"
        });
    }

    /**
     * Get all archived skills.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getAllArchived() {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}skills?archived=true`,
            method: "GET"
        });
    }

    /**
     * Post a new skill.
     * @param {Object} skill The skill to create.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    post(skill) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}skills`,
            method: 'POST',
            data: skill,
        });
    }

    /**
     * Archive or unarchive an skill.
     * @param {number} skillId The identifier of the skill to archive or unarchive.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    archiving(skillId) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}skills/${skillId}/archiving`,
            method: "POST"
        });
    }

    /**
     * Patch an existing resource. Only the properties that are set on the patch will be updated.
     * @param {number} id The identifier of the report.
     * @param {Object} patch The properties to update.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    patch(id, patch) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}skills/${id}`,
            method: 'PATCH',
            data: patch,
        });
    }

    /**
     * Delete an existing host skill.
     * @param {number} id The identifier of the user.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    remove(id) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}skills/${id}`,
            method: 'DELETE',
        });
    }
}

const service = new SkillService();
export default service;