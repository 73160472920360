import dispatcher from "../dispatchers/AppDispatcher";
import ActionsBase from "../actions/ActionsBase";

import IncidentConstants from "../constants/IncidentConstants";
import IncidentService from "../services/IncidentService";

import CacheManager from "../services/CacheManager";

const CACHE_DURATION = 30 * 1000;

class IncidentActions extends ActionsBase {
    create = (incident) => {
        const $this = this;
        return new Promise((resolve, reject) => {
                function handleSuccess(newIncident) {

                    dispatcher.dispatch({
                        type: IncidentConstants.RECEIVE_INCIDENT,
                        payload: {
                            incident: newIncident
                        }
                    });
                    resolve(newIncident);
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                IncidentService.post(incident)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };

    edit = (incidentId, incident) => {
        const $this = this;
        return new Promise(
            (
                resolve,
                reject
            ) => {
                function handleSuccess(newIncident) {
                    dispatcher.dispatch({
                        type: IncidentConstants.RECEIVE_INCIDENT,
                        payload: {
                            incident: newIncident
                        }
                    });
                    resolve(newIncident);
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                IncidentService.patch(incidentId, incident)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };

    delete = (id) => {
        const $this = this;
        return new Promise(
            (resolve, reject) => {
                function handleSuccess() {
                    dispatcher.dispatch({
                        type: IncidentConstants.DELETE_INCIDENT,
                        payload: {
                            id
                        }
                    });
                    resolve();
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                IncidentService.remove(id)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };
    
    reload = () => {
        const $this = this;
        return new Promise((resolve, reject) => {
          function handleSuccess(incidents) {
            dispatcher.dispatch({
              type: IncidentConstants.RECEIVE_INCIDENTS,
              payload: {
                incidents
              }
            });
            resolve();
          }
          function handleError(err) {
            $this.handleError(err, reject);
          }
          IncidentService.getAll()
            .then(handleSuccess)
            .catch(handleError);
        });
    };

    reloadArchived = () => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(incidents) {
                dispatcher.dispatch({
                    type: IncidentConstants.RECEIVE_ARCHIVED_INCIDENTS,
                    payload: {
                        incidents,
                    },
                });
                resolve();
            }
            function handleError(err) {
                $this.handleError(err, reject);
            }
            IncidentService.getAllArchived()
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadByUser = (user) => {
        const $this = this;
        return new Promise(
            (resolve, reject) => {
                if (
                    CacheManager.isCached(
                        "Incident:reloadByUser",
                        user.id.toString(),
                        CACHE_DURATION
                    )
                ) {
                    resolve();
                    return;
                }

                function handleSuccess(incidents) {
                    dispatcher.dispatch({
                        type: IncidentConstants.RECEIVE_INCIDENTS_OF_USER,
                        payload: {
                            user,
                            incidents
                        }
                    });
                    resolve();
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                IncidentService.getByUser(user.id)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };

    reloadByMission = (mission) => {
        const $this = this;
        return new Promise(
            (resolve, reject) => {
                if (
                    CacheManager.isCached(
                        "Incident:reloadByMission",
                        mission.id.toString(),
                        CACHE_DURATION
                    )
                ) {
                    resolve();
                    return;
                }

                function handleSuccess(incidents) {
                    dispatcher.dispatch({
                        type: IncidentConstants.RECEIVE_INCIDENTS_OF_MISSION,
                        payload: {
                            CACHE_DURATION,
                            incidents
                        }
                    });
                    resolve();
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                IncidentService.getByMission(mission.id)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };

    reloadById = (id) => {
        const $this = this;
        return new Promise(
            (resolve, reject) => {
                function handleSuccess(incident) {
                    dispatcher.dispatch({
                        type: IncidentConstants.RECEIVE_INCIDENT,
                        payload: {
                            incident
                        }
                    });
                    resolve();
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                IncidentService.getById(id)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };
}

const action = new IncidentActions();
export default action;