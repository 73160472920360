import ServiceBase from "../services/ServiceBase";
import BaseUrlConstants from "../constants/BaseUrlConstants";
import TokenContainer from "services/TokenContainer";

const URL = `${BaseUrlConstants.BASE_URL}`;

class ReportService extends ServiceBase {
  constructor() {
    super(URL);
  }

  /**
   * Get a report by unique identifier.
   * @param {number} reportId The identifier of the report.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getById(reportId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}reports/${reportId}`,
      method: "GET"
    });
  }

  /**
   * Get reports of a user.
   * @param {number} userId The identifier of the user.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getByUser(userId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}users/${userId}/reports`,
      method: "GET"
    });
  }

  /**
   * Get reports of a user.
   * @param {number} missionId The identifier of the mission.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getByMission(missionId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}missions/${missionId}/reports`,
      method: "GET"
    });
  }

  /**
     * Get all unarchived reports.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getAll() {
      return this.execute({
          url: `${BaseUrlConstants.BASE_URL}reports?archived=false`,
          method: "GET"
      });
  }

  /**
   * Get all archived reports.
   *
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getAllArchived() {
      return this.execute({
          url: `${BaseUrlConstants.BASE_URL}reports?archived=true`,
          method: "GET"
      });
  }

  /**
   * Post a new report to an user.
   * @param {Object} report The report to create.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  post(report) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}reports`,
      method: "POST",
      data: report
    });
  }

  /**
   * Patch an existing resource. Only the properties that are set on the patch will be updated.
   * @param {number} reportId The identifier of the report.
   * @param {Object} patch The properties to update.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  patch(reportId, patch) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}reports/${reportId}`,
      method: "PATCH",
      data: patch
    });
  }

  /**
   * Delete an existing report.
   * @param {number} reportId The identifier of the report.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  remove(reportId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}reports/${reportId}`,
      method: "DELETE"
    });
  }

  /**
   * @param  {number} reportId The ID of the mission.
   * @return {[type]}            [description]
   */
  download(reportId) {
    const token = TokenContainer.get().replace(/[+]/g, "%2B");
    const url = `${BaseUrlConstants.BASE_URL}reports/${reportId}/download`;
    window.location = `${url}?X-Auth-Token=${token}`;
  }
}

const service = new ReportService();
export default service;