import React, { useEffect, useMemo, useState } from "react";
import ReviewForm from "./ReviewForm";
import { Modal } from "antd";
import ReviewService from 'services/ReviewService';
import DateService from "services/utils/DateService";
import ReviewActions from "actions/ReviewActions";

interface IProps {
    reviewId: number
    onCancel: () => void
    visible: boolean
}

const EditReviewModal: React.FC<IProps> = ({
    reviewId,
    onCancel,
    visible
}) => {
    const [review, setReview] = useState<any | null>(null);

    useEffect(() => {
        async function loadData() {
            const review = await ReviewService.getById(reviewId);
            setReview(review);
        }
        loadData();
    }, [reviewId]);

    const initialValues = useMemo(() => {
        if (review) {
            return {
                ...review,
                user: review.user.id,
                article: review
                    .article
                    .toSorted((a: any, b: any) => a.articleOrder > b.articleOrder ? 1 : -1)
                    .map((a: any) => ({ title: a.title, content: a.content, hasGoals: a.hasGoals, articleOrder: a.articleOrder }))
            };
        }
        return undefined;
    }, [review]);

    async function handleSubmit(values: any) {
        await ReviewService.patch(review.id,
            {
                ...values,
                date: DateService.formatPostApi(values.date)
            });
        ReviewActions.reloadByMission({ id: review.mission.id });
        onCancel();
    }

    if (!review) {
        return null;
    }


    return (
        <Modal
            title={`${review.title} - ${review.user.firstName} ${review.user.lastName}`}
            open={visible}
            onCancel={onCancel}
            footer={null}
        >
            <ReviewForm
                missionId={review.mission.id}
                initialValues={initialValues}
                onSubmit={handleSubmit}
            />
        </Modal>
    );
}

export default EditReviewModal;