import EntityStoreBase from "../stores/EntityStoreBase";
import ArticleConstants from "../constants/ArticleConstants";

class ArticleStore extends EntityStoreBase {
  getInitialState() {
    return {
      entities: []
    };
  }

  reduce(state, action) {
    const { type, payload } = action;
    let newState = Object.assign({}, state);

    switch (type) {
      case ArticleConstants.RECEIVE_ARTICLES_OF_REVIEW:
        newState = this._receiveByReview(
          state,
          payload.review,
          payload.articles
        );
        break;
      case ArticleConstants.RECEIVE_ARTICLES_OF_REPORT:
        newState = this._receiveByReport(
          state,
          payload.report,
          payload.articles
        );
        break;
      case ArticleConstants.RECEIVE_ARTICLES_OF_INCIDENT:
        newState = this._receiveByIncident(
          state,
          payload.incident,
          payload.articles
        );
        break;
      case ArticleConstants.RECEIVE_ARTICLE:
        newState = this._receiveEntity(state, payload.article);
        break;
      case ArticleConstants.RECEIVE_ARTICLES:
        newState = this._receiveMany(state, payload.articles);
        break;
      case ArticleConstants.DELETE_ARTICLE:
        newState = this._deleteOne(state, payload.id);
        break;
      case ArticleConstants.CHANGE_CURRENT_ARTICLE:
        newState.currentArticleId = payload.articleId;
        break;
      case "clearAll":
        newState = this.getInitialState();
        break;
      default:
        newState = state;
    }
    return newState;
  }

  _receiveByReview = (state, review, articles) => {
    let newState = Object.assign({}, state);
    newState.entities = state.entities.filter(
      e => articles.findIndex(a => e.id === a.id) === -1
    );
    newState.entities.push(...articles);

    return newState;
  };

  _receiveByReport = (state, report, articles) => {
    let newState = Object.assign({}, state);
    newState.entities = state.entities.filter(
      e => articles.findIndex(a => e.id === a.id) === -1
    );
    newState.entities.push(...articles);

    return newState;
  };

  _receiveByIncident = (state, incident, articles) => {
    let newState = Object.assign({}, state);
    newState.entities = state.entities.filter(
      e => articles.findIndex(a => e.id === a.id) === -1
    );
    newState.entities.push(...articles);

    return newState;
  };

  _receiveMany = (state, articles) => {
    let newState = Object.assign({}, state);
    // We remove all the entities that have the same ID as the new articles
    newState.entities = state.entities.filter(
      e => articles.findIndex(a => e.id === a.id) === -1
    );
    newState.entities.push(...articles);
    return newState;
  };

  _deleteOne = (state, articleId) => {
    const i = state.entities.findIndex(c => c.id === articleId);
    if (i === -1) {
      return state;
    }
    let newState = Object.assign({}, state);
    newState.entities = state.entities.slice();
    newState.entities.splice(i, 1);
    return newState;
  };

  ///// Public Methods  /////

  getArticle() {
    return this.getState().article;
  }

  /**
   * Get all the articles of a report.
   */
  getByReport = report => {
    return this.getState().entities.filter(
      e => e.report && e.report.id === report.id
    );
  };

  /**
   * Get all the articles of a incident.
   */
  getByIncident = incident => {
    return this.getState().entities.filter(
      e => e.incident && e.incident.id === incident.id
    );
  };

  /**
   * Get all the articles of a review.
   */
  getByReview = review => {
    return this.getState().entities.filter(
      e => e.review && e.review.id === review.id
    );
  };

  /**
   * Get a article by ID.
   * @param {number} id The identifier.
   * @return {?Article} The article, or NULL if no entity is found.
   */
  getById = id => {
    return this.getState().entities.find(e => e.id === id);
  };

  /**
   * Get all the articles.
   */
  getAll = () => this.getState().entities;

  /**
   * Get the identifier of the current article.
   * @return {[type]} [description]
   */
  getCurrentArticleId = () => {
    return (
      this.getState().currentArticleId ||
      (this.getState().entities.length ? this.getState().entities[0].id : null)
    );
  };

  /**
   * Get the identifier of the current article.
   * @return {[type]} [description]
   */
  getCurrentArticle = () => this.getById(this.getCurrentArticleId());
}

const store = new ArticleStore();
export default store;