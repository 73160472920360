import ArchivedEntityStoreBase from "../stores/ArchivedEntityStoreBase";
import SubSkillConstants from "../constants/SubSkillConstants";

class SubSkillStore extends ArchivedEntityStoreBase {
    getInitialState() {
        return {
            currentSubSkillId: null,
            entities: [],
            archived: [],
        };
    }

    reduce(state, action) {
        const { type, payload } = action;
        let newState = Object.assign({}, state);

        switch (type) {
            case SubSkillConstants.RECEIVE_SUB_SKILLS_OF_SKILL:
                newState = this._receiveBySkill(
                    state,
                    payload.skill,
                    payload.subSkills
                );
                break;
            case SubSkillConstants.RECEIVE_SUB_SKILLS_OF_USER_HAS_SKILL:
                newState = this._receiveByUserHasSkill(
                    state,
                    payload.userHasSkill,
                    payload.subSkills
                );
                break;
            case SubSkillConstants.RECEIVE_SUB_SKILL:
                newState = this._receiveEntity(state, payload.subSkill);
                break;
            case SubSkillConstants.RECEIVE_ARCHIVED_SUB_SKILL:
                newState = this._receiveArchivedEntity(state, payload.subSkill);
                break;
            case SubSkillConstants.RECEIVE_SUB_SKILLS:
                newState = this._receiveMany(state, payload.subSkills);
                break;
            case SubSkillConstants.RECEIVE_ALL_SUB_SKILLS:
                newState = this._receiveAll(state, payload.subSkills);
                break;
            case SubSkillConstants.RECEIVE_ARCHIVED_SUB_SKILLS:
                newState = this._receiveArchived(state, payload.subSkills);
                break;
            case SubSkillConstants.CHANGE_CURRENT_SUB_SKILL:
                newState.currentSubSkillId = payload.subSkillId;
                break;
            case "clearAll":
                newState = this.getInitialState();
                break;
            default:
                newState = state;
        }
        return newState;
    }

    _receiveBySkill = (state, skill, subSkills) => {
        let newState = Object.assign({}, state);
        newState.entities = state.entities.filter(
            e => subSkills.findIndex(a => e.id === a.id) === -1
        );
        newState.entities.push(...subSkills);
        return newState;
    };

    _receiveByUserHasSkill = (state, userHasSkill, subSkills) => {
        let newState = Object.assign({}, state);
        newState.entities = state.entities.filter(
            e => subSkills.findIndex(a => e.id === a.id) === -1
        );
        newState.entities.push(...subSkills);
        return newState;
    };

    _receiveMany = (state, subSkills) => {
        let newState = Object.assign({}, state);
        // We remove all the entities that have the same ID as the new skills
        newState.entities = state.entities.filter(
            e => subSkills.findIndex(a => e.id === a.id) === -1
        );
        newState.entities.push(...subSkills);
        return newState;
    };

    _receiveArchived = (state, subSkills) => {
        let newState = Object.assign({}, state);
        // We remove all the entities that have the same ID as the new sub skills
        newState.archived = state.archived.filter(
            e => subSkills.findIndex(a => e.id === a.id) === -1
        );
        newState.archived.push(...subSkills);
        return newState;
      };

    ///// Public Methods  /////

    getSubSkill() {
        return this.getState().subSkill;
    }

    /**
     * Get a sub skill by ID.
     * @param {number} id The identifier.
     * @return {?Skill}    The sub skill, or NULL if no entity is found.
     */
    getById = (id) => {
        return this.getState().entities.find(e => e.id === id);
    };

    /**
     * Get an archived sub skill by ID.
     * @param {number} id The identifier of the archived sub skill.
     * @return {?SubSkill}    The archived sub skill, or NULL if no entity is found.
     */
    getArchivedById = id => this.getState().archived.find(e => e.id === id);

    /**
     * Get all the sub skills of a skill.
     */
    getBySkill = (skill) => {
        return skill ? this.getState().entities.filter(e => e.skill.id === skill.id) || [] : [];
    };

    /**
     * Get all the sub skills of a user has skill.
     */
    getByUserHasSkill = (userHasSkill) => {
        return userHasSkill ? this.getState().entities.filter(e => e.user_has_skill.length > 0) || [] : [];
    };
        
    /**
     * Get all the sub skills.
     */
    getAll = () => this.getState().entities.concat(this.getState().archived);

    /**
     * Get unarchived sub skills.
     */
    getSubSkills = () => this.getState().entities;

    getArchivedSubSkills = () => this.getState().archived;

    /**
     * Get the identifier of the current sub skill.
     * @return {[type]} [description]
     */
    getCurrentSubSkillId = () => {
        return (
            this.getState().currentSubSkillId ||
            (this.getState().entities.length
                ? this.getState().entities[0].id
                : null)
        );
    };

    /**
     * Get the identifier of the current sub skill.
     * @return {[type]} [description]
     */
    getCurrentSubSkill = () =>
        this.getById(this.getCurrentSubSkillId());
}

const store = new SubSkillStore();
export default store;