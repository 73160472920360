let caches = {};

const CacheManager = {
    isCached: (cacheName, key, cacheDuration) => {
        if (!caches[cacheName]) {
            caches[cacheName] = {};
        }

        const validityDate = caches[cacheName][key]
            ? caches[cacheName][key] + (cacheDuration || 0)
            : 0;
        const now = Date.now();
        const ic = validityDate > now;
        if (!ic) {
            caches[cacheName][key] = now;
        }
        return ic;
    },

    clear: () => {
        caches = {};
    },
};

export default CacheManager;
