import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import frFR from 'antd/locale/fr_FR';
import { ConfigProvider as AppLocaleProvider} from './locale/LocaleFactory';

import LoginActions from './actions/LoginActions';

import Layout from './views/Layout';
import Login from './views/Login';
import ResetPassword from './views/ResetPassword';
import ResetPasswordRequest from './views/ResetPasswordRequest';

import ProtectedRoute from './components/ProtectedRoute';

import 'antd/dist/reset.css';
import "styles/style.scss";

const routes = (
    <ConfigProvider locale={frFR}>
        <AppLocaleProvider>
            <Router>
                <Routes>
                    {/* @ts-ignore */}
                    <Route exact path="/login" element={<Login />} />
                    {/* @ts-ignore */}
                    <Route exact path="/reset-password" element={<ResetPassword />} />
                    {/* @ts-ignore */}
                    <Route exact path="/reset-password-request" element={<ResetPasswordRequest />} />
                    <Route path="/*" element={<ProtectedRoute element={<Layout />} />} />
                </Routes>
            </Router>
        </AppLocaleProvider>
    </ConfigProvider>
);

LoginActions.loginUserIfRemembered();

//ReactDOM.render(routes, app);
export default routes;