const values = {
    RECEIVE_USER: 'RECEIVE_USER',
    RECEIVE_USERS: 'RECEIVE_USERS',
    RECEIVE_INTERNS: 'RECEIVE_INTERNS',
    RECEIVE_ARCHIVED_USER: 'RECEIVE_ARCHIVED_USER',
    RECEIVE_ARCHIVED_USERS: 'RECEIVE_ARCHIVED_USERS',
    RECEIVE_ARCHIVED_INTERNS: 'RECEIVE_ARCHIVED_INTERNS',
    CHANGE_CURRENT_USER: 'CHANGE_CURRENT_USER',
    RECEIVE_USERS_OF_MISSION: 'RECEIVE_USERS_OF_MISSION',
};
export default values;