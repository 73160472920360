import React from "react";

import { Table, Button, Tooltip, Menu, Dropdown } from "antd";
import TableColumnFilter from "components/TableColumnFilter.jsx";
import FilterIcon from "components/FilterIcon.jsx";
import CreateMissionModal from "views/Mission/CreateMissionModal.jsx";
import EditMissionModal from "views/Mission/EditMissionModal.jsx";
import MissionEmargementsModal from "views/Mission/MissionEmargementsModal.jsx";
import LoadingIcon from "components/LoadingIcon.jsx";
import ToastActions from "actions/ToastActions";
import SelectMissionDocumentsToPrintModal from "./SelectMissionDocumentsToPrintModal.jsx";
import SelectEmargementsPeriodModal from "components/SelectEmargementsPeriodModal.jsx";

import Locale, { Locale as LocaleComponent } from "locale/LocaleFactory";
import ArrayService from "services/utils/ArrayService";
import StringService from "services/utils/StringService";
import FilterService from "services/utils/FilterService";
import SecurityService from "services/SecurityService";
import Access from "constants/AccessLevel";
import Resource from "constants/Resource";

import MissionActions from "actions/MissionActions";
import MissionStore from "stores/MissionStore";
import OrganisationStore from "stores/OrganisationStore";
import { CheckOutlined, CopyOutlined, EditOutlined, EyeInvisibleOutlined, EyeOutlined, PlusOutlined, PrinterOutlined } from "@ant-design/icons";
import ExportEmargementsModal from "./ExportEmargementsModal.tsx";

// Sorting Methods
function sortNameColumn(c1, c2) {
  return StringService.compareCaseInsensitive(c1.name, c2.name);
}

function sortTypeColumn(m1, m2) {
  const s1 = m1.type;
  const s2 = m2.type;
  return StringService.compareCaseInsensitive(s1, s2);
}

/**
 * The list of the missions.
 */
export default class MissionList extends React.Component {
  constructor(props) {
    super(props);
    const missions = MissionStore.getMissions();

    this.state = {
      loading: !missions.length,
      missions,

      filteredMissions: [],
      filters: {
        name: [],
        type: [],
      },

      createMissionVisible: false,
      editMissionVisible: false,
      missionToEdit: null,

      displayEmargementsOf: null,
      archivedVisible: false,
      selectedRowKeys: [],
      concernedMission: null,
      selectMissionDocumentsToPrintModalVisible: false,
      selectEmargementsPeriodModalVisible: false,
      emargementsGroupedBy: "",
    };
  }

  componentDidMount() {
    this.hostMissionListener = MissionStore.addListener(this.receiveMissions);
    this.organisationListener = OrganisationStore.addListener(
      this.changeOrganisation
    );
    this.archivedMissionListener = MissionStore.addListener(this.receiveArchivedMissions);
    this.loadMissions();
    this.updateFilters();
    // Here we set the default sorted column
    // Temporary solution waiting for AntD to propose a native way to do it.
    /*const column = this.table.findColumn("reference");
    this.table.toggleSortOrder("descend", column);*/
  }

  componentWillUnmount() {
    this.hostMissionListener.remove();
    this.organisationListener.remove();
    this.archivedMissionListener.remove();
  }

  loadMissions = () => {
    this.setState({
      loading: true
    });
    MissionActions.reload().then(() => {
      this.setState({
        loading: false,
        archivedVisible: false,
        selectedRowKeys: [],
      });
    });
    this.hostMissionListener = MissionStore.addListener(this.receiveMissions);
  };

  loadArchivedMissions = () => {
    this.setState({
      loading: true,
    });
    MissionActions.reloadArchived().then(() => {
      this.setState({
        loading: false,
        archivedVisible: true,
        selectedRowKeys: [],
      });
    });
    this.archivedMissionListener = MissionStore.addListener(this.receiveArchivedMissions);
  };

  receiveMissions = () => {
    this.setState(
      {
        missions: MissionStore.getMissions()
      },
      this.updateFilters
    );
  };

  receiveArchivedMissions = () => {
    this.setState(
      {
        missions: MissionStore.getArchivedMissions()
      },
      this.updateFilters
    );
  };

  // Filters
  updateFilters = () => {
    const { missions } = this.state;
    const filteredMissions = missions.filter(this.missionMatchFilters);
    this.setState({ filteredMissions });
  };

  missionMatchFilters = e => {
    const { filters } = this.state;
    return (
      FilterService.matchFilter(filters.name, e.name) &&
      FilterService.matchFilter(filters.type, e.type)
    );
  };


  handleFilterChange = (name, values) => {
    let prevState = this.state.filters;
    prevState[name] = values;
    this.setState({ filters: prevState });
    this.updateFilters();
  };

  getMissionNames = () =>
    ArrayService.unique(this.state.missions.filter(c => !!c).map(c => c.name));

  getMissionTypes = () =>
    ArrayService.unique(this.state.missions.filter(c => !!c).map(c => c.type));

  showCreateMissionModal = () => {
    this.setState({
      createMissionVisible: true
    });
  };

  hideCreateMissionModal = () => {
    this.setState({
      createMissionVisible: false
    });
  };

  editMission = mission => {
    this.setState({
      editMissionVisible: true,
      missionToEdit: mission
    });
  };
  hideEditMissionModal = () => {
    this.setState({
      editMissionVisible: false,
      missionToEdit: null
    });
  };

  showEmargementsOfMission = mission => {
    this.setState({
      displayEmargementsOf: mission
    });
  };

  hideEmargementModal = () => {
    this.setState({
      displayEmargementsOf: null
    });
  };

  archiving = (mission, toasVisible) => {
    const { archivedVisible } = this.state;
    if (!mission) return;
    this.setState({
      loading: true
    });
    return new Promise((resolve) => {
      MissionActions.archiving(mission.id).then(newMission => {
        resolve(newMission);
        this.setState({
          loading: false
        });
        if (toasVisible) {
          ToastActions.createToastSuccess(
            archivedVisible ? `Dispositif "${mission.name}" rétablit` : `Dispositif "${mission.name}" archivé`
          );
        }
      }).catch(this.handleError);
    })
  };

  archiveSelected = () => {
    const { selectedRowKeys, archivedVisible } = this.state;
    const promises = selectedRowKeys.forEach(r => {
      this.setState({
        loading: true
      });
      const mission = archivedVisible ? MissionStore.getArchivedById(r) : MissionStore.getById(r);
      this.archiving(mission, false);
    });
    if (promises) {
      return Promise.all(promises).then(() => {
        ToastActions.createToastSuccess(
          archivedVisible ? "Dispositifs rétablis" : "Dispositifs archivés"
        );
      });
    }
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  handleError = err => {
    this.setState({
      loading: false
    });
    try {
      const resp = JSON.parse(err.response);
      ToastActions.createToastError(resp.message);
    } catch (e) {
      ToastActions.createToastError("Une erreur est survenue");
    }
  };

  render() {
    const {
      missionToEdit,
      loading,
      displayEmargementsOf,
      filteredMissions,
      archivedVisible,
      selectedRowKeys,
      concernedMission,
      emargementsGroupedBy,
      selectEmargementsPeriodModalVisible,
      selectMissionDocumentsToPrintModalVisible,
    } = this.state;
    const hasSelected = selectedRowKeys.length > 0;

    return (
      <div className="mission-list">
        {!loading &&
          SecurityService.isGranted(Resource.MISSION, Access.CREATE) && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={this.showCreateMissionModal}
            >
              <LocaleComponent transKey="mission.add" />
            </Button>
          )}
        {!loading && SecurityService.isGranted(Resource.EMARGEMENT, Access.READ) && <ExportEmargementsModal />}
        {!loading &&
          <Button
            type="danger"
            style={{ color: !archivedVisible && "#f04134", marginBottom: "1%", float: "right", zIndex: 99 }}
            ghost={!archivedVisible}
            icon={archivedVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            onClick={() => { archivedVisible ? this.loadMissions() : this.loadArchivedMissions() }}
          >
            {archivedVisible ? Locale.trans("archive.unarchived") : Locale.trans("archive.archived")}
          </Button>
        }
        {this.renderMissionTable()}

        {!loading && (
          <div
            className="actions-row"
            style={{
              marginTop:
                !filteredMissions || filteredMissions.length === 0
                  ? "10px"
                  : "-50px"
            }}
          >
            {selectedRowKeys.length > 0 && (
              <Button icon={archivedVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />} type="danger" onClick={this.archiveSelected} disabled={!hasSelected} loading={loading}>
                {archivedVisible ? `Rétablir ${selectedRowKeys.length} dispositif(s)` : `Archiver ${selectedRowKeys.length} dispositif(s)`}
              </Button>
            )}
          </div>
        )}

        <CreateMissionModal
          onCancel={this.hideCreateMissionModal}
          visible={this.state.createMissionVisible}
        />
        <EditMissionModal
          mission={missionToEdit}
          onCancel={this.hideEditMissionModal}
          visible={this.state.editMissionVisible}
        />
        {displayEmargementsOf && (
          <MissionEmargementsModal
            onCancel={this.hideEmargementModal}
            mission={displayEmargementsOf}
          />
        )}
        {concernedMission && (
          <SelectMissionDocumentsToPrintModal
            mission={concernedMission}
            onCancel={() => this.setState({
              selectMissionDocumentsToPrintModalVisible: false,
              concernedMission: null
            })}
            visible={selectMissionDocumentsToPrintModalVisible}
          />
        )}
        {concernedMission && emargementsGroupedBy !== "" && (
          <SelectEmargementsPeriodModal
            id={concernedMission.id}
            archived={archivedVisible}
            groupBy={emargementsGroupedBy}
            onCancel={() => this.setState({
              selectEmargementsPeriodModalVisible: false,
              concernedMission: null,
              emargementsGroupedBy: "",
            })}
            visible={selectEmargementsPeriodModalVisible}
          />
        )}
      </div>
    );
  }

  renderMissionTable() {
    const { filters, filteredMissions, selectedRowKeys, loading } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const columns = [
      {
        title: Locale.trans("mission.name"),
        key: "name",
        sorter: sortNameColumn,
        filterIcon: <FilterIcon active={filters.name.length > 0} />,
        render: this.renderMissionNameCell,
        filterDropdown: (
          <TableColumnFilter
            name="name"
            selectedValues={filters.name}
            values={this.getMissionNames().map(r => ({ text: r, value: r }))}
            onChange={this.handleFilterChange}
          />
        )
      },
      {
        title: Locale.trans("user.type"),
        key: "type",
        sorter: sortTypeColumn,
        filterIcon: <FilterIcon active={filters.type.length > 0} />,
        render: this.renderMissionTypeCell,
        filterDropdown: (
          <TableColumnFilter
            name="type"
            selectedValues={filters.type}
            values={this.getMissionTypes().map(r => ({ text: r, value: r }))}
            onChange={this.handleFilterChange}
          />
        )
      },
      {
        title: null,
        key: "actions",
        width: "200px",
        render: this.rendActionsCell
      }
    ];

    return (
      <Table
        rowSelection={rowSelection}
        dataSource={filteredMissions}
        rowKey="id"
        columns={columns}
        locale={Locale.Table}
        ref={r => {
          this.table = r;
        }}
        loading={loading && { indicator: <LoadingIcon /> }}
      />
    );
  }

  renderMissionNameCell = mission => mission.name;

  renderMissionTypeCell = (mission) => (
    <React.Fragment>
      {mission.type && mission.type}
    </React.Fragment>
  );

  rendActionsCell = mission => {
    const showEmargementsBtn =
      SecurityService.isGranted(Resource.EMARGEMENT, Access.READ) && this.renderShowEmargementsCell(mission);
    const printEmargementsBtn =
      SecurityService.isGranted(Resource.PRINT_EMARGEMENT, Access.READ) && this.rendPrintCell(mission);
    return (
      <div className="actions-row">
        {this.state.archivedVisible ? (
          <Tooltip title={Locale.trans("archive.unarchive")}>
            <Button
              shape="circle"
              icon={<EyeOutlined />}
              onClick={() => this.archiving(mission, true)}
            />
          </Tooltip>
        ) : (
          <Tooltip title={Locale.trans("archive.action")}>
            <Button
              shape="circle"
              icon={<EyeInvisibleOutlined />}
              onClick={() => this.archiving(mission, true)}
            />
          </Tooltip>
        )}
        {showEmargementsBtn}
        {printEmargementsBtn}
        <Button
          type="primary"
          shape="circle"
          icon={<EditOutlined />}
          onClick={e => {
            this.editMission(mission);
            e.stopPropagation();
            e.preventDefault();
            return false;
          }}
        />
      </div>
    );
  };

  renderShowEmargementsCell = mission => (
    <Tooltip title="Voir les émargements">
      <Button
        type="primary"
        shape="circle"
        icon={<CheckOutlined />}
        onClick={() => {
          this.showEmargementsOfMission(mission);
        }}
      />
    </Tooltip>
  );

  rendPrintCell = mission => {
    const menu = (
      <Menu onClick={({ key }) => this.setState({ selectEmargementsPeriodModalVisible: true, emargementsGroupedBy: key, concernedMission: mission })}>
        {/*<Menu.Item key="week">Imprimer par semaine</Menu.Item>*/}
        <Menu.Item key="user">Imprimer par stagiaire</Menu.Item>
        <Menu.Item key="date">Imprimer par date</Menu.Item>
      </Menu>
    );
    return (
      <>
        <Dropdown overlay={menu}>
          <Tooltip title="Imprimer les émargements">
            <Button type="primary" shape="circle" icon={<PrinterOutlined />} />
          </Tooltip>
        </Dropdown>

        <Tooltip title={Locale.trans("documents")}>
          <Button
            type="primary"
            shape="circle"
            icon={<CopyOutlined />}
            onClick={() => this.setState({
              selectMissionDocumentsToPrintModalVisible: true,
              concernedMission: mission
            })}
          />
        </Tooltip>

      </>
    );
  };
}