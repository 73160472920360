import ArchivedEntityStoreBase from "../stores/ArchivedEntityStoreBase";
import JobConstants from "../constants/JobConstants";

class JobStore extends ArchivedEntityStoreBase {
  getInitialState() {
    return {
      currentJobId: null,
      entities: [],
      archived: [],
    };
  }

  reduce(state, action) {
    const { type, payload } = action;
    let newState = Object.assign({}, state);

    switch (type) {
      case JobConstants.RECEIVE_JOBS_OF_SKILL:
        newState = this._receiveBySkill(state, payload.skill, payload.jobs);
        break;
      case JobConstants.RECEIVE_JOB:
        newState = this._receiveEntity(state, payload.job);
        break;
      case JobConstants.RECEIVE_ARCHIVED_JOB:
        newState = this._receiveArchivedEntity(state, payload.job);
        break;
      case JobConstants.RECEIVE_JOBS:
        newState = this._receiveMany(state, payload.jobs);
        break;
      case JobConstants.RECEIVE_ALL_JOBS:
          newState = this._receiveAll(state, payload.jobs);
          break;
      case JobConstants.RECEIVE_ARCHIVED_JOBS:
          newState = this._receiveArchived(state, payload.jobs);
          break;
      case JobConstants.CHANGE_CURRENT_JOB:
        newState.currentJobId = payload.jobId;
        break;
      case "clearAll":
        newState = this.getInitialState();
        break;
      default:
        newState = state;
    }
    return newState;
  }

  _receiveBySkill = (state, skill, jobs) => {
    const newState = Object.assign({}, state);
    newState.entities = state.entities.filter(
      e => jobs.findIndex(a => e.id === a.id) === -1
    );
    newState.entities.push(...jobs);
    return newState;
  };

  _receiveMany = (state, jobs) => {
    const newState = Object.assign({}, state);
    newState.entities = state.entities.filter(
      e => jobs.findIndex(a => e.id === a.id) === -1
    );
    newState.entities.push(...jobs);
    return newState;
  };

  _receiveArchived = (state, jobs) => {
    let newState = Object.assign({}, state);
    newState.archived = state.archived.filter(
        e => jobs.findIndex(a => e.id === a.id) === -1
    );
    newState.archived.push(...jobs);
    return newState;
  };

  // /// Public Methods  /////

  getJob() {
    return this.getState().job;
  }

  /**
   * Get a job by ID.
   * @param {number} id The identifier.
   * @return {?Job}    The job, or NULL if no entity is found.
   */
  getById = (id) =>  this.getState().entities.find(e => e.id === parseInt(id));

  /**
   * Get an archived job by ID.
   * @param {number} id The identifier of the archived job.
   * @return {?Job}    The archived job, or NULL if no entity is found.
   */
  getArchivedById = id => this.getState().archived.find(e => e.id === parseInt(id));

  /**
   * Get all jobs of a skill.
   */
  getBySkill = skill =>
    this.getState().entities.filter(e => !!e.skill.find(a => a.id === skill.id));

  /**
   * Get all the jobs.
   */
  getAll = () => this.getState().entities.concat(this.getState().archived);

  /**
   * Get unarchived jobs.
   */
  getJobs = () => this.getState().entities;

  getArchivedJobs = () => this.getState().archived;

  /**
   * Get the identifier of the current job.
   * @return {[type]} [description]
   */
  getCurrentJobId = () =>
    this.getState().currentJobId ||
    (this.getState().entities.length ? this.getState().entities[0].id : null);

  /**
   * Get the identifier of the current job.
   * @return {[type]} [description]
   */
  getCurrentJob = () => this.getById(this.getCurrentJobId());
}

const store = new JobStore();
export default store;