import dispatcher from "../dispatchers/AppDispatcher";
import ActionsBase from "../actions/ActionsBase";

import PartnerConstants from "../constants/PartnerConstants";
import PartnerService from "../services/PartnerService";

import CacheManager from "../services/CacheManager";

const CACHE_DURATION = 30 * 1000;

class PartnerActions extends ActionsBase {
    create = (partner) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(newPartner) {
                dispatcher.dispatch({
                    type: PartnerConstants.RECEIVE_PARTNER,
                    payload: {
                        partner: newPartner,
                    },
                });
                resolve(newPartner);
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            PartnerService.post(partner)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    edit = (partnerId, partner) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(newPartner) {
                dispatcher.dispatch({
                    type: PartnerConstants.RECEIVE_PARTNER,
                    payload: {
                        partner: newPartner,
                    },
                });
                resolve(newPartner);
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            PartnerService.patch(partnerId, partner)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    archiving = (partnerId) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(newPartner) {
                dispatcher.dispatch({
                    type: PartnerConstants.RECEIVE_ARCHIVED_PARTNER,
                    payload: {
                        partner: newPartner,
                    },
                });
                resolve();
            }
            function handleError(err) {
                $this.handleError(err, reject);
            }
            PartnerService.archiving(partnerId)
                .then(handleSuccess)
                .catch(handleError);
        });
      };

    delete = (id) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess() {
                dispatcher.dispatch({
                    type: PartnerConstants.DELETE_PARTNER,
                    payload: {
                        id,
                    },
                });
                resolve();
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            PartnerService.remove(id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    changeCurrentPartnerId = (partnerId) => {
        return new Promise(
            (resolve, reject) => {
                dispatcher.dispatch({
                    type: PartnerConstants.CHANGE_CURRENT_PARTNER,
                    payload: {
                        partnerId,
                    }
                });
                resolve();
            }
        );
    };

    reload = () => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(partners) {
                dispatcher.dispatch({
                    type: PartnerConstants.RECEIVE_PARTNERS,
                    payload: {
                        partners,
                    },
                });
                resolve();
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            PartnerService.getAll()
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadArchived = () => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(partners) {
                dispatcher.dispatch({
                    type: PartnerConstants.RECEIVE_ARCHIVED_PARTNERS,
                    payload: {
                        partners,
                    },
                });
                resolve();
            }
            function handleError(err) {
                $this.handleError(err, reject);
            }
            PartnerService.getAllArchived()
                .then(handleSuccess)
                .catch(handleError);
        });
      };

    reloadById = (id) => {
        const $this = this;
        return new Promise(
            (resolve, reject) => {
                function handleSuccess(partner) {
                    dispatcher.dispatch({
                        type: PartnerConstants.RECEIVE_PARTNER,
                        payload: {
                            partner
                        }
                    });
                    resolve();
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                PartnerService.getById(id)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };

    reloadByMission = (mission) => {
        const $this = this;
        return new Promise(
            (resolve, reject) => {
                if (
                    CacheManager.isCached(
                        "Partner:reloadByMission",
                        mission.id.toString(),
                        CACHE_DURATION
                    )
                ) {
                    resolve();
                    return;
                }

                function handleSuccess(partners) {
                    dispatcher.dispatch({
                        type: PartnerConstants.RECEIVE_PARTNERS_OF_MISSION,
                        payload: {
                            partners,
                            mission
                        }
                    });
                    resolve();
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                PartnerService.getByMission(mission.id)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };
}

const action = new PartnerActions();
export default action;