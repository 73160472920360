import React from "react";
import ShowReportModal from "views/Report/ShowReportModal";
import ShowIncidentModal from "views/Incident/ShowIncidentModal";
import ShowReviewModal from "views/Review/ShowReviewModal";

export default function ShowDocumentModal({ document, visible, onCancel }) {
  if (!document) {
    return null;
  }

  switch (document.documentType) {
    case "report":
      return (
        <ShowReportModal
          report={document}
          onCancel={onCancel}
          visible={visible}
        />
      );
    case "incident":
      return (
        <ShowIncidentModal
          incident={document}
          onCancel={onCancel}
          visible={visible}
        />
      );
    case "review":
      return (
        <ShowReviewModal
          review={document}
          onCancel={onCancel}
          visible={visible}
        />
      );
    default:
      return null;
  }
}
