import React from "react";

import { Table, Button, Tooltip } from "antd";
import TableColumnFilter from "components/TableColumnFilter.jsx";
import FilterIcon from "components/FilterIcon.jsx";
import LoadingIcon from "components/LoadingIcon.jsx";

import ShowReviewModal from "views/Review/ShowReviewModal.jsx";

import Locale from "locale/LocaleFactory";
import ArrayService from "services/utils/ArrayService";
import DateService from "services/utils/DateService";
import StringService from "services/utils/StringService";
import FilterService from "services/utils/FilterService";

import ReviewActions from "actions/ReviewActions";
import ReviewStore from "stores/ReviewStore";

import MissionActions from "actions/MissionActions";
import MissionStore from "stores/MissionStore";

import EstablishmentActions from "actions/EstablishmentActions";
import EstablishmentStore from "stores/EstablishmentStore";

import ReviewService from "services/ReviewService";
import { EditOutlined, EyeInvisibleOutlined, EyeOutlined, PrinterOutlined } from "@ant-design/icons";
import EditReviewModal from "./EditReviewModal";

// Sorting Methods
function sortDateColumn(r1, r2) {
  return StringService.compareCaseInsensitive(r1.date, r2.date);
}

function sortMissionColumn(r1, r2) {
  const m1 = MissionStore.getById(r1.mission.id);
  const m2 = MissionStore.getById(r2.mission.id);
  return StringService.compareCaseInsensitive(m1 && m1.name, m2 && m2.name);
}

function sortAuthorColumn(r1, r2) {
  const m1 = r1.author;
  const m2 = r2.author;
  return StringService.compareCaseInsensitive(
    m1 && m1.firstName + m1.lastName,
    m2 && m2.firstName + m2.lastName
  );
}

function sortUserColumn(r1, r2) {
  const u1 = r1.user;
  const u2 = r2.user;
  return StringService.compareCaseInsensitive(
    u1 && u1.firstName + u1.lastName,
    u2 && u2.firstName + u2.lastName
  );
}

function sortReviewTypeColumn(r1, r2) {
  const s1 = Locale.trans(`review.${r1.type}`);
  const s2 = Locale.trans(`review.${r2.type}`);
  return StringService.compareCaseInsensitive(s1, s2);
}

function sortEstablishmentColumn(r1, r2) {
  const e1 = EstablishmentStore.getById(r1.establishment[0]?.id);
  const e2 = EstablishmentStore.getById(r2.establishment[0]?.id);
  return StringService.compareCaseInsensitive(e1 && e1.name, e2 && e2.name);
}

// TODO: add sort methods for interns.

/**
 * The list of the reviews.
 */
export default class ReviewList extends React.Component {
  constructor() {
    super();

    const reviews = ReviewStore.getReviews();

    this.state = {
      loading: !reviews.length,
      reviews,

      filteredReviews: [],

      filters: {
        date: [],
        mission: [],
        author: [],
        reviewType: [],
        establishment: [],
        user: [],
      },

      reviewToShow: null,
      reviewToEdit: null,
      archivedVisible: false,
    };
  }

  componentDidMount() {
    this.reviewListener = ReviewStore.addListener(this.receiveReviews);
    this.missionListener = MissionStore.addListener(this.receiveMissions);
    this.establishmentListener = EstablishmentStore.addListener(
      this.receiveEstablishments
    );
    this.archivedReviewListener = ReviewStore.addListener(
      this.receiveArchivedReviews
    );

    MissionActions.reload();
    EstablishmentActions.reload();
    this.loadReviews();
    this.updateFilters();
    // Here we set the default sorted column
    // Temporary solution waiting for AntD to propose a native way to do it.
    /*const column = this.table.findColumn("date");
    this.table.toggleSortOrder("descend", column);*/
  }

  componentWillUnmount() {
    this.reviewListener.remove();
    this.missionListener.remove();
    this.establishmentListener.remove();
    this.archivedReviewListener.remove();
  }

  loadReviews = () => {
    this.setState({
      loading: true,
    });
    ReviewActions.reload().then(() => {
      this.setState({
        loading: false,
        archivedVisible: false,
      });
    });
    this.reviewListener = ReviewStore.addListener(this.receiveReviews);
  };

  loadArchivedReviews = () => {
    this.setState({
      loading: true,
    });
    ReviewActions.reloadArchived().then(() => {
      this.setState({
        loading: false,
        archivedVisible: true,
      });
    });
    this.archivedReviewListener = ReviewStore.addListener(
      this.receiveArchivedReviews
    );
  };

  receiveReviews = () => {
    this.setState(
      {
        reviews: ReviewStore.getReviews(),
      },
      this.updateFilters
    );
  };

  receiveArchivedReviews = () => {
    this.setState(
      {
        reviews: ReviewStore.getArchivedReviews(),
      },
      this.updateFilters
    );
  };

  receiveMissions = () => {
    this.forceUpdate();
  };

  receiveEstablishments = () => {
    this.forceUpdate();
  };

  // Filters
  updateFilters = () => {
    const { reviews } = this.state;
    const filteredReviews = reviews.filter(this.reviewMatchFilters);
    this.setState({ filteredReviews });
  };

  reviewMatchFilters = (e) => {
    const { filters } = this.state;

    return (
      FilterService.matchFilter(filters.date, e.date) &&
      FilterService.matchFilter(filters.mission, e.mission.id) &&
      FilterService.matchFilter(filters.author, e.author.id) &&
      FilterService.matchFilter(filters.reviewType, e.type) &&
      FilterService.matchFilter(filters.establishment, e.establishment.length > 0 && e.establishment[0]?.id) &&
      FilterService.matchFilter(filters.user, e.user.id)
    );
  };

  handleFilterChange = (name, values) => {
    let prevState = this.state.filters;
    prevState[name] = values;
    this.setState({ filters: prevState });
    this.updateFilters();
  };

  getReviewDates = () =>
    ArrayService.unique(this.state.reviews.map((r) => r.date));

  getReviewTypes = () =>
    ArrayService.unique(this.state.reviews.map((r) => r.type));

  showReview = (review) => {
    this.setState({
      reviewToShow: review,
    });
  };

  hideReview = () => {
    this.setState({
      reviewToShow: null,
    });
  };

  editReview = (review) => {
    this.setState({
      reviewToEdit: review,
    });
  };

  hideEditReview = () => {
    this.setState({
      reviewToEdit: null,
    });
  };

  render() {
    const { reviewToShow, reviewToEdit, archivedVisible } = this.state;

    return (
      <div className="report-list">
        <Button
          type="danger"
          style={{
            color: !archivedVisible && "#f04134",
            marginBottom: "1%",
            zIndex: 1,
            float: "right",
          }}
          ghost={!archivedVisible}
          icon={archivedVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          onClick={() => {
            archivedVisible ? this.loadReviews() : this.loadArchivedReviews();
          }}
        >
          {archivedVisible
            ? Locale.trans("archive.unarchived")
            : Locale.trans("archive.archived")}
        </Button>
        {this.renderReviewTable()}
        {reviewToEdit && <EditReviewModal
            reviewId={reviewToEdit.id}
            onCancel={this.hideEditReview}
            visible={!!reviewToEdit}
          />}
        {reviewToShow &&
          <ShowReviewModal
            review={reviewToShow}
            onCancel={this.hideReview}
            visible={!!reviewToShow}
          />}
      </div>
    );
  }

  renderReviewTable() {
    const { filters, reviews, filteredReviews } = this.state;

    const columns = [
      {
        title: Locale.trans("review.date"),
        key: "date",
        sorter: sortDateColumn,
        defaultSortOrder: "descend",
        filterIcon: <FilterIcon active={filters.date.length > 0} />,
        render: this.renderReviewDateCell,
        filterDropdown: (
          <TableColumnFilter
            name="date"
            selectedValues={filters.date}
            values={this.getReviewDates().map((d) => ({
              text: DateService.formatApiToDisplay(d),
              value: d,
            }))}
            onChange={this.handleFilterChange}
          />
        ),
      },
      {
        title: Locale.trans("review.mission"),
        key: "mission",
        sorter: sortMissionColumn,
        filterIcon: <FilterIcon active={filters.mission.length > 0} />,
        render: this.renderReviewMissionCell,
        filterDropdown: (
          <TableColumnFilter
            name="mission"
            selectedValues={filters.mission}
            values={ArrayService.uniqueEntity(
              reviews.map((r) => r.mission)
            ).map((m) => ({
              text: m.name,
              value: m.id,
            }))}
            onChange={this.handleFilterChange}
          />
        ),
      },
      {
        title: Locale.trans("review.author"),
        key: "author",
        sorter: sortAuthorColumn,
        filterIcon: <FilterIcon active={filters.author.length > 0} />,
        render: this.renderReviewAuthorCell,
        filterDropdown: (
          <TableColumnFilter
            name="author"
            selectedValues={filters.author}
            values={ArrayService.uniqueEntity(reviews.map(r => r.author))
              .map((u) => ({
                text: `${u.firstName} ${u.lastName}`,
                value: u.id,
              }))}
            onChange={this.handleFilterChange}
          />
        ),
      },
      {
        title: Locale.trans("review.type"),
        key: "reviewType",
        sorter: sortReviewTypeColumn,
        filterIcon: <FilterIcon active={filters.reviewType.length > 0} />,
        render: this.renderReviewTypeCell,
        filterDropdown: (
          <TableColumnFilter
            name="reviewType"
            selectedValues={filters.reviewType}
            values={this.getReviewTypes().map((t) => ({
              text: Locale.trans(`review.${t}`),
              value: t,
            }))}
            onChange={this.handleFilterChange}
          />
        ),
      },
      {
        title: Locale.trans("mission.establishment"),
        key: "establishment",
        sorter: sortEstablishmentColumn,
        filterIcon: <FilterIcon active={filters.establishment.length > 0} />,
        render: this.renderReviewEstablishmentCell,
        filterDropdown: (
          <TableColumnFilter
            name="establishment"
            selectedValues={filters.establishment}
            values={EstablishmentStore.getAll().map((e) => ({
              text: e.name,
              value: e.id,
            }))}
            onChange={this.handleFilterChange}
          />
        ),
      },
      {
        title: Locale.trans("intern"),
        key: "user",
        sorter: sortUserColumn,
        filterIcon: <FilterIcon active={filters.user.length > 0} />,
        render: this.renderReviewUserCell,
        filterDropdown: (
          <TableColumnFilter
            name="user"
            selectedValues={filters.user}
            values={ArrayService.uniqueEntity(reviews.map(r => r.author))
              .map((u) => ({
                text: `${u.firstName} ${u.lastName}`,
                value: u.id,
              }))}
            onChange={this.handleFilterChange}
          />
        ),
      },
      {
        title: null,
        key: "actions",
        width: "160px",
        render: this.rendActionsCell,
      },
    ];

    return (
      <Table
        dataSource={filteredReviews}
        rowKey="id"
        columns={columns}
        locale={Locale.Table}
        ref={(r) => {
          this.table = r;
        }}
        loading={this.state.loading && { indicator: <LoadingIcon /> }}
      />
    );
  }

  renderReviewDateCell = (review) =>
    DateService.formatApiToDisplay(review.date);

  renderReviewTypeCell = (review) => Locale.trans(`review.${review.type}`);

  renderReviewMissionCell = (review) => {
    return review.mission.name;
  };

  renderReviewEstablishmentCell = (review) => {
    const establishment =
      review.establishment.length > 0 &&
      EstablishmentStore.getById(review.establishment[0].id);
    return establishment && establishment.name;
  };

  renderReviewAuthorCell = (review) => {
    return review.author && `${review.author.firstName} ${review.author.lastName}`;
  };

  renderReviewUserCell = (review) => {
    return review.user && `${review.user.firstName} ${review.user.lastName}`;
  };

  rendActionsCell = (review) => (
    <div className="actions">
      {this.rendPrintCell(review)}
      <Tooltip title={Locale.trans("view")}>
        <Button
          type="primary"
          shape="circle"
          icon={<EyeOutlined />}
          onClick={(e) => {
            this.showReview(review);
            e.stopPropagation();
            e.preventDefault();
            return false;
          }}
        />
      </Tooltip>
      <Tooltip title={Locale.trans("edit")}>
        <Button
          type="primary"
          shape="circle"
          icon={<EditOutlined />}
          onClick={(e) => {
            this.editReview(review);
            e.stopPropagation();
            e.preventDefault();
            return false;
          }}
        />
      </Tooltip>
    </div>
  );

  rendPrintCell = (review) => (
    <Tooltip title={Locale.trans("print")}>
      <Button
        type="primary"
        shape="circle"
        icon={<PrinterOutlined />}
        onClick={() => ReviewService.download(review.id)}
      />
    </Tooltip>
  );
}
