
import ServiceBase from "../services/ServiceBase";
import BaseUrlConstants from "../constants/BaseUrlConstants";

const URL = `${BaseUrlConstants.BASE_URL}`;

class OrganisationService extends ServiceBase {
  constructor() {
    super(URL);
  }

  /**
   * Get a organisation by unique identifier.
   * @param {number} organisationId The identifier of the organisation.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getById(organisationId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}organisations/${organisationId}`,
      method: "GET"
    });
  }

  /**
   * Get all organisations.
   *
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getAll() {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}organisations`,
      method: "GET"
    });
  }
}

const service = new OrganisationService();
export default service;
