import BaseUrlContacts from '../constants/BaseUrlConstants';

const values = {
    BASE_URL: BaseUrlContacts.BASE_URL,
    LOGIN_URL: BaseUrlContacts.BASE_URL + 'auth-tokens',
    SIGNUP_URL: BaseUrlContacts.BASE_URL + 'users',

    LOGIN_USER: 'LOGIN_USER',
    LOGOUT_USER: 'LOGOUT_USER',
    RECEIVE_USER_LOGIN: 'RECEIVE_USER_LOGIN',
}
export default values;