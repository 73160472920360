import React, { useEffect } from "react";
import { Form, Button } from "antd";
import Locale from "locale/LocaleFactory";
import FormItem from "components/forms/FormItems";
import UserSelectFormItem from "components/form-items/UserSelectFormItem";

interface IProps {
  establishment?: any
  loading: boolean
  userTypes?: any[]
  onSubmit: (establishment: any) => void
}
const EstablishmentForm: React.FC<IProps> = ({ establishment, userTypes, loading, onSubmit }) => {
  const [form] = Form.useForm();

  const getEntityFromValues = (values: any) => {
    const entity: { [key: string]: any } = {};
    const keys = Object.keys(values);
    const ignoredKeys = [];

    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      if (!k.endsWith("_ids") && k !== "user") {
        if (k.endsWith("_id")) {
          const tK = k.replace("_id", "");
          entity[tK] = values[k];
          ignoredKeys.push(tK);
        } else if (ignoredKeys.indexOf(k) === -1) {
          entity[k] = values[k];
        }
      } else if (k.startsWith("user_")) {
        if (!entity.user) {
          entity.user = [];
        }
        entity.user = entity.user.concat(values[k]);
      }
    }

    return entity;
  };
  const handleSubmit = (values: any) => {
    const establishment = getEntityFromValues(values);
    onSubmit(establishment);
  };

  const defaultValues = establishment;
  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  return (
    <Form initialValues={defaultValues} onFinish={handleSubmit} layout="vertical" form={form}>
      <FormItem.Input
        id="name"
        required
        label={Locale.trans("establishment.name")}
        form={form}
        hasFeedback
      />
      <FormItem.Input
        id="email"
        label={Locale.trans("establishment.email")}
        form={form}
        hasFeedback
      />
      <FormItem.Input
        id="phoneNumber"
        label={Locale.trans("establishment.phoneNumber")}
        form={form}
        hasFeedback
      />

      <FormItem.TextArea
        id="address"
        required
        autosize={{ minRows: 1, maxRows: 10 }}
        label={Locale.trans("establishment.address")}
        form={form}
      />
      <FormItem.Input
        id="cp"
        label={Locale.trans("establishment.postalCode")}
        form={form}
      />
      <FormItem.Input
        id="town"
        label={Locale.trans("establishment.city")}
        form={form}
      />

      {userTypes?.filter(u => u.isVisibleOnEstablishment)
        .map((u) => <UserField userType={u} establishment={establishment} />)}

      <Button
        type="primary"
        htmlType="submit"
        className="login-form-button"
        loading={loading}
      >
        {Locale.trans("save")}
      </Button>
    </Form>
  );

}

interface IUserFieldProps {
  userType: any
  establishment?: any
}
const UserField: React.FC<IUserFieldProps> = ({ userType, establishment }) => {
  const initialUsers = establishment?.user
    .filter((u: any) =>
      !!u.types?.find((type: any) => type.id === userType.id)
    );
  return (
    <UserSelectFormItem
      key={userType.id}
      id={`user_${userType.id}_ids`}
      multiple
      label={userType.title}
      initialValue={initialUsers?.map((u: any) => u.id.toString()) ?? []}
      initialUsers={initialUsers}
      extraFilters={{
        archived: false,
        types: [userType.id]
      }}
    />
  );
};

export default EstablishmentForm;
