const trans = {
  Trans: {
    beginning: "Début",
    cost: "Coût",
    date: "Date",
    day: "jour",
    week: "semaine",
    month: "mois",
    days: "jours",
    view: "Aperçu",
    description: "Description",
    delete: "Supprimer",
    "delete.confirm": "Êtes vous sur de supprimer?",
    download: "Télécharger",
    print: "Imprimer",
    certificate: "Certificat",
    documents: "Documents",
    end: "Fin",
    edit: "Modifier",
    save: "Enregistrer",
    send: "Envoyer",
    cancel: "Annuler",
    total: "Total",
    notFound: "Aucun",
    forbiddenAccess: "L'accès à cette fonctionnalité vous est interdite.",

    mister: "Monsieur",
    miss: "Madame",

    facebook: "Facebook",
    linkedin: "Linkedin",
    twitter: "Twitter",

    matin: "Matin",
    apresmidi: "Après-midi",
    refused: "Refusée",
    approved: "Acceptée",
    requested: "En attente",
    accepted: "Acceptée",

    monday: "Lundi",
    tuesday: "Mardi",
    wednesday: "Mercredi",
    thursday: "Jeudi",
    friday: "Vendredi",
    saturday: "Samedi",
    sunday: "Dimanche",

    intern: "Stagiaire",
    trainer: "Formateur",
    funder: "Donateur",
    referent: "Référent",
    instructor: "Instructeur",

    welcome: "Bonjour __USER__",

    license: "Oliviers tout droits réservés © 2018 Créé par IAS Ingenierie",

    address: "Adresse",
    "address.street": "Rue",
    "address.country": "Pays",
    "address.city": "Ville",
    "address.postalCode": "Code Postal",

    error: {
      required: "Merci de renseigner ce champ",
      "email.invalid": "E-Mail invalide",
      "phone.invalid": "Numéro de téléphone invalide",
      "amountquote.invalid": "Montant de commande invalide",
      "socialSecurityNumber.invalid": "Numéro de sécurité social invalide",
    },

    advancedSearch: {
      show: "Filtres Avancés",
      hide: "Masquer les Filtres",
    },

    login: {
      username: "Nom d'Utilisateur",
      password: "Mot de Passe",
      rememberMe: "Se souvenir de moi",

      "logIn.button": "Connexion",
      "logOut.button": "Déconnexion",
      "username.error.required": "Veuillez reseigner votre identifiant",
      "password.error.required": "Veuillez taper votre mot de passe",
    },
    incident: {
      date: "Date",
      author: "Auteur",
      mission: "Dispositif",
      on: "Séance du",
    },
    report: {
      date: "Date",
      author: "Auteur",
      name: "Rapport",
      activity: "Rapport d'activité",
      incident: "Rapport d'incident",
      review: "Bilan",
      activities: "Rapports d'activité",
      incidents: "Rapports d'incident",
      reviews: "Bilans",
      on: "Séance du",
      mission: "Dispositif",
    },
    review: {
      name: "Bilan",
      date: "Date",
      author: "Auteur",
      type: "Type",
      mission: "Dispositif",
      on: "Séance du",
      title4: "II. Evaluation des compétences professionnelles",

      initial: "Bilan initial",
      intermediate: "Bilan intermédiaire",
      final: "Bilan final",

      "recrutement-coaching": "Session de coaching",
      "recrutement-preorientation": "Réunion de pré-orientation",
      "recrutement-intermediate": "Bilan intermédiaire",
      "recrutement-final": "Bilan final",
    },
    mission: {
      name: "Dispositif",
      type: "Type",
      emargementType: "Emargement",
      emargementTypes: {
        day: "Journée",
        "half-day": "Matin / Après-midi",
        "morning": "Matin",
        "afternoon": "Après-midi"
      },
      reviewAlertDate: "Dates limites de bilan",
      theName: "le dispositif",
      hostCompany: "Entreprise d'accueil",
      hostCompanies: "Entreprises d'accueil",
      establishment: "Établissement",
      establishments: "Établissements",
      skills: "Compétences",
      select: "Sélectionnez un dispositif",
      add: {
        _: "Ajouter un dispositif",
        success: "Dispositif __name__ créé.",
      },
      update: {
        _: "Modifier un dispositif",
        success: "Dispositif __name__ modifié.",
      },
      hours: "Nombre d'heures par emargement",
      period: "Période de la formation",
      job: "Métier",
      partner: "Partenaire",
    },
    user: {
      firstName: "Prénom",
      lastName: "Nom",
      name: "Nom",
      username: "Identifiant",
      nationality: "Nationalité",
      schedules: "Horaires de formation",
      birthDate: "Date de naissance",
      noData: "Aucun stagiaire n'est concerné.",
      select: "Sélectionnez un stagiaire",
      email: "Email",
      boats: "Bateaux",
      phoneNumber: "N° Téléphone",
      password: "Mot de passe",
      passwordConfirm: "Confirmez votre mot de passe",
      "password.error.required": "Veuillez taper votre mot de passe",
      address: "Adresse",
      postalCode: "Code Postal",
      city: "Ville",
      type: "Type",
      job: "Fonction",
    },
    userTypes: "Permissions",
    userType: {
      title: "Intitulé",
      update: {
        _: "Mettre à jour",
        error: "Impossible de mettre à jour les permissions",
        success: 'Permissions "__title__" modifiées',
      },
      trainer: "Formateur",
      intern: "Stagiaire",
    },
    features: {
      mission: "Dispositifs de l'utilisateur",
      "all-missions": "Toutes les missions",
      report: "Rapports",
      review: "Bilans",
      emargement: "Emargements",
      "print-emargement": "Imprimer les émargements",
      "user-has-skill": "Evaluer les compétences d'un stagiaire",
      configuration: "Configuration",
      user: "Utilisateurs",
      "host-company": "Entreprises d'accueil",
      establishment: "Etablissements",
      partner: "Partenaires",
      job: "Metiers",
      skill: "Compétences / Sous-compétences",
      organisation: "Autres Paramètres",
    },
    skill: {
      name: "Compétence",
      skills: "Compétences",
      select: "Sélectionnez une compétence",
      validation: "Validation des compétences",
      add: {
        _: "Ajouter une compétence",
        success: 'Compétence "__name__" créée.',
      },
      update: {
        _: "Modifier une compétence",
        success: 'Compétence "__name__" modifiée.',
      },
    },
    subSkill: {
      skill: "Compétence",
      name: "Sous-compétence",
      subSkills: "Sous-compétences",
      validated: "Compétence(s) validée(s)",
      invalidated: "Compétence(s) non validée(s)",
      noData: "Aucune compétence validée",
      done: "Validée",
      add: {
        _: "Ajouter une sous-compétence",
        success: 'Sous-compétence "__name__" créée.',
      },
      update: {
        _: "Modifier une sous-compétence",
        success: 'Sous-compétence "__name__" modifiée.',
      },
    },
    hostCompany: {
      name: "Nom",
      email: "Email",
      phoneNumber: "N° Téléphone",
      address: "Adresse",
      postalCode: "Code Postal",
      city: "Ville",
      add: {
        _: "Ajouter une entreprise d'accueil",
        success: 'Entreprise "__name__" créée',
        error: "Une erreur est survenue",
      },
      update: {
        _: "Modifier une entreprise d'accueil",
        success: 'Entreprise "__name__" modifiée',
        error: "Une erreur est survenue",
      },
    },
    partner: {
      name: "Nom",
      email: "Email",
      phoneNumber: "N° Téléphone",
      address: "Adresse",
      postalCode: "Code Postal",
      city: "Ville",
      add: {
        _: "Ajouter un Partenaire",
        success: 'Partenaire "__name__" créée',
        error: "Une erreur est survenue",
      },
      update: {
        _: "Modifier un partenaire",
        success: 'Partenaire "__name__" modifiée',
        error: "Une erreur est survenue",
      },
    },
    job: {
      name: "Nom",
      add: {
        _: "Ajouter un métier",
        success: 'Métier "__name__" créée',
        error: "Une erreur est survenue",
      },
      update: {
        _: "Modifier un métier",
        success: 'Métier "__name__" modifiée',
        error: "Une erreur est survenue",
      },
    },
    hostCompanies: "Entreprise d'accueil",
    establishments: "Etablissements",
    partners: "Partenaires",
    jobs: "Métiers",
    establishment: {
      name: "Nom",
      email: "Email",
      phoneNumber: "N° Téléphone",
      address: "Adresse",
      postalCode: "Code Postal",
      city: "Ville",
    },
    session: {
      number: "Nombre de sessions",
    },
    sign: "Signer",
    archive: {
      action: "Archiver",
      unarchive: "Rétablir",
      archived: "Afficher les archivés",
      unarchived: "Masquer les archivés",
    },
  },

  // Ant Design
  Table: {
    filterTitle: "Rechercher...",
    filterConfirm: "OK",
    filterReset: "Réinitialiser",
    emptyText: "Aucune donnée",
  },
  Modal: {
    okText: "OK",
    cancelText: "Annuler",
    justOkText: "OK",
  },
  Popconfirm: {
    okText: "OK",
    cancelText: "Annuler",
  },
  Transfer: {
    notFoundContent: "Pas de résultat",
    searchPlaceholder: "Recherche",
    itemUnit: "élément",
    itemsUnit: "éléments",
  },
  Select: {
    notFoundContent: "Pas de résultat",
  },
  Pagination: {
    // Options.jsx
    items_per_page: "/ page",
    jump_to: "Allez à",
    page: "",

    // Pagination.jsx
    prev_page: "Page Précédente",
    next_page: "Page Suivante",
    prev_5: "5 Pages Précédentes",
    next_5: "5 Pages Suivantes",
    prev_3: "3 Pages Précédentes",
    next_3: "3 Pages Suivantes",
  },
  TimePicker: {
    placeholder: "",
  },
  DatePicker: {
    lang: {
      today: "Aujourd'hui",
      now: "Maintenant",
      backToToday: "Retour à aujourdh'hui",
      ok: "Ok",
      clear: "Vider",
      month: "Mois",
      year: "Année",
      timeSelect: "Sélectionner l'heure",
      dateSelect: "Sélectionner une date",
      monthSelect: "Sélectionner un mois",
      yearSelect: "Sélectionner une année",
      decadeSelect: "Sélectionner une décénie",
      yearFormat: "YYYY",
      dateFormat: "M/D/YYYY",
      dayFormat: "D",
      dateTimeFormat: "M/D/YYYY HH:mm:ss",
      monthFormat: "MMMM",
      monthBeforeYear: true,
      previousMonth: "Mois précédent (PageUp)",
      nextMonth: "Mois suivant (PageDown)",
      previousYear: "Année précédente (Control + left)",
      nextYear: "Année suivante (Control + right)",
      previousDecade: "Décennie précédente",
      nextDecade: "Décennie suivante",
      previousCentury: "Siècle précédent",
      nextCentury: "Siècle suivant",

      placeholder: "Sélectionnez une date",
      rangePlaceholder: ["Date de début", "Date de fin"],
    },
  },
};

export default trans;