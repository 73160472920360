import EntityStoreBase from "../stores/EntityStoreBase";
import MediaConstants from "../constants/MediaConstants";

class MediaStore extends EntityStoreBase {
  getInitialState() {
    return {
      entities: []
    };
  }

  reduce(state, action) {
    const { type, payload } = action;
    let newState = Object.assign({}, state);

    switch (type) {
      case MediaConstants.RECEIVE_MEDIAS_OF_REPORT:
        newState = this._receiveByReport(state, payload.report, payload.medias);
        break;
      case MediaConstants.RECEIVE_MEDIAS_OF_INCIDENT:
        newState = this._receiveByIncident(
          state,
          payload.incident,
          payload.medias
        );
        break;
      case MediaConstants.RECEIVE_MEDIAS_OF_REVIEW:
        newState = this._receiveByReview(state, payload.review, payload.medias);
        break;
      case MediaConstants.RECEIVE_MEDIAS_OF_ARTICLE:
        newState = this._receiveByArticle(
          state,
          payload.article,
          payload.medias
        );
        break;
      case MediaConstants.RECEIVE_MEDIA:
        newState = this._receiveEntity(state, payload.media);
        break;
      case MediaConstants.RECEIVE_MEDIAS:
        newState = this._receiveMany(state, payload.medias);
        break;
      case MediaConstants.DELETE_MEDIA:
        newState = this._deleteOne(state, payload.id);
        break;
      case "clearAll":
        newState = this.getInitialState();
        break;
      default:
        newState = state;
    }
    return newState;
  }

  _receiveByReport = (state, report, medias) => {
    const newState = Object.assign({}, state);
    newState.entities = state.entities.filter(
      e => medias.findIndex(a => e.id === a.id) === -1
    );
    newState.entities.push(...medias);
    return newState;
  };

  _receiveByIncident = (
    state,
    incident,
    medias
  ) => {
    const newState = Object.assign({}, state);
    newState.entities = state.entities.filter(
      e => medias.findIndex(a => e.id === a.id) === -1
    );
    newState.entities.push(...medias);
    return newState;
  };

  _receiveByReview = (state, review, medias) => {
    const newState = Object.assign({}, state);
    newState.entities = state.entities.filter(
      e => medias.findIndex(a => e.id === a.id) === -1
    );
    newState.entities.push(...medias);
    return newState;
  };

  _receiveByArticle = (state, article, medias) => {
    let newState = Object.assign({}, state);
    newState.entities = state.entities.filter(
      e => medias.findIndex(a => e.id === a.id) === -1
    );
    newState.entities.push(...medias);
    return newState;
  };

  _receiveMany = (state, medias) => {
    const newState = Object.assign({}, state);
    // We remove all the entities that have the same ID as the new medias
    newState.entities = state.entities.filter(
      e => medias.findIndex(a => e.id === a.id) === -1
    );
    newState.entities.push(...medias);
    return newState;
  };

  _deleteOne = (state, mediaId) => {
    const i = state.entities.findIndex(c => c.id === mediaId);
    if (i === -1) {
      return state;
    }
    const newState = Object.assign({}, state);
    newState.entities = state.entities.slice();
    newState.entities.splice(i, 1);
    return newState;
  };

  // /// Public Methods  /////

  getMedia() {
    return this.getState().media;
  }

  /**
   * Get all the medias of a report.
   */
  getByReport = (report) =>
    this.getState().entities.filter(e => e.report && e.report.id === report.id);

  /**
   * Get all the medias of an incident.
   */
  getByIncident = (incident) =>
    this.getState().entities.filter(
      e => e.incident && e.incident.id === incident.id
    );

  /**
   * Get all the medias of a review.
   */
  getByReview = (review) =>
    this.getState().entities.filter(e => e.review.id === review.id);

  /**
   * Get all the medias of an article.
   */
  getByArticle = article => {
    return this.getState().entities.filter(e => e.article.id === article.id);
  };

  /**
   * Get a media by ID.
   * @param {number} id The identifier.
   * @return {?Media} The media, or NULL if no entity is found.
   */
  getById = (id) =>
    this.getState().entities.find(e => e.id === id);

  getByUser = (user) =>
    user.medias
      ? user.medias.map(b => this.getById(b.id)).filter(b => !!b)
      : [];

  /**
   * Get all the medias.
   */
  getAll = () => this.getState().entities;
}

const store = new MediaStore();
export default store;