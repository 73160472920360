import React from 'react';
import { Upload } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';

const UploadButton = () => (
    <div>
        <UserAddOutlined />
        <div className="ant-upload-text">Upload</div>
    </div>
);

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

export default class AvatarUpload extends React.Component {
    state = {
        loading: false,
        imageBase64: null,
    };

    handleChange = (info) => {
        const { onSuccess, onError } = this.props;
        const { status } = info.file;
        if (status === 'uploading') {
            this.setState({ loading: true });
        } else if (status === 'done') {
            onSuccess && onSuccess();
            getBase64(info.file.originFileObj, imageBase64 =>
                this.setState({
                    imageBase64,
                    loading: false,
                }));
        } else if (status === 'error') {
            onError && onError();
            this.setState({
                loading: false,
            });
        }
    };

    render() {
        const { imageUrl, action, headers } = this.props;
        const { loading, imageBase64 } = this.state;

        const url = imageBase64 || imageUrl;
        return (
            <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={action}
                headers={headers}
                onChange={this.handleChange}
            >
                {url ? (
                    <img
                        src={url}
                        alt="Insérer"
                        style={{
                            maxWidth: '120px',
                            maxHeight: '120px',
                            objectFit: 'cover',
                            opacity: loading ? 0.5 : null,
                        }}
                    />
                ) : (
                    <UploadButton loading={loading} />
                )}
            </Upload>
        );
    }
}
