import BaseStore from "stores/BaseStore";

import ThemeConstants from 'constants/ThemeConstants';
import Color from "color";

const BASE_COLOR = "#5dc4e2";

class ThemeStore extends BaseStore {
  getInitialState() {
    return extractAllValues({
      "--theme-primary-color": BASE_COLOR
    });
  }

  reduce(state, action) {
    const { type, payload } = action;
    switch (type) {
      case ThemeConstants.UPDATE_THEME:
        return { ...state, ...extractAllValues(payload.theme.value) };
      default:
        break;
    }
    return state;
  }

  get = () => this.getState();
}

const extractAllValues = base => {
  let values = {};
  if (base === undefined) {
    return values;
  }
  if (base["--theme-primary-color"] !== undefined) {
    const color = Color(base["--theme-primary-color"]);
    
    values = {
      ...values,
      ...{
        "--theme-primary-color": color.string(),
        "--theme-light-primary-color": color.alpha(0.2).string(),
        "--theme-vlight-primary-color": color.alpha(0.1).string(),
        "--theme-mild-primary-color": color.alpha(0.5).string(),
        "--theme-lightened-primary-color": color.lighten(0.07),
        "--theme-darkened-primary-color": color.darken(0.07)
      }
    };
  }
  if (base["--theme-login-image"] !== undefined) {
    values = {
      ...values,
      "--theme-login-image": `url("${base["--theme-login-image"]}")`
    };
  }
  return values;
};

const store = new ThemeStore();
export default store;