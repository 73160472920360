import ArchivedEntityStoreBase from "../stores/ArchivedEntityStoreBase";
import MissionConstants from "../constants/MissionConstants";

class MissionStore extends ArchivedEntityStoreBase {
  getInitialState() {
    return {
      currentMissionId: null,
      entities: [],
      archived: [],
    };
  }

  reduce(state, action) {
    const { type, payload } = action;
    let newState = Object.assign({}, state);

    switch (type) {
      case MissionConstants.RECEIVE_MISSIONS_OF_USER:
        newState = this._receiveByUser(state, payload.user, payload.missions);
        break;
      case MissionConstants.RECEIVE_MISSION:
        newState = this._receiveEntity(state, payload.mission);
        break;
      case MissionConstants.RECEIVE_ARCHIVED_MISSION:
        newState = this._receiveArchivedEntity(state, payload.mission);
        break;
      case MissionConstants.RECEIVE_MISSIONS:
        newState = this._receiveMany(state, payload.missions);
        break;
      case MissionConstants.RECEIVE_ALL_MISSIONS:
          newState = this._receiveAll(state, payload.missions);
          break;
      case MissionConstants.RECEIVE_ARCHIVED_MISSIONS:
          newState = this._receiveArchived(state, payload.missions);
          break;
      case MissionConstants.CHANGE_CURRENT_MISSION:
        newState.currentMissionId = payload.missionId;
        break;
      case "clearAll":
        newState = this.getInitialState();
        break;
      default:
        newState = state;
    }
    return newState;
  }

  _receiveByUser = (state, user, missions) => {
    const newState = Object.assign({}, state);
    newState.entities = state.entities.filter(
      e => missions.findIndex(a => e.id === a.id) === -1
    );
    newState.entities.push(...missions);
    return newState;
  };

  _receiveMany = (state, missions) => {
    const newState = Object.assign({}, state);
    newState.entities = state.entities.filter(
      e => missions.findIndex(a => e.id === a.id) === -1
    );
    newState.entities.push(...missions);
    return newState;
  };

  _receiveArchived = (state, missions) => {
    let newState = Object.assign({}, state);
    newState.archived = state.archived.filter(
        e => missions.findIndex(a => e.id === a.id) === -1
    );
    newState.archived.push(...missions);
    return newState;
  };

  // /// Public Methods  /////

  getMission() {
    return this.getState().mission;
  }

  /**
   * Get a mission by ID.
   * @param {number} id The identifier.
   * @return {?Mission}    The mission, or NULL if no entity is found.
   */
  getById = id => this.getState().entities.find(e => e.id === id);

  /**
   * Get an archived mission by ID.
   * @param {number} id The identifier of the archived mission.
   * @return {?Mission}    The archived mission, or NULL if no entity is found.
   */
  getArchivedById = id => this.getState().archived.find(e => e.id === id);

  /**
   * Get all missions of a user.
   */
  getByUser = user =>
    this.getState().entities.filter(e => !!e.users.find(a => a.user.id === user.id));

  /**
   * Get all the missions.
   */
  getAll = () => this.getState().entities.concat(this.getState().archived);

  /**
   * Get unarchived missions.
   */
  getMissions = () => this.getState().entities;

  getArchivedMissions = () => this.getState().archived;

  /**
   * Get the identifier of the current mission.
   * @return {[type]} [description]
   */
  getCurrentMissionId = () =>
    this.getState().currentMissionId ||
    (this.getState().entities.length ? this.getState().entities[0].id : null);

  /**
   * Get the identifier of the current mission.
   * @return {[type]} [description]
   */
  getCurrentMission = () => this.getById(this.getCurrentMissionId());
}

const store = new MissionStore();
export default store;
