import React from 'react';
import { Form, Input, Button } from 'antd';
import Locale from 'locale/LocaleFactory';

interface IProps {
    loading: boolean
    onSubmit: (values: any) => void
}
const ResetPasswordForm: React.FC<IProps> = ({ loading, onSubmit }) => {
    const [form] = Form.useForm();

    const checkPassword = (rule: any, value: string, callback: (err?: string) => void) => {
        if (value && value !== form.getFieldValue('plainPassword')) {
            callback('Les mots de passe ne sont pas identiques!');
        } else {
            callback();
        }
    };

    return (
        <Form onFinish={onSubmit} layout="vertical" form={form}>
            <Form.Item
                name="plainPassword"
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: Locale.trans('user.password.error.required'),
                    },
                ]}
            >
                <Input type="password" placeholder={Locale.trans('user.password')} />
            </Form.Item>
            <Form.Item
                name="passwordConfirm"
                hasFeedback
                rules={[
                    {
                        validator: checkPassword,
                    },
                ]}
            >
                <Input
                    type="password"
                    placeholder={Locale.trans('user.passwordConfirm')}
                />
            </Form.Item>
            <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
            >
                {Locale.trans('edit')}
            </Button>
        </Form>
    );
}

export default ResetPasswordForm;
