import React from "react";
import { Tabs } from "antd";

import Panel from "components/Panel.jsx";

import Locale from "locale/LocaleFactory";

import UserList from "views/User/UserList";
import HostCompanyList from "views/HostCompany/HostCompanyList.jsx";
import EstablishmentList from "views/Establishment/EstablishmentList.jsx";
import SkillList from "views/Skill/SkillList.jsx";
import SubSkillList from "views/SubSkill/SubSkillList.jsx";
import PartnerList from "views/Partner/PartnerList.jsx";
import JobList from "views/Job/JobList.jsx";
import UserTypeList from "views/configuration/userType/UserTypeList";

export default class Home extends React.Component {
  render() {
    return (
      <Panel title="Configuration">
        <Tabs
          items={[
            {
              key: "user",
              label: "Utilisateurs",
              children: <UserList />
            },
            {
              key: "host-company",
              label: Locale.trans("hostCompanies"),
              children: <HostCompanyList />
            },
            {
              key: "establishment",
              label: Locale.trans("establishments"),
              children: <EstablishmentList />
            },
            {
              key: "partner",
              label: Locale.trans("partners"),
              children: <PartnerList />
            },
            {
              key: "job",
              label: Locale.trans("jobs"),
              children: <JobList />
            },
            {
              key: "skill",
              label: Locale.trans("skill.skills"),
              children: <SkillList />
            },
            {
              key: "subskill",
              label: Locale.trans("subSkill.subSkills"),
              children: <SubSkillList />
            },
            {
              key: "userTypes",
              label: Locale.trans("userTypes"),
              children: <UserTypeList />
            }
          ]}
        />
      </Panel>
    );
  }
}
