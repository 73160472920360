import dispatcher from "../dispatchers/AppDispatcher";
import ActionsBase from "../actions/ActionsBase";

import SubSkillConstants from "../constants/SubSkillConstants";
import SubSkillService from "../services/SubSkillService";

import CacheManager from "../services/CacheManager";

const CACHE_DURATION = 30 * 1000;

class SubSkillActions extends ActionsBase {
    create = (subSkill) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(newSubSkill) {
                dispatcher.dispatch({
                    type: SubSkillConstants.RECEIVE_SUB_SKILL,
                    payload: {
                        subSkill: newSubSkill,
                    },
                });
                resolve(newSubSkill);
            }
            function handleError(err) {
                $this.handleError(err, reject);
            }
            SubSkillService.post(subSkill)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    edit = (id, subSkill) => {
        const $this = this;
        return new Promise((
            resolve,
            reject
        ) => {
            function handleSuccess(newSubSkill) {
                dispatcher.dispatch({
                    type: SubSkillConstants.RECEIVE_SUB_SKILL,
                    payload: {
                        subSkill: newSubSkill,
                    },
                });
                resolve(newSubSkill);
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            SubSkillService.patch(id, subSkill)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    archiving = (subSkillId) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(newSubSkill) {
                dispatcher.dispatch({
                    type: SubSkillConstants.RECEIVE_ARCHIVED_SUB_SKILL,
                    payload: {
                        subSkill: newSubSkill,
                    },
                });
                resolve();
            }
            function handleError(err) {
                $this.handleError(err, reject);
            }
            SubSkillService.archiving(subSkillId)
                .then(handleSuccess)
                .catch(handleError);
        });
      };

    delete = (id) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess() {
                dispatcher.dispatch({
                    type: SubSkillConstants.DELETE_SUB_SKILL,
                    payload: {
                        id,
                    },
                });
                resolve();
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            SubSkillService.remove(id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reload = () => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(subSkills) {
                dispatcher.dispatch({
                    type: SubSkillConstants.RECEIVE_SUB_SKILLS,
                    payload: {
                        subSkills,
                    },
                });
                resolve();
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            SubSkillService.getAll()
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadArchived = () => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(subSkills) {
                dispatcher.dispatch({
                    type: SubSkillConstants.RECEIVE_ARCHIVED_SUB_SKILLS,
                    payload: {
                        subSkills,
                    },
                });
                resolve();
            }
            function handleError(err) {
                $this.handleError(err, reject);
            }
            SubSkillService.getAllArchived()
                .then(handleSuccess)
                .catch(handleError);
        });
      };

    changeCurrentSubSkillId = (subSkillId) => {
        return new Promise(
            (resolve, reject) => {
                dispatcher.dispatch({
                    type: SubSkillConstants.CHANGE_CURRENT_SUB_SKILL,
                    payload: {
                        subSkillId
                    }
                });
                resolve();
            }
        );
    };

    reloadById = (id) => {
        const $this = this;
        return new Promise(
            (resolve, reject) => {
                function handleSuccess(subSkill) {
                    dispatcher.dispatch({
                        type: SubSkillConstants.RECEIVE_SUB_SKILL,
                        payload: {
                            subSkill
                        }
                    });
                    resolve();
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                SubSkillService.getById(id)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };

    reloadBySkill = (skill) => {
        const $this = this;
        return new Promise(
            (resolve, reject) => {
                if (
                    CacheManager.isCached(
                        "SubSkill:reloadBySkill",
                        skill.id.toString(),
                        CACHE_DURATION
                    )
                ) {
                    resolve();
                    return;
                }

                function handleSuccess(subSkills) {
                    dispatcher.dispatch({
                        type: SubSkillConstants.RECEIVE_SUB_SKILLS_OF_SKILL,
                        payload: {
                            skill,
                            subSkills
                        }
                    });
                    resolve();
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                SubSkillService.getBySkill(skill.id)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };

    reloadByUserHasSkill = (userHasSkill) => {
        const $this = this;
        return new Promise(
            (resolve, reject) => {
                if (
                    CacheManager.isCached(
                        "SubSkill:reloadByUserHasSkill",
                        userHasSkill.id.toString(),
                        CACHE_DURATION
                    )
                ) {
                    resolve();
                    return;
                }

                function handleSuccess(subSkills) {
                    dispatcher.dispatch({
                        type: SubSkillConstants.RECEIVE_SUB_SKILLS_OF_USER_HAS_SKILL,
                        payload: {
                            userHasSkill,
                            subSkills
                        }
                    });
                    resolve();
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                SubSkillService.getByUserHasSkill(userHasSkill.id)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };
}

const action = new SubSkillActions();
export default action;