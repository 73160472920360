import ServiceBase from "../services/ServiceBase";
import BaseUrlConstants from "../constants/BaseUrlConstants";

const URL = `${BaseUrlConstants.BASE_URL}`;

class ArticleService extends ServiceBase {
  constructor() {
    super(URL);
  }

  /**
   * Get an article by unique identifier.
   * @param {number} articleId The identifier of the article.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getById(articleId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}articles/${articleId}`,
      method: "GET"
    });
  }

  /**
   * Get articles of a report.
   * @param {number} reportId The identifier of the report.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getByReport(reportId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}reports/${reportId}/articles`,
      method: "GET"
    });
  }

  /**
   * Get articles of a incident.
   * @param {number} incidentId The identifier of the incident.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getByIncident(incidentId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}incidents/${incidentId}/articles`,
      method: "GET"
    });
  }

  /**
   * Get articles of a review.
   * @param {number} reviewId The identifier of the review.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getByReview(reviewId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}reviews/${reviewId}/articles`,
      method: "GET"
    });
  }

  /**
   * Get all articles.
   *
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getAll() {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}articles`,
      method: "GET"
    });
  }

  /**
   * Post a new article to an review.
   * @param {Object} article The article to create.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  post(article) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}articles`,
      method: "POST",
      data: article
    });
  }

  /**
   * Patch an existing resource. Only the properties that are set on the patch will be updated.
   * @param {number} articleId The identifier of the article.
   * @param {Object} patch The properties to update.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  patch(articleId, patch) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}articles/${articleId}`,
      method: "PATCH",
      data: patch
    });
  }

  /**
   * Delete an existing article.
   * @param {number} articleId The identifier of the article.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  remove(articleId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}articles/${articleId}`,
      method: "DELETE"
    });
  }
}

const service = new ArticleService();
export default service;