import React from "react";
import ReviewForm from "./ReviewForm";
import { Modal } from "antd";
import ReviewService from 'services/ReviewService';
import ReviewActions from "actions/ReviewActions";
import DateService from "services/utils/DateService";

interface IProps {
    missionId: number
    onCancel: () => void
    visible: boolean
}

const CreateReviewModal: React.FC<IProps> = ({
    missionId,
    onCancel,
    visible
}) => {
    async function handleSubmit(values: any) {
        await ReviewService.post({
            ...values,
            mission: missionId,
            date: DateService.formatPostApi(values.date)
        });
        ReviewActions.reloadByMission({ id: missionId });
        onCancel();
    }

    return (
        <Modal
            title="Rédiger un bilan"
            open={visible}
            onCancel={onCancel}
            footer={null}
        >
            <ReviewForm
                missionId={missionId}
                onSubmit={handleSubmit}
            />
        </Modal>
    );
}

export default CreateReviewModal;