import ServiceBase from "../services/ServiceBase";
import BaseUrlConstants from "../constants/BaseUrlConstants";

const URL = `${BaseUrlConstants.BASE_URL}`;

class ThemeService extends ServiceBase {
    constructor() {
      super(URL);
    }
  
    /**
     * Get a theme by organisation unique identifier.
     * @param {number} organisation The identifier of the organisation.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getByOrganisation(organisation) {
      return this.execute({
        url: `${BaseUrlConstants.BASE_URL}organisations/${organisation}/theme`,
        method: "GET"
      });
    }

    getForPlateform() {
      return this.execute({
        url: `${BaseUrlConstants.BASE_URL}theme`,
        method: "GET"
      });
    }
};

const service = new ThemeService();
export default service;