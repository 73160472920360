import React from 'react';

import { Table, Button, Tooltip } from 'antd';
import TableColumnFilter from 'components/TableColumnFilter.jsx';
import FilterIcon from 'components/FilterIcon.jsx';
import CreateEstablishmentModal from 'views/Establishment/CreateEstablishmentModal.jsx';
import EditEstablishmentModal from 'views/Establishment/EditEstablishmentModal.jsx';
import LoadingIcon from 'components/LoadingIcon.jsx';
import ToastActions from "actions/ToastActions";

import Locale from 'locale/LocaleFactory';
import ArrayService from 'services/utils/ArrayService';
import StringService from 'services/utils/StringService';
import FilterService from 'services/utils/FilterService';
import SecurityService from "services/SecurityService";
import Resource from "constants/Resource";
import Access from "constants/AccessLevel";

import EstablishmentActions from 'actions/EstablishmentActions';
import EstablishmentStore from 'stores/EstablishmentStore';
import { EditOutlined, EyeInvisibleOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';

// Sorting Methods
function sortNameColumn(c1, c2) {
    return StringService.compareCaseInsensitive(c1.name, c2.name);
}

/**
 * The list of the establishments.
 */
export default class EstablishmentList extends React.Component {
    constructor() {
        super();

        const establishments = EstablishmentStore.getEstablishments();

        this.state = {
            loading: !establishments.length,
            establishments,

            filteredEstablishments: [],

            filterGlobal: '',
            filters: {
                name: [],
            },

            createEstablishmentVisible: false,
            editEstablishmentVisible: false,
            establishmentToEdit: null,
            archivedVisible: false,
            selectedRowKeys: [],
        };
    }

    componentDidMount() {
        this.EstablishmentListener = EstablishmentStore.addListener(this.receiveEstablishments);
        this.archivedEstablishmentListener = EstablishmentStore.addListener(this.receiveArchivedEstablishments);
        this.loadEstablishments();
        this.updateFilters();
    }

    componentWillUnmount() {
        this.EstablishmentListener.remove();
        this.archivedEstablishmentListener.remove();
    }

    loadEstablishments = () => {
        this.setState({
            loading: true
        });
        EstablishmentActions.reload().then(() => {
            this.setState({
                loading: false,
                archivedVisible: false,
                selectedRowKeys : [],
            });
        });
        this.EstablishmentListener = EstablishmentStore.addListener(this.receiveEstablishments);
    };
    
    loadArchivedEstablishments = () => {
        this.setState({ loading: true });
        EstablishmentActions.reloadArchived().then(() => {
            this.setState({
                loading: false,
                archivedVisible: true,
                selectedRowKeys : [],
            });
        });
        this.archivedEstablishmentListener = EstablishmentStore.addListener(this.receiveArchivedEstablishments);
    };

    receiveEstablishments = () => {
        const establishments = EstablishmentStore.getEstablishments();
        this.setState(
            {
                establishments,
            },
            this.updateFilters,
        );
    };

    receiveArchivedEstablishments = () => {
        this.setState(
          {
            establishments: EstablishmentStore.getArchivedEstablishments()
          },
          this.updateFilters
        );
    };

    // Filters
    updateFilters = () => {
        const { establishments } = this.state;
        const filteredEstablishments = establishments.filter(this.establishmentMatchFilters);
        this.setState({ filteredEstablishments });
    };

    establishmentMatchFilters = (e) => {
        const { filters } = this.state;
        return (
            FilterService.matchFilter(filters.name, e.name) &&
            this.matchGlobalSearch(e)
        );
    };

    searchGlobal = (e) => {
        this.setState({ filterGlobal: e.target.value.toLowerCase() });
        this.updateFilters();
    };

    matchGlobalSearch = (establishment) => {
        const { filterGlobal } = this.state;
        return establishment.name.toLowerCase().indexOf(filterGlobal) > -1;
    };

    handleFilterChange = (name, values) => {
        let prevState = this.state.filters;
        prevState[name] = values;
        this.setState({filters: prevState});
        this.updateFilters();
    };

    getEstablishmentNames = () => {
        return ArrayService.unique(this.state.establishments.map(c => c.name));
    }
       

    showCreateEstablishmentModal = () => {
        this.setState({
            createEstablishmentVisible: true,
        });
    };
    hideCreateEstablishmentModal = () => {
        this.setState({
            createEstablishmentVisible: false,
        });
    };

    editEstablishment = (establishment) => {
        this.setState({
            editEstablishmentVisible: true,
            establishmentToEdit: establishment,
        });
    };
    hideEditEstablishmentModal = () => {
        this.setState({
            editEstablishmentVisible: false,
            establishmentToEdit: null,
        });
    };

    archiving = (establishment, toasVisible) => {
        const { archivedVisible } = this.state;
        if (!establishment) return;
        this.setState({
          loading: true
        });
        return new Promise((resolve, reject) => {
            EstablishmentActions.archiving(establishment.id).then(newEstablishment => {
            resolve(newEstablishment);
            this.setState({
              loading: false
            });
            if(toasVisible) {
              ToastActions.createToastSuccess(
                archivedVisible ? `Etablissement "${establishment.name}" rétablit` : `Etablissement "${establishment.name}" archivé` 
              );
            }
          }).catch(this.handleError);
        })
    };

    archiveSelected = () => {
        const { selectedRowKeys, archivedVisible } = this.state;
        const promises = selectedRowKeys.forEach(r => {
            this.setState({
            loading: true
            });
            const establishment = archivedVisible ? EstablishmentStore.getArchivedById(r) : EstablishmentStore.getById(r);
            this.archiving(establishment, false);
        });
        if(promises) {
            return Promise.all(promises).then(() => {
                ToastActions.createToastSuccess(
                    archivedVisible ? "Etablissements rétablis" : "Etablissements archivés" 
                );
            });
        }
    };

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    handleError = err => {
        this.setState({
            loading: false
        });
        try {
            const resp = JSON.parse(err.response);
            ToastActions.createToastError(resp.message);
        } catch (e) {
            ToastActions.createToastError("Une erreur est survenue");
        }
    };

    render() {
        const { establishmentToEdit, loading, filteredEstablishments, archivedVisible, selectedRowKeys } = this.state;
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <div className="establishment-list">
                {!loading &&
                    SecurityService.isGranted(Resource.ESTABLISHMENT, Access.CREATE) && (
                        <Button type="primary" icon={<PlusOutlined />} onClick={this.showCreateEstablishmentModal}>
                            Ajouter un établissement
                        </Button>
                )}
                {!loading &&
                    <Button
                        type="danger"
                        style={{color: !archivedVisible && "#f04134", marginBottom: "1%", float: "right"}}
                        ghost={!archivedVisible}
                        icon={archivedVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                        onClick={() => {archivedVisible ? this.loadEstablishments() : this.loadArchivedEstablishments()} }
                        >
                            {archivedVisible ? Locale.trans("archive.unarchived") : Locale.trans("archive.archived")}  
                    </Button>
                }
                {this.renderEstablishmentTable()}

                {!loading && (
                    <div className="actions-row" style={{ marginTop: !filteredEstablishments || filteredEstablishments.length === 0 ? '10px' : '-50px' }}>
                        {selectedRowKeys.length > 0 && (
                            <Button icon={archivedVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />} type="danger" onClick={this.archiveSelected} disabled={!hasSelected} loading={loading}>
                            {archivedVisible ? `Rétablir ${selectedRowKeys.length} établissement(s)` : `Archiver ${selectedRowKeys.length} établissement(s)`}
                            </Button>
                        )}
                    </div>
                )}
                <CreateEstablishmentModal
                    onCancel={this.hideCreateEstablishmentModal}
                    visible={this.state.createEstablishmentVisible}
                />
                <EditEstablishmentModal
                    establishment={establishmentToEdit}
                    onCancel={this.hideEditEstablishmentModal}
                    visible={this.state.editEstablishmentVisible}
                />
            </div>
        );
    }

    renderEstablishmentTable() {
        const { filters, filteredEstablishments, selectedRowKeys, loading } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const columns = [
            {
                title: Locale.trans('establishment.name'),
                key: 'name',
                sorter: sortNameColumn,
                filterIcon: <FilterIcon active={filters.name.length > 0} />,
                render: this.renderEstablishmentNameCell,
                filterDropdown: (
                    <TableColumnFilter
                        name="name"
                        selectedValues={filters.name}
                        values={this.getEstablishmentNames().map(r => ({ text: r, value: r }))}
                        onChange={this.handleFilterChange}
                    />
                ),
            },
            {
                title: null,
                key: 'actions',
                width: '50px',
                render: this.rendActionsCell,
            },
        ];

        return (
            <Table
                rowSelection={rowSelection}
                dataSource={filteredEstablishments}
                rowKey="id"
                columns={columns}
                locale={Locale.Table}
                ref={(r) => {
                    this.table = r;
                }}
                loading={loading && { indicator: <LoadingIcon /> }}
            />
        );
    }

    renderEstablishmentNameCell = (establishment) => establishment.name;

    rendActionsCell = (establishment) => (
        <div className="actions-row">
            {this.state.archivedVisible ? (
                <Tooltip title={Locale.trans("archive.unarchive")}>
                    <Button
                    shape="circle"
                    icon={<EyeOutlined />}
                    onClick={() => this.archiving(establishment, true)}
                    />
                </Tooltip>
                ) : (
                <Tooltip title={Locale.trans("archive.action")}>
                    <Button
                    shape="circle"
                    icon={<EyeInvisibleOutlined />}
                    onClick={() => this.archiving(establishment, true)}
                    />
                </Tooltip>
            )}
            <Tooltip title={Locale.trans("edit")}>
                <Button
                    type="primary"
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={(e) => {
                        this.editEstablishment(establishment);
                        e.stopPropagation();
                        e.preventDefault();
                        return false;
                    }}
                />
            </Tooltip>
        </div>
    );
}
