
import ServiceBase from './ServiceBase';
import BaseUrlConstants from '../constants/BaseUrlConstants';

const URL = `${BaseUrlConstants.BASE_URL}`;

class UserTypeService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Get a user type by unique identifier.
     * @param {number} userTypeId The identifier of the user type.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getById(userTypeId) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}user-types/${userTypeId}`,
            method: 'GET',
        });
    }

    /**
     * Get all user types.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getAll() {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}user-types`,
            method: 'GET',
        });
    }

    getFeatures() {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}features`,
            method: 'GET',
        });
    }

    patch(id, patch) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}user-types/${id}`,
            method: 'PATCH',
            data: patch,
        });
    }
}

const service = new UserTypeService();
export default service;