
import ServiceBase from "../services/ServiceBase";
import BaseUrlConstants from "../constants/BaseUrlConstants";

const URL = `${BaseUrlConstants.BASE_URL}`;

class MediaService extends ServiceBase {
  constructor() {
    super(URL);
  }

  /**
   * Get a media by unique identifier.
   * @param {number} mediaId The identifier of the media.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getById(mediaId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}medias/${mediaId}`,
      method: "GET"
    });
  }

  /**
   * Get medias of a report.
   * @param {number} reportId The identifier of the report.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getByReport(reportId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}reports/${reportId}/medias`,
      method: "GET"
    });
  }

  /**
   * Get medias of an incident.
   * @param {number} incidentId The identifier of the incident.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getByIncident(incidentId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}incidents/${incidentId}/medias`,
      method: "GET"
    });
  }

  /**
   * Get medias of a review.
   * @param {number} reviewId The identifier of the review.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getByReview(reviewId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}reviews/${reviewId}/medias`,
      method: "GET"
    });
  }

  /**
   * Get medias of an article.
   * @param {number} articleId The identifier of the article.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getByArticle(articleId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}articles/${articleId}/medias`,
      method: "GET"
    });
  }

  /**
   * Get all medias.
   *
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getAll() {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}medias`,
      method: "GET"
    });
  }

  /**
   * Post a new media to an user.
   * @param {Object} media The media to create.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  post(media) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}medias`,
      method: "POST",
      data: media
    });
  }

  /**
   * Patch an existing media. Only the properties that are set on the patch will be updated.
   * @param {number} mediaId The identifier of the media.
   * @param {Object} patch The properties to update.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  patch(mediaId, patch) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}medias/${mediaId}`,
      method: "PATCH",
      data: patch
    });
  }

  /**
   * Delete an existing media.
   * @param {number} mediaId The identifier of the media.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  remove(mediaId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}medias/${mediaId}`,
      method: "DELETE"
    });
  }
}

const service = new MediaService();
export default service;