import ArchivedEntityStoreBase from "../stores/ArchivedEntityStoreBase";
import ReportConstants from "../constants/ReportConstants";

class ReportStore extends ArchivedEntityStoreBase {
    getInitialState() {
        return {
            entities: [],
            archived: [],
        };
    }

    reduce(state, action) {
        const { type, payload } = action;
        let newState = Object.assign({}, state);

        switch (type) {
            case ReportConstants.RECEIVE_REPORTS_OF_USER:
                newState = this._receiveByUser(
                    state,
                    payload.user,
                    payload.reports
                );
                break;
            case ReportConstants.RECEIVE_REPORTS_OF_MISSION:
                newState = this._receiveByMission(
                    state,
                    payload.mission,
                    payload.reports
                );
                break;
            case ReportConstants.RECEIVE_REPORT:
                newState = this._receiveEntity(state, payload.report);
                break;
            case ReportConstants.RECEIVE_REPORTS:
                newState = this._receiveMany(state, payload.reports);
                break;
            case ReportConstants.DELETE_REPORT:
                newState = this._deleteOne(state, payload.id);
                break;
            case ReportConstants.RECEIVE_ALL_REPORTS:
                newState = this._receiveAll(state, payload.reports);
                break;
            case ReportConstants.RECEIVE_ARCHIVED_REPORTS:
                newState = this._receiveArchived(state, payload.reports);
                break;
            case "clearAll":
                newState = this.getInitialState();
                break;
            default:
                newState = state;
        }
        return newState;
    }

    _receiveByUser = (state, user, reports) => {
        let newState = Object.assign({}, state);
        newState.entities = state.entities.filter(
            e => reports.findIndex(a => e.id === a.id) === -1
        );
        newState.entities.push(...reports);
        return newState;
    };

    _receiveByMission = (state, mission, reports) => {
        let newState = Object.assign({}, state);
        newState.entities = state.entities.filter(
            e => reports.findIndex(a => e.id === a.id) === -1
        );
        newState.entities.push(...reports);
        return newState;
    };

    _receiveMany = (state, reports) => {
        let newState = Object.assign({}, state);
        // We remove all the entities that have the same ID as the new reports
        newState.entities = state.entities.filter(
            e => reports.findIndex(a => e.id === a.id) === -1
        );
        newState.entities.push(...reports);
        return newState;
    };

    _receiveArchived = (state, reports) => {
        let newState = Object.assign({}, state);
        newState.archived = state.archived.filter(
            e => reports.findIndex(a => e.id === a.id) === -1
        );
        newState.archived.push(...reports);
        return newState;
    };

    _deleteOne = (state, reportId) => {
        const i = state.entities.findIndex(c => c.id === reportId);
        if (i === -1) {
            return state;
        }
        let newState = Object.assign({}, state);
        newState.entities = state.entities.slice();
        newState.entities.splice(i, 1);
        return newState;
    };

    ///// Public Methods  /////

    getReport() {
        return this.getState().report;
    }

    /**
     * Get all the reports of an user.
     */
    getByUser = (user) => {
        return this.getState().entities.filter(e => e.user.findIndex(u => u.id === user.id) > -1);
    };

    /**
     * Get all the reports of an mission.
     */
    getByMission = (mission) => {
        return this.getState().entities.filter(e => e.mission.id === mission.id);
    };

    /**
     * Get a report by ID.
     * @param {number} id The identifier.
     * @return {?Report} The report, or NULL if no entity is found.
     */
    getById = (id) => {
        return this.getState().entities.find(e => e.id === id) || this.getState().archived.find(e => e.id === id);
    };

    /**
     * Get an archived report by ID.
     * @param {number} id The identifier of the archived report.
     * @return {?Report}    The archived report, or NULL if no entity is found.
     */
    getArchivedById = id => this.getState().archived.find(e => e.id === id);

    /**
     * Get all the reports.
     */
    getAll = () => this.getState().entities.concat(this.getState().archived);

    /**
     * Get unarchived reports.
     */
    getReports = () => this.getState().entities;

    getArchivedReports = () => this.getState().archived;
}

const store = new ReportStore();
export default store;