
import ServiceBase from "./ServiceBase";
import BaseUrlConstants from "../constants/BaseUrlConstants";
import TokenContainer from "./TokenContainer";

class EmargementService extends ServiceBase {

  /**
   * Get a emargement by unique identifier.
   * @param {number} emargementId The identifier of the emargement.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getById(emargementId: number) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}emargements/${emargementId}`,
      method: "GET"
    });
  }

  /**
   * Get emargement of a mission.
   * @param {number} missionId The identifier of the mission.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getByMission(missionId: number) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}missions/${missionId}/emargements`,
      method: "GET"
    });
  }

  /**
   * Get emargement of a user.
   * @param {number} userId The identifier of the user.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getByUser(userId: number) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}users/${userId}/emargements`,
      method: "GET"
    });
  }

  /**
   * Get all emargements.
   *
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getAll() {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}emargements`,
      method: "GET"
    });
  }

  /**
   * Post a new emargement to an user.
   * @param {Object} emargement The emargement to create.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  post(emargement: any) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}emargements`,
      method: "POST",
      data: emargement
    });
  }

  /**
   * Patch an existing resource. Only the properties that are set on the patch will be updated.
   * @param {number} emargementId The identifier of the emargement.
   * @param {Object} patch The properties to update.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  patch(emargementId: number, patch: any) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}emargements/${emargementId}`,
      method: "PATCH",
      data: patch
    });
  }

  /**
   * Delete an existing emargement.
   * @param {number} emargementId The identifier of the emargement.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  remove(emargementId: number) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}emargements/${emargementId}`,
      method: "DELETE"
    });
  }

  export(startDate: string, endDate: string, missionTypes?: string[] | null, userIds?: string[] | null, missionIds?: string[] | null) {
    const token = TokenContainer.get().replace(/[+]/g, "%2B");
    const url = `${BaseUrlConstants.BASE_URL}exports/emargements.csv`;

    let params = `&startDate=${startDate}&endDate=${endDate}`;
    if (missionTypes && missionTypes.length > 0) {
      params = `${params}&missionTypes=${missionTypes?.join(',')}`
    }
    if (userIds && userIds.length > 0) {
      params = `${params}&userIds=${userIds?.join(',')}`
    }
    if (missionIds && missionIds.length > 0) {
      params = `${params}&missionIds=${missionIds?.join(',')}`
    }

    window.open(`${url}?X-Auth-Token=${token}${params}`)
  }

  download(groupBy: 'user' | 'date', startDate: string | null, endDate: string | null, missionTypes?: string[] | null, userIds?: string[] | null, missionIds?: string[] | null) {
    const token = TokenContainer.get().replace(/[+]/g, "%2B");
    const url = `${BaseUrlConstants.BASE_URL}exports/emargements.pdf`;

    let params = `&groupBy=${groupBy}`;
    if(startDate) {
      params = `${params}&startDate=${startDate}`
    }
    if(endDate) {
      params = `${params}&endDate=${endDate}`
    }
    if (missionTypes && missionTypes.length > 0) {
      params = `${params}&missionTypes=${missionTypes?.join(',')}`
    }
    if (userIds && userIds.length > 0) {
      params = `${params}&userIds=${userIds?.join(',')}`
    }
    if (missionIds && missionIds.length > 0) {
      params = `${params}&missionIds=${missionIds?.join(',')}`
    }

    window.open(`${url}?X-Auth-Token=${token}${params}`)
  }

  /**
   * Download user's emargements in PDF format.
   * @param  {number} userId The ID of the user.
   * @return {[type]}            [description]
   */
  downloadByIntern(userId: number, period: any) {
    const token = TokenContainer.get().replace(/[+]/g, "%2B");
    const url = `${BaseUrlConstants.BASE_URL
      }users/${userId}/emargements.pdf`;
    const periodStr = encodeURIComponent(JSON.stringify(period));
    const periodParam = periodStr ? "&period=" + periodStr : "";
    window.open(`${url}?X-Auth-Token=${token}${periodParam}`);
  }
}

const service = new EmargementService();
export default service;