import ArchivedEntityStoreBase from "../stores/ArchivedEntityStoreBase";
import SkillConstants from "../constants/SkillConstants";

class SkillStore extends ArchivedEntityStoreBase {
    getInitialState() {
        return {
            currentSkillId: null,
            entities: [],
            archived: [],
        };
    }

    reduce(state, action) {
        const { type, payload } = action;
        let newState = Object.assign({}, state);

        switch (type) {
            case SkillConstants.RECEIVE_SKILLS_OF_USER:
                newState = this._receiveByUser(
                    state,
                    payload.user,
                    payload.skills
                );
                break;
            case SkillConstants.RECEIVE_SKILL:
                newState = this._receiveEntity(state, payload.skill);
                break;
            case SkillConstants.RECEIVE_ARCHIVED_SKILL:
                newState = this._receiveArchivedEntity(state, payload.skill);
                break;
            case SkillConstants.RECEIVE_SKILLS:
                newState = this._receiveMany(state, payload.skills);
                break;
            /*case SkillConstants.RECEIVE_ALL_SKILLS:
                newState = this._receiveAll(state, payload.skills);
                break;*/
            case SkillConstants.RECEIVE_ARCHIVED_SKILLS:
                newState = this._receiveArchived(state, payload.skills);
                break;
            case SkillConstants.CHANGE_CURRENT_SKILL:
                newState.currentSkillId = payload.skillId;
                break;
            case "clearAll":
                newState = this.getInitialState();
                break;
            default:
                newState = state;
        }
        return newState;
    }

    _receiveByUser = (state, user, skills) => {
        let newState = Object.assign({}, state);
        newState.entities = state.entities.filter(
            e => skills.findIndex(a => e.id === a.id) === -1
        );
        newState.entities.push(...skills);
        return newState;
    };

    _receiveMany = (state, skills) => {
        let newState = Object.assign({}, state);
        // We remove all the entities that have the same ID as the new skills
        newState.entities = state.entities.filter(
            e => skills.findIndex(a => e.id === a.id) === -1
        );
        newState.entities.push(...skills);
        return newState;
    };

    _receiveArchived = (state, skills) => {
        let newState = Object.assign({}, state);
        // We remove all the entities that have the same ID as the new skills
        newState.archived = state.archived.filter(
            e => skills.findIndex(a => e.id === a.id) === -1
        );
        newState.archived.push(...skills);
        return newState;
    };

    ///// Public Methods  /////

    getSkill() {
        return this.getState().skill;
    }

    /**
     * Get a skill by ID.
     * @param {number} id The identifier.
     * @return {?Skill}    The skill, or NULL if no entity is found.
     */
    getById = (id) => {
        return this.getState().entities.find(e => e.id === id);
    };

    /**
     * Get an archived skill by ID.
     * @param {number} id The identifier of the archived skill.
     * @return {?Skill}    The archived skill, or NULL if no entity is found.
     */
    getArchivedById = id => this.getState().archived.find(e => e.id === id);

    /**
     * Get all skills of a user.
     */
    getByUser = (user) => {
        return this.getState().entities.filter(e => !!e.user.find(a => a.id === user.id));
    };

    /**
     * Get all the skills.
     */
    getAll = () => this.getState().entities.concat(this.getState().archived);

    /**
     * Get unarchived skills.
     */
    getSkills = () => this.getState().entities;

    getArchivedSkills = () => this.getState().archived;

    /**
     * Get the identifier of the current skill.
     * @return {[type]} [description]
     */
    getCurrentSkillId = () => {
        return (
            this.getState().currentSkillId ||
            (this.getState().entities.length
                ? this.getState().entities[0].id
                : null)
        );
    };

    /**
     * Get the identifier of the current skill.
     * @return {[type]} [description]
     */
    getCurrentSkill = () =>
        this.getById(this.getCurrentSkillId());
}

const store = new SkillStore();
export default store;