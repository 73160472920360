import dispatcher from 'dispatchers/AppDispatcher';
import ActionsBase from 'actions/ActionsBase';

import ThemeConstants from 'constants/ThemeConstants';
import ThemeService from 'services/ThemeService';
import OrganisationStore from "stores/OrganisationStore";

class ThemeActions extends ActionsBase {
    reload = () => {
      const $this = this;
      return new Promise((resolve, reject) => {
        function handleSuccess(theme) {
          dispatcher.dispatch({
            type: ThemeConstants.UPDATE_THEME,
            payload: {
              theme
            }
          });
          resolve();
        }
  
        function handleError(err) {
          $this.handleError(err, reject);
        }
        const organisation = OrganisationStore.getCurrentOrganisation();
        if (organisation) {
          ThemeService.getByOrganisation(organisation.id)
            .then(handleSuccess)
            .catch(handleError);
        } else {
          ThemeService.getForPlateform()
            .then(handleSuccess)
            .catch(handleError);
        }
      });
    };
}

const action = new ThemeActions();
export default action;