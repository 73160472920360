import ServiceBase from '../services/ServiceBase';
import BaseUrlConstants from '../constants/BaseUrlConstants';

class EstablishmentService extends ServiceBase {

    /**
     * Get an establishment by unique identifier.
     * @param {number} establishmentId The identifier of the establishment.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getById(establishmentId) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}establishments/${establishmentId}`,
            method: 'GET',
        });
    }

    /**
     * Get host company of a mission.
     * @param {number} missionId The identifier of the mission.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getByMission(missionId) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}missions/${missionId}/establishment`,
            method: 'GET',
        });
    }

    /**
     * Get all unarchived establishments.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getAll() {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}establishments?archived=false`,
            method: "GET"
        });
    }

    /**
     * Get all archived establishments.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getAllArchived() {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}establishments?archived=true`,
            method: "GET"
        });
    }

    /**
     * Post a new establishment.
     * @param {Object} establishment The establishment to create.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    post(establishment) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}establishments`,
            method: 'POST',
            data: establishment,
        });
    }

    /**
     * Archive or unarchive an establishment.
     * @param {number} establishmentId The identifier of the establishment to archive or unarchive.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    archiving(establishmentId) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}establishments/${establishmentId}/archiving`,
            method: "POST"
        });
    }

    /**
     * Patch an existing resource. Only the properties that are set on the patch will be updated.
     * @param {number} establishmentId The identifier of the report.
     * @param {Object} patch The properties to update.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    patch(establishmentId, patch) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}establishments/${establishmentId}`,
            method: 'PATCH',
            data: patch,
        });
    }

    /**
     * Delete an existing host establishment.
     * @param {number} establishmentId The identifier of the user.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    remove(establishmentId) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}establishments/${establishmentId}`,
            method: 'DELETE',
        });
    }
}

const service = new EstablishmentService();
export default service;