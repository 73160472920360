import dispatcher from "../dispatchers/AppDispatcher";
import ActionsBase from "../actions/ActionsBase";
import ArticleConstants from "../constants/ArticleConstants";
import ArticleService from "../services/ArticleService";
import CacheManager from "../services/CacheManager";

const CACHE_DURATION = 30 * 1000;

class ArticleActions extends ActionsBase {
    create = article => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(newArticle) {
                dispatcher.dispatch({
                    type: ArticleConstants.RECEIVE_ARTICLE,
                    payload: {
                        article: newArticle
                    }
                });
                resolve(newArticle);
            }
            function handleError(err) {
                $this.handleError(err, reject);
            }
            ArticleService.post(article)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    edit = (articleId, article) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(newArticle) {
                dispatcher.dispatch({
                    type: ArticleConstants.RECEIVE_ARTICLE,
                    payload: {
                        article: newArticle
                    }
                });
                resolve(newArticle);
            }
            function handleError(err) {
                $this.handleError(err, reject);
            }
            ArticleService.patch(articleId, article)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    delete = id => {        
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess() {
                dispatcher.dispatch({
                    type: ArticleConstants.DELETE_ARTICLE,
                    payload: {
                        id
                    }
                });
                resolve();
            }
            function handleError(err) {
                $this.handleError(err, reject);
            }
            ArticleService.remove(id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reload = () => {
        const $this = this;
        return new Promise((resolve, reject) => {
            if (CacheManager.isCached("Article:reload", "", CACHE_DURATION)) {
                resolve();
            }
            function handleSuccess(articles) {
                dispatcher.dispatch({
                    type: ArticleConstants.RECEIVE_ARTICLES,
                    payload: {
                        articles
                    }
                });
                resolve();
            }
            function handleError(err) {
                $this.handleError(err, reject);
            }
            ArticleService.getAll()
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    changeCurrentArticleId = articleId => {
        return new Promise((resolve, reject) => {
            dispatcher.dispatch({
                type: ArticleConstants.CHANGE_CURRENT_ARTICLE,
                payload: {
                    articleId
                }
            });
            resolve();
        });
    };è

    reloadByReport = report => {        
        const $this = this;
        return new Promise((resolve, reject) => {
            if (
                CacheManager.isCached(
                    "Article:reloadByReport",
                    report.id.toString(),
                    CACHE_DURATION
                )
            ) {
                resolve();
            }
            function handleSuccess(articles) {          
                dispatcher.dispatch({
                    type: ArticleConstants.RECEIVE_ARTICLES_OF_REPORT,
                    payload: {
                        report,
                        articles
                    }
                });
                resolve();
            }
            function handleError(err) {               
                $this.handleError(err, reject);
            }
            ArticleService.getByReport(report.id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadByIncident = incident => {
        const $this = this;
        return new Promise((resolve, reject) => {
            if (
                CacheManager.isCached(
                    "Article:reloadByIncident",
                    incident.id.toString(),
                    CACHE_DURATION
                )
            ) {
                resolve();
            }
            function handleSuccess(articles) {        
                dispatcher.dispatch({
                    type: ArticleConstants.RECEIVE_ARTICLES_OF_INCIDENT,
                    payload: {
                        incident,
                        articles
                    }
                });
                resolve();
            }
            function handleError(err) {
                $this.handleError(err, reject);
            }
            ArticleService.getByIncident(incident.id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadByReview = review => {
        const $this = this;
        return new Promise((resolve, reject) => {
            if (
                CacheManager.isCached(
                    "Article:reloadByReview",
                    review.id.toString(),
                    CACHE_DURATION
                )
            ) {
                resolve();
            }
            function handleSuccess(articles) {
                dispatcher.dispatch({
                    type: ArticleConstants.RECEIVE_ARTICLES_OF_REVIEW,
                    payload: {
                        review,
                        articles
                    }
                });
                resolve();
            }
            function handleError(err) {
                $this.handleError(err, reject);
            }
            ArticleService.getByReview(review.id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadById = id => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(article) {
                dispatcher.dispatch({
                    type: ArticleConstants.RECEIVE_ARTICLE,
                    payload: {
                        article
                    }
                });
                resolve();
            }
            function handleError(err) {
                $this.handleError(err, reject);
            }
            ArticleService.getById(id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };
}

const action = new ArticleActions();
export default action;
