import React from 'react';
import { Form, Input, Button } from 'antd';
import Locale from 'locale/LocaleFactory';

interface IProps {
    loading: boolean
    onSubmit: (values: any) => void
}
const ResetPasswordForm: React.FC<IProps> = ({ loading, onSubmit }) => {
    const [form] = Form.useForm();
    return (
        <Form onFinish={onSubmit} layout="vertical" form={form}>
            <Form.Item
                name="email"
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: Locale.trans('user.email.error.required'),
                    },
                ]}
            >
                <Input placeholder={Locale.trans('user.email')} />
            </Form.Item>
            <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
            >
                {Locale.trans('send')}
            </Button>
        </Form>
    );
}

export default ResetPasswordForm;
