import ServiceBase from "../services/ServiceBase";
import BaseUrlConstants from "../constants/BaseUrlConstants";

const URL = `${BaseUrlConstants.BASE_URL}`;

class JobService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Get a job by unique identifier.
     * @param {number} jobId The identifier of the job.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getById(jobId) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}jobs/${jobId}`,
            method: "GET"
        });
    }

    /**
     * Get all unarchived jobs.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getAll() {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}jobs?archived=false`,
            method: "GET"
        });
    }

    /**
     * Get all archived jobs.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getAllArchived() {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}jobs?archived=true`,
            method: "GET"
        });
    }

    /**
     * Post a new job.
     * @param {Object} job The job to create.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    post(job) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}jobs`,
            method: 'POST',
            data: job,
        });
    }

    /**
     * Archive or unarchive a job.
     * @param {number} jobId The identifier of the job to archive or unarchive.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    archiving(jobId) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}jobs/${jobId}/archiving`,
            method: "POST"
        });
    }

    /**
     * Patch an existing resource. Only the properties that are set on the patch will be updated.
     * @param {number} id The identifier of the report.
     * @param {Object} patch The properties to update.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    patch(id, patch) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}jobs/${id}`,
            method: 'PATCH',
            data: patch,
        });
    }

    /**
     * Delete an existing job.
     * @param {number} id The identifier of the job.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    remove(id) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}jobs/${id}`,
            method: 'DELETE',
        });
    }
}

const service = new JobService();
export default service;