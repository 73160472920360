import React, { useState } from "react";
import { Button, DatePicker, Form, Modal, Radio, Select } from "antd";
import EmargementService from "services/EmargementService";
import UserSelectFormItem from "components/form-items/UserSelectFormItem";
import MissionSelectFormItem from "components/form-items/MissionSelectFormItem";

const typeOptions = ["Découverte", "Recrutement"].map((u) => ({
    value: u,
    label: u,
}));

const ExportEmargementsModal: React.FC = () => {
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();

    const missionTypes = Form.useWatch('missionTypes', form);

    return (
        <>
            <Button onClick={() => setVisible(true)} >Exporter les émargements</Button>
            {visible && <Modal
                title="Exporter les émargements"
                open
                onCancel={() => setVisible(false)}
                footer={null}
            >
                <Form
                    form={form}
                    onFinish={(values) => {
                        const { period, missionTypes, users, missions, format } = values;
                        if (period && period.length === 2) {
                            const startDate = period[0].format('YYYY-MM-DD');
                            const endDate = period[1].format('YYYY-MM-DD');

                            if (format === "excel") {
                                EmargementService.export(
                                    startDate,
                                    endDate,
                                    missionTypes as string[],
                                    users as string[],
                                    missions as string[]
                                );
                            } else {
                                EmargementService.download(
                                    'user',
                                    startDate,
                                    endDate,
                                    missionTypes as string[],
                                    users as string[],
                                    missions as string[]
                                );
                            }
                        }
                    }}
                    labelCol={{ sm: 7 }}
                >
                    <Form.Item name="period" label="Période">
                        <DatePicker.RangePicker format="DD/MM/YYYY" />
                    </Form.Item>
                    <Form.Item name="missionTypes" label="Types de dispositifs">
                        <Select mode="multiple" options={typeOptions} placeholder="Tous" />
                    </Form.Item>
                    <UserSelectFormItem
                        id="users"
                        multiple
                        label="Stagiaires"
                        initialValue={[]}
                        extraFilters={{ types: [3] }}
                        placeholder="Tous"
                    />
                    <MissionSelectFormItem
                        id="missions"
                        multiple
                        label="Dispositifs"
                        initialValue={[]}
                        extraFilters={{ types: (missionTypes?.length ?? 0) > 0 ? missionTypes : undefined }}
                        placeholder="Tous"
                    />
                    <Form.Item name="format" initialValue="excel" label="Format">
                        <Radio.Group
                            options={[{ label: "Excel", value: "excel" }, { label: "PDF", value: "pdf" }]}
                            optionType="button"
                        />
                    </Form.Item>
                    <Button type='primary' htmlType="submit" block>Exporter</Button>
                </Form>
            </Modal>}
        </>
    );
}

export default ExportEmargementsModal;