import ServiceBase from "../services/ServiceBase";
import BaseUrlConstants from "../constants/BaseUrlConstants";
import TokenContainer from "../services/TokenContainer";

export interface IUser {
  id: number
  firstName: string
  lastName: string
  types: {
    title: string
  }[]
  missions?: {
    mission: {
      id: number
    }
  }[]
}

export interface IPaginatedResponse<T> {
  data: T;
  total: number;
}

class UserService extends ServiceBase {
  /**
   * Get a user by unique identifier.
   * @param {number} userId The identifier of the user.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getById(userId: number) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}users/${userId}`,
      method: "GET"
    });
  }

  /**
   * Get users of a mission.
   * @param {number} missionId The identifier of the mission.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getByMission(missionId: number) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}missions/${missionId}/users`,
      method: "GET"
    });
  }

  /**
   * Get all unarchived users.
   *
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getAll(params: {
    archived?: boolean,
    name?: string | null,
    types?: number[],
    __offset?: number,
    __limit?: number
  }): Promise<IPaginatedResponse<IUser[]>> {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}users?${this.objectToQueryString(params)}`,
      method: "GET"
    });
  }

  /**
   * Get all unarchived interns.
   *
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getInterns() {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}interns?archived=false`,
      method: "GET"
    });
  }

  /**
   * Get all archived users.
   *
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getAllArchived() {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}users?archived=true`,
      method: "GET"
    });
  }

  /**
   * Get all archived interns.
   *
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getArchivedInterns() {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}interns?archived=true`,
      method: "GET"
    });
  }

  /**
   * Post a new user.
   * @param {Object} user The user to create.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  post(user: any) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}users`,
      method: "POST",
      data: user
    });
  }

  /**
   * Archive or unarchive an user.
   * @param {number} userId The identifier of the user to archive or unarchive.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  archiving(userId: number) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}users/${userId}/archiving`,
      method: "POST"
    });
  }

  /**
   * Patch an existing resource. Only the properties that are set on the patch will be updated.
   * @param {number} reportId The identifier of the report.
   * @param {Object} patch The properties to update.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  patch(userId: number, patch: any) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}users/${userId}`,
      method: "PATCH",
      data: patch
    });
  }

  /**
   * Delete an existing user.
   * @param {number} userId The identifier of the user.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  remove(userId: number) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}users/${userId}`,
      method: "DELETE"
    });
  }

  /**
   * @param  {number} userId The ID of the user.
   * @param  {number} missionId The ID of the mission.
   * @return {[type]}            [description]
   */
  download(userId: number, missionId: number) {
    const token = TokenContainer.get().replace(/[+]/g, "%2B");
    const url = `${BaseUrlConstants.BASE_URL
      }users/${userId}/download/${missionId}`;
    window.location = `${url}?X-Auth-Token=${token}` as any;
  }

  /**
   * @param  {number} userId The ID of the user.
   * @param  {number} missionId The ID of the mission.
   * @return {[type]}            [description]
   */
  downloadSkills(userId: number, missionId: number) {
    const token = TokenContainer.get().replace(/[+]/g, "%2B");
    const url = `${BaseUrlConstants.BASE_URL
      }users/${userId}/download/${missionId}/skills`;
    window.location = `${url}?X-Auth-Token=${token}` as any;
  }
}

const service = new UserService();
export default service;