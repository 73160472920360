import React from "react";
import Locale from "locale/LocaleFactory";

import { Table, Button, Tooltip } from "antd";
import LoadingIcon from "components/LoadingIcon.jsx";
import TableColumnFilter from "components/TableColumnFilter.jsx";
import FilterIcon from "components/FilterIcon.jsx";
import SelectEmargementsPeriodModal from "components/SelectEmargementsPeriodModal.jsx";

import StringService from "services/utils/StringService";
import FilterService from "services/utils/FilterService";

import UserActions from "actions/UserActions";
import MissionActions from "actions/MissionActions";

import UserStore from "stores/UserStore";
import MissionStore from "stores/MissionStore";
import SelectUserDocumentsToPrintModal from "./User/SelectUserDocumentsToPrintModal";
import { CopyOutlined, PrinterOutlined } from "@ant-design/icons";

// Sorting Methods
function sortNameColumn(u1, u2) {
  const s1 = u1.firstName + u1.lastName;
  const s2 = u2.firstName + u2.lastName;
  return StringService.compareCaseInsensitive(s1, s2);
}

function sortMissionColumn(r1, r2) {
  function getMissionsName(i) {
    return i.mission
      ? i.mission
          .map((m) => MissionStore.getById(m.id))
          .filter((m) => !!m)
          .map((m) => m.name)
          .join(" / ")
      : "";
  }
  return StringService.compareCaseInsensitive(
    getMissionsName(r1),
    getMissionsName(r2)
  );
}

/**
 * The home page of the application.
 */
export default class Home extends React.Component {
  constructor() {
    super();
    const missions = MissionStore.getAll();
    const interns = UserStore.getInterns().filter((i) =>
      this.isInternAvailable(i, missions)
    );

    this.state = {
      interns,
      missions,
      filteredInterns: [],
      filters: {
        intern: [],
        mission: [],
      },
      filterGlobal: "",
      loading: !interns.length,
      archivedVisible: false,
      concernedIntern: null,
      internDocumentsModalVisible: false,
      selectEmargementsPeriodModalVisible: false,
      errorMessage: "",
    };
  }

  isInternAvailable = (i, missions) => {
    return (
      i.mission &&
      i.mission.filter((m) => missions.findIndex((m1) => m1.id === m.id) > -1)
        .length > 0
    );
  };

  componentDidMount() {
    this.internListener = UserStore.addListener(this.receiveInterns);
    this.missionListener = MissionStore.addListener(this.receiveMissions);
    this.archivedInternListener = UserStore.addListener(
      this.receiveArchivedInterns
    );
    MissionActions.reload();
    this.loadInterns();
    this.updateFilters();
  }

  componentWillUnmount() {
    this.internListener.remove();
    this.missionListener.remove();
    this.archivedInternListener.remove();
  }

  loadInterns = () => {
    this.setState({
      loading: true,
    });
    UserActions.reloadInterns().then(() => {
      this.setState({
        loading: false,
        archivedVisible: false,
        selectedRowKeys: [],
      });
    }).catch(e => {
      if(e.code === 403) {
        this.setState({errorMessage: Locale.trans("forbiddenAccess") }) 
      }
    })
    this.internListener = UserStore.addListener(this.receiveInterns);
  };

  loadArchivedInterns = () => {
    this.setState({
      loading: true,
    });
    UserActions.reloadArchivedInterns().then(() => {
      this.setState({
        loading: false,
        archivedVisible: true,
        selectedRowKeys: [],
      });
    });
    this.archivedInternListener = UserStore.addListener(
      this.receiveArchivedInterns
    );
  };

  receiveInterns = () => {
    const { missions } = this.state;
    this.setState(
      {
        interns: UserStore.getInterns().filter((i) =>
          this.isInternAvailable(i, missions)
        ),
      },
      this.updateFilters
    );
  };

  receiveArchivedInterns = () => {
    this.setState(
      {
        interns: UserStore.getArchivedInterns(),
      },
      this.updateFilters
    );
  };

  receiveMissions = () => {
    const missions = MissionStore.getAll(); // TODO: getAll() ou getMissions() ?
    this.setState(
      {
        missions,
        interns: UserStore.getInterns().filter((i) =>
          this.isInternAvailable(i, missions)
        ),
      },
      this.updateFilters
    );
  };

  // Filters
  updateFilters = () => {
    const { interns } = this.state;
    const filteredInterns = interns.filter(this.internMatchFilters);
    this.setState({ filteredInterns });
  };

  internMatchFilters = (i) => {
    const { filters } = this.state;
    const missions = i.mission
      .map((m) => MissionStore.getById(m.id))
      .filter((m) => !!m);
    return (
      FilterService.matchFilter(filters.intern, i.id) &&
      FilterService.matchFilter(
        filters.mission,
        missions,
        (v1, v2) => v1.id === v2
      )
    );
  };

  handleFilterChange = (name, values) => {
    let prevState = this.state.filters;
    prevState[name] = values;
    this.setState({ filters: prevState });
    this.updateFilters();
  };

  getInterns = () => this.state.interns || [];

  getMissions = () => (this.state.missions || []).sort(sortNameColumn);

  render() {
    const { concernedIntern, archivedVisible, internDocumentsModalVisible, selectEmargementsPeriodModalVisible, errorMessage } = this.state;
    return (
      <div className="intern-list">
        {errorMessage && <p style={{color: "red"}}>{errorMessage}</p>}
        {concernedIntern && (
          <>
            <SelectUserDocumentsToPrintModal
              user={concernedIntern}
              onCancel={() =>
                this.setState({
                  internDocumentsModalVisible: false,
                  concernedIntern: null,
                })
              }
              visible={internDocumentsModalVisible}
            />
            <SelectEmargementsPeriodModal
              id={concernedIntern.id}
              archived={archivedVisible}
              groupBy="none"
              onCancel={() =>
                this.setState({
                  selectEmargementsPeriodModalVisible: false,
                  concernedIntern: null,
                })
              }
              visible={selectEmargementsPeriodModalVisible}
            />
          </>
        )}
        {this.renderInternTable()}
      </div>
    );
  }

  renderInternTable() {
    const { filters, filteredInterns, loading, errorMessage } = this.state;

    const columns = [
      {
        title: Locale.trans("user.name"),
        key: "name",
        sorter: sortNameColumn,
        filterIcon: <FilterIcon active={filters.intern.length > 0} />,
        render: this.renderInternNameCell,
        filterDropdown: (
          <TableColumnFilter
            name="intern"
            selectedValues={filters.intern}
            values={this.getInterns().map((i) => ({
              text: `${i.firstName} ${i.lastName}`,
              value: i.id,
            }))}
            onChange={this.handleFilterChange}
          />
        ),
      },
      {
        title: Locale.trans("mission.name"),
        key: "mission",
        sorter: sortMissionColumn,
        defaultSortOrder: "ascend",
        filterIcon: <FilterIcon active={filters.mission.length > 0} />,
        render: this.renderMissionCell,
        filterDropdown: (
          <TableColumnFilter
            name="mission"
            selectedValues={filters.mission}
            values={this.getMissions().map((m) => ({
              text: m.name,
              value: m.id,
            }))}
            onChange={this.handleFilterChange}
          />
        ),
      },
      {
        title: null,
        key: "actions",
        width: "50px",
        render: this.rendActionsCell,
      },
    ];

    return (
      <Table
        dataSource={filteredInterns}
        rowKey="id"
        columns={columns}
        locale={Locale.Table}
        ref={(r) => {
          this.table = r;
        }}
        loading={(loading && errorMessage === "") && { indicator: <LoadingIcon /> }}
      />
    );
  }

  renderInternNameCell = (intern) => `${intern.firstName} ${intern.lastName}`;

  renderMissionCell = (intern) => (
    <React.Fragment>
      {intern.mission &&
        intern.mission
          .map((m) => MissionStore.getById(m.id))
          .filter((m) => !!m)
          .map((m) => <div>{m.name}</div>)}
    </React.Fragment>
  );

  rendActionsCell = (intern) => (
    <div className="actions-row">
      <Tooltip title={Locale.trans("documents")}>
        <Button
          type="primary"
          shape="circle"
          icon={<CopyOutlined />}
          onClick={() =>
            this.setState({
              internDocumentsModalVisible: true,
              concernedIntern: intern,
            })
          }
        />
      </Tooltip>
      <Tooltip title="Imprimer les émargements">
        <Button
          type="primary"
          shape="circle"
          icon={<PrinterOutlined />}
          onClick={() =>
            this.setState({
              selectEmargementsPeriodModalVisible: true,
              concernedIntern: intern,
            })
          }
        />
      </Tooltip>
    </div>
  );
}
