import dispatcher from '../dispatchers/AppDispatcher';
import ActionsBase from '../actions/ActionsBase';

import SkillConstants from '../constants/SkillConstants';
import SkillService from '../services/SkillService';

import CacheManager from '../services/CacheManager';

const CACHE_DURATION = 30 * 1000;

class SkillActions extends ActionsBase {
    create = (skill) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(newSkill) {
                dispatcher.dispatch({
                    type: SkillConstants.RECEIVE_SKILL,
                    payload: {
                        skill: newSkill,
                    },
                });
                resolve(newSkill);
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            SkillService.post(skill)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    edit = (skillId, skill) => {
        const $this = this;
        return new Promise((
            resolve,
            reject
        ) => {
            function handleSuccess(newSkill) {
                dispatcher.dispatch({
                    type: SkillConstants.RECEIVE_SKILL,
                    payload: {
                        skill: newSkill,
                    },
                });
                resolve(newSkill);
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            SkillService.patch(skillId, skill)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    archiving = (skillId) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(newSkill) {
                dispatcher.dispatch({
                    type: SkillConstants.RECEIVE_ARCHIVED_SKILL,
                    payload: {
                        skill: newSkill,
                    },
                });
                resolve();
            }
            function handleError(err) {
                $this.handleError(err, reject);
            }
            SkillService.archiving(skillId)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    delete = (id) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess() {
                dispatcher.dispatch({
                    type: SkillConstants.DELETE_SKILL,
                    payload: {
                        id,
                    },
                });
                resolve();
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            SkillService.remove(id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reload = () => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(skills) {
                dispatcher.dispatch({
                    type: SkillConstants.RECEIVE_SKILLS,
                    payload: {
                        skills,
                    },
                });
                resolve();
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            SkillService.getAll()
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadArchived = () => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(skills) {
                dispatcher.dispatch({
                    type: SkillConstants.RECEIVE_ARCHIVED_SKILLS,
                    payload: {
                        skills,
                    },
                });
                resolve();
            }
            function handleError(err) {
                $this.handleError(err, reject);
            }
            SkillService.getAllArchived()
                .then(handleSuccess)
                .catch(handleError);
        });
      };

    changeCurrentSkillId = (skillId) => new Promise((resolve, reject) => {
        dispatcher.dispatch({
            type: SkillConstants.CHANGE_CURRENT_SKILL,
            payload: {
                skillId,
            },
        });
        resolve();
    });

    reloadById = (id) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(skill) {
                dispatcher.dispatch({
                    type: SkillConstants.RECEIVE_SKILL,
                    payload: {
                        skill,
                    },
                });
                resolve();
            }
            function handleError(err) {
                $this.handleError(err, reject);
            }
            SkillService.getById(id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadByUser = (user) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            if (
                CacheManager.isCached('Skill:reloadByUser', user.id.toString(), CACHE_DURATION)
            ) {
                resolve();
                return;
            }

            function handleSuccess(skills) {
                dispatcher.dispatch({
                    type: SkillConstants.RECEIVE_SKILLS_OF_USER,
                    payload: {
                        user,
                        skills,
                    },
                });
                resolve();
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            SkillService.getByUser(user.id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };
}

const action = new SkillActions();
export default action;