import React, { useEffect } from "react";
import { Form, Button } from "antd";
import Locale from "locale/LocaleFactory";
import FormItem from "components/forms/FormItems";

import ArrayService from "services/utils/ArrayService";
import StringService from "services/utils/StringService";

function cmpSkillsByName(s1: any, s2: any) {
  return StringService.compareCaseInsensitive(s1.name, s2.name);
}
interface IProps {
  job?: any
  loading: boolean
  skills: any[]
  readOnly?: boolean
  onSubmit: (establishment: any) => void
}
const JobForm: React.FC<IProps> = ({ job, skills, loading, readOnly, onSubmit }) => {
  const [form] = Form.useForm();

  const getEntityFromValues = (values: any) => {
    const entity: { [key: string]: any } = {};
    const keys = Object.keys(values);
    const ignoredKeys = [];

    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      if (!k.endsWith("_ids") && k !== "user") {
        if (k.endsWith("_id")) {
          const tK = k.replace("_id", "");
          entity[tK] = values[k];
          ignoredKeys.push(tK);
        } else if (ignoredKeys.indexOf(k) === -1) {
          entity[k] = values[k];
        }
      } else if (k.startsWith("user_")) {
        if (!entity.user) {
          entity.user = [];
        }
        entity.user = entity.user.concat(values[k]);
      }
    }
    return entity;
  };

  const handleSubmit = (values: any) => {
    const establishment = getEntityFromValues(values);
    onSubmit(establishment);
  };

  const defaultValues = job;
  useEffect(() => {
    if (defaultValues) {
      form.setFieldsValue({
        ...defaultValues,
        skill_ids: defaultValues.skills?.map((s: any) => s.id.toString()) ?? []
      });
    } else {
      form.resetFields();
    }
  }, [form, defaultValues]);

  return (
    <Form initialValues={defaultValues} onFinish={handleSubmit} layout="vertical" form={form}>
      <FormItem.Input
        id="name"
        required
        label={Locale.trans("job.name")}
        form={form}
        hasFeedback
      />

      {job && <FormItem.Select
        id="skill_ids"
        multiple
        label={Locale.trans("mission.skills")}
        options={(job ? ArrayService.uniqueEntity(skills.concat(job.skills)) : skills).sort(cmpSkillsByName).map(s => ({
          value: s.id,
          label: s.name ?? `Inconnu (${s.id})`
        }))}
        form={form}
        readOnly={readOnly}
      />}

      <Button
        type="primary"
        htmlType="submit"
        className="login-form-button"
        loading={loading}
      >
        {Locale.trans("save")}
      </Button>
    </Form>
  );
}

export default JobForm;
