import dispatcher from '../dispatchers/AppDispatcher';
import ActionsBase from '../actions/ActionsBase';

import EmargementConstants from '../constants/EmargementConstants';
import EmargementService from '../services/EmargementService';

import CacheManager from '../services/CacheManager';

const CACHE_DURATION = 30 * 1000;

class EmargementActions extends ActionsBase {
    create = (emargement) => {
        const $this = this;
        return new Promise((resolve, reject,) => {
            function handleSuccess(newEmargement) {
                dispatcher.dispatch({
                    type: EmargementConstants.RECEIVE_EMARGEMENT,
                    payload: {
                        emargement: newEmargement,
                    },
                });
                resolve(newEmargement);
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            EmargementService.post(emargement)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    edit = (emargementId, emargement) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(newEmargement) {
                dispatcher.dispatch({
                    type: EmargementConstants.RECEIVE_EMARGEMENT,
                    payload: {
                        emargement: newEmargement,
                    },
                });
                resolve(newEmargement);
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            EmargementService.patch(emargementId, emargement)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    delete = (id) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess() {
                dispatcher.dispatch({
                    type: EmargementConstants.DELETE_EMARGEMENT,
                    payload: {
                        id,
                    },
                });
                resolve();
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            EmargementService.remove(id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reload = () => {
        const $this = this;
        return new Promise((resolve, reject) => {
            if (CacheManager.isCached('Emargement:reload', '', CACHE_DURATION)) {
                resolve();
                return;
            }

            function handleSuccess(emargements) {
                dispatcher.dispatch({
                    type: EmargementConstants.RECEIVE_EMARGEMENTS,
                    payload: {
                        emargements,
                    },
                });
                resolve();
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            EmargementService.getAll()
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadById = (id) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(emargement) {
                dispatcher.dispatch({
                    type: EmargementConstants.RECEIVE_EMARGEMENT,
                    payload: {
                        emargement,
                    },
                });
                resolve();
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            EmargementService.getById(id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadByMission = (mission) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            if (
                CacheManager.isCached(
                    'Emargement:reloadByMission',
                    mission.id.toString(),
                    CACHE_DURATION,
                )
            ) {
                resolve();
            }

            function handleSuccess(emargements) {
                dispatcher.dispatch({
                    type: EmargementConstants.RECEIVE_EMARGEMENTS_OF_MISSION,
                    payload: {
                        mission,
                        emargements,
                    },
                });
                resolve();
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            EmargementService.getByMission(mission.id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadByUser = (user) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            if (
                CacheManager.isCached(
                    'Emargement:reloadByUser',
                    user.id.toString(),
                    CACHE_DURATION,
                )
            ) {
                resolve();
            }

            function handleSuccess(emargements) {
                dispatcher.dispatch({
                    type: EmargementConstants.RECEIVE_EMARGEMENTS_OF_USER,
                    payload: {
                        user,
                        emargements,
                    },
                });
                resolve();
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            EmargementService.getByUser(user.id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };
}

const action = new EmargementActions();
export default action;
