import React, { useEffect } from 'react';
import { Form, Button } from 'antd';
import Locale from 'locale/LocaleFactory';
import FormItem from 'components/forms/FormItems';
import ArrayService from "services/utils/ArrayService";

interface IProps {
    subSkill?: any
    loading: boolean
    skills?: any[]
    onSubmit: (establishment: any) => void
}
const SubSkillForm: React.FC<IProps> = ({ subSkill, skills, loading, onSubmit }) => {
    const [form] = Form.useForm();

    const getEntityFromValues = (values: any) => {
        const entity: { [key: string]: any } = {};
        const keys = Object.keys(values);
        const ignoredKeys = [];

        for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            if (!k.endsWith('_ids') && k !== 'passwordConfirm') {
                if (k.endsWith('_id')) {
                    const tK = k.replace('_id', '');
                    entity[tK] = values[k];
                    ignoredKeys.push(tK);
                } else if (ignoredKeys.indexOf(k) === -1) {
                    entity[k] = values[k];
                }
            }
        }

        return entity;
    };

    const handleSubmit = (values: any) => {
        const establishment = getEntityFromValues(values);
        onSubmit(establishment);
    };

    const defaultValues = subSkill;
    useEffect(() => {
        if (defaultValues) {
            form.setFieldsValue({
                ...defaultValues,
                skill_id: defaultValues.skill?.id.toString()
            });
        } else {
            form.resetFields();
        }
    }, [form, defaultValues]);

    return (
        <Form initialValues={defaultValues} onFinish={handleSubmit} layout="vertical" form={form}>
            <FormItem.Input
                id="name"
                required
                label={Locale.trans('subSkill.name')}
                hasFeedback
            />
            {skills &&
                <FormItem.Select
                    id="skill_id"
                    showSearch
                    label={Locale.trans('subSkill.skill')}
                    options={(subSkill ? ArrayService.uniqueEntity(skills.concat(subSkill.skill)) : skills).map(c => ({ value: c.id, label: c.name }))}
                    form={form}
                    hasFeedback
                />}

            <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
            >
                {Locale.trans('save')}
            </Button>
        </Form>
    );
}

export default SubSkillForm;
