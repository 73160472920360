
import React from 'react';
import { Modal } from 'antd';

import HostCompanyForm from 'components/forms/HostCompanyForm';

import ToastActions from 'actions/ToastActions';

import HostCompanyActions from 'actions/HostCompanyActions';

import UserTypeActions from 'actions/UserTypeActions';
import UserTypeStore from 'stores/UserTypeStore';
import Locale, { Locale as LocaleComponent } from 'locale/LocaleFactory';

/**
 * The modal to create a new host company.
 */
export default class CreateHostCompanyModal extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            fields: {},
            userTypes: UserTypeStore.getAll(),
        };
    }

    componentDidMount() {
        this.userTypeListener = UserTypeStore.addListener(this.receiveUserTypes);
        UserTypeActions.reload();
    }

    componentWillUnmount() {
        this.userTypeListener.remove();
    }

    receiveUserTypes = () => {
        this.setState({
            userTypes: UserTypeStore.getAll(),
        });
    };

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    handleSubmit = (company) => {
        this.setState({
            loading: true,
        });
        HostCompanyActions.create(company)
            .then((newCompany) => {
                this.setState({
                    fields: {},
                    loading: false,
                });
                ToastActions.createToastSuccess(Locale.trans('hostCompany.add.success', { name: newCompany.name }));
                this.props.onCancel();
            })
            .catch(this.handleError);
    };

    handleError = (err) => {
        this.setState({
            loading: false,
        });
        try {
            const resp = JSON.parse(err.response);
            ToastActions.createToastError(resp.message);
        } catch (e) {
            ToastActions.createToastError(Locale.trans('hostCompany.add.error'));
        }
    };

    render() {
        const { visible, onCancel } = this.props;
        const { fields, loading, userTypes } = this.state;
        return (
            <Modal
                title={<LocaleComponent transKey="hostCompany.add" />}
                open={visible}
                onCancel={onCancel}
                footer={null}
            >
                <HostCompanyForm
                    userTypes={userTypes}
                    onChange={this.handleFormChange}
                    fields={fields}
                    onSubmit={this.handleSubmit}
                    loading={loading}
                />
            </Modal>
        );
    }
}
