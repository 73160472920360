import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, Select } from "antd";
import MissionService, { IMission } from "services/MissionService";
import dayjs from "dayjs";
import DateService from "services/utils/DateService";

const REVIEW_TYPE_OPTIONS = [
    { value: "initial", label: "Bilan initial", missionType: "Découverte" },
    { value: "intermediate", label: "Bilan intermédiaire", missionType: "Découverte" },
    { value: "final", label: "Bilan final", missionType: "Découverte" },
    { value: "recrutement-coaching", label: "Session de coaching", missionType: "Recrutement" },
    { value: "recrutement-preorientation", label: "Réunion de pré-orientation", missionType: "Recrutement" },
    { value: "recrutement-intermediate", label: "Bilan intermédiaire", missionType: "Recrutement" },
    { value: "recrutement-final", label: "Bilan final", missionType: "Recrutement" },
]

const ARTICLES_BY_TYPE: { [key: string]: any[] } = {
    initial: [
        {
            title: "Bilan du démarrage du dispositif",
        },
    ],
    intermediate: [
        {
            title: "I.	Comportement du stagiaire",
        },
        {
            title: "II. Compétences professionnelles",
        },
        {
            title: "III. Objectifs et axes de travail jusqu'au bilan final",
            hasGoals: true,
        },
    ],
    final: [
        {
            title: "Synthèse de l'année de formation",
        },
    ],

    "recrutement-coaching": [
        {
            title: "I.	Evaluation et remarques de la direction",
        },
        {
            title: "II.	Evaluation et remarques du stagiaire",
        },
        {
            title:
                "III.	Appreciation du formateur Handiwork et actions ou recommandations",
        },
    ],
    "recrutement-preorientation": [
        {
            title: "I. Synthèse de la rencontre de pré-orientation",
        },
    ],
    "recrutement-intermediate": [
        {
            title: "I.	Comportement du stagiaire",
        },
        {
            title: "II. Compétences professionnelles",
        },
        {
            title: "III. Objectifs et axes de travail jusqu'au bilan final",
            hasGoals: true,
        },
    ],
    "recrutement-final": [
        {
            title: "I. Synthèse du parcours Handiwork recrutement",
        },
    ],
};

interface IProps {
    missionId: number
    initialValues?: any
    onSubmit: (values: any) => void
}

const ReviewForm: React.FC<IProps> = ({ missionId, initialValues, onSubmit }) => {
    const [form] = Form.useForm();

    const [mission, setMission] = useState<IMission | null>(null);

    useEffect(() => {
        async function loadData() {
            const mission = await MissionService.getById(missionId);
            setMission(mission);
        }
        loadData();
    }, [missionId]);

    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [initialValues, form]);

    if (!mission) {
        return null;
    }

    const users = (mission.users as any[]).filter(u => u.type.id === 3).map(u => u.user);

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={onSubmit}
        >
            <Form.Item
                name="date"
                label="Date de session"
                rules={[{ required: true }]}
            >
                <DatePickerString />
            </Form.Item>
            <Form.Item
                name="user"
                label="Stagiaire"
                rules={[{ required: true }]}
            >
                <Select options={users.map(u => ({ value: u.id, label: `${u.firstName} ${u.lastName}` }))} />
            </Form.Item>
            <Form.Item
                name="type"
                label="Type de bilan"
                rules={[{ required: true }]}
            >
                <Select
                    options={REVIEW_TYPE_OPTIONS.filter(o => o.missionType === mission.type)}
                    onChange={(value) => {
                        form.setFieldValue('article', ARTICLES_BY_TYPE[value] ?? []);
                    }}
                />
            </Form.Item>
            <Form.List name="article">
                {(fields) => fields.map(field => <ArticleField key={field.name} rowId={field.name} />)}
            </Form.List>

            <Button type="primary" htmlType="submit" block>Enregistrer</Button>
        </Form>
    )
}

interface IArticleFieldProps {
    rowId: number
}
const ArticleField: React.FC<IArticleFieldProps> = ({ rowId }) => {
    const articleTitle = Form.useWatch(['article', rowId, 'title']);
    const articleHasGoals = Form.useWatch(['article', rowId, 'hasGoals']);
    return (
        <Form.Item
            name={[rowId, "content"]}
            label={articleTitle}
            rules={[{ required: true }]}
        >
            {
                articleHasGoals ?
                    <ArticleGoalsInput /> :
                    <Input.TextArea autoSize />
            }
        </Form.Item>
    )
}

interface IArticleGoalsInputProps {
    value?: string
    onChange?: (value: string) => void
}
const ArticleGoalsInput: React.FC<IArticleGoalsInputProps> = ({ value, onChange }) => {
    const splitValue = (value ?? "||").split('|');

    function handleChange(i: number, value: string) {
        splitValue[i] = value;
        if (onChange) {
            onChange(splitValue.join('|'));
        }
    }

    return <>
        <Input style={{ marginBottom: 4 }} value={splitValue[0]} onChange={(e) => handleChange(0, e.target.value)} />
        <Input style={{ marginBottom: 4 }} value={splitValue[1]} onChange={(e) => handleChange(1, e.target.value)} />
        <Input style={{ marginBottom: 4 }} value={splitValue[2]} onChange={(e) => handleChange(2, e.target.value)} />
    </>
}

interface IDatePickerStringProps {
    value?: string | null
    onChange?: (value: string | null) => void
}
const DatePickerString: React.FC<IDatePickerStringProps> = ({ value, onChange }) => {
    return <DatePicker
        style={{ width: "100%" }}
        value={value ? dayjs(value) : null}
        onChange={(value) => {
            if (onChange) {
                onChange(value ? DateService.formatApi(value) : null)
            }
        }}
    />;
}

export default ReviewForm;