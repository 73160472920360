
import ServiceBase from '../services/ServiceBase';
import BaseUrlConstants from '../constants/BaseUrlConstants';

const URL = `${BaseUrlConstants.BASE_URL}`;

class HostCompanyService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Get a host company by unique identifier.
     * @param {number} hostCompanyId The identifier of the host company.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getById(hostCompanyId) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}host-companies/${hostCompanyId}`,
            method: 'GET',
        });
    }

    /**
     * Get host company of a mission.
     * @param {number} missionId The identifier of the mission.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getByMission(missionId) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}missions/${missionId}/host-company`,
            method: "GET"
        });
    }

    /**
     * Get all unarchived host companies.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getAll() {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}host-companies?archived=false`,
            method: "GET"
        });
    }

    /**
     * Get all archived host companies.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getAllArchived() {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}host-companies?archived=true`,
            method: "GET"
        });
    }

    /**
     * Post a new host company.
     * @param {Object} hostCompany The company to create.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    post(hostCompany) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}host-companies`,
            method: 'POST',
            data: hostCompany,
        });
    }

    /**
     * Archive or unarchive a host company.
     * @param {number} hostCompanyId The identifier of the host company to archive or unarchive.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    archiving(hostCompanyId) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}host-companies/${hostCompanyId}/archiving`,
            method: "POST"
        });
    }

    /**
     * Patch an existing resource. Only the properties that are set on the patch will be updated.
     * @param {number} hostCompanyId The identifier of the report.
     * @param {Object} patch The properties to update.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    patch(hostCompanyId, patch) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}host-companies/${hostCompanyId}`,
            method: 'PATCH',
            data: patch,
        });
    }

    /**
     * Delete an existing host company.
     * @param {number} hostCompanyId The identifier of the user.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    remove(hostCompanyId) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}host-companies/${hostCompanyId}`,
            method: 'DELETE',
        });
    }
}

const service = new HostCompanyService();
export default service;