
import ServiceBase from '../services/ServiceBase';
import BaseUrlConstants from '../constants/BaseUrlConstants';

const URL = `${BaseUrlConstants.BASE_URL}`;

class PartnerService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Get a partner by unique identifier.
     * @param {number} partnerId The identifier of the partner.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getById(partnerId) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}partners/${partnerId}`,
            method: 'GET',
        });
    }

    /**
     * Get partner of a mission.
     * @param {number} missionId The identifier of the mission.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getByMission(missionId) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}missions/${missionId}/partner`,
            method: "GET"
        });
    }

    /**
     * Get all unarchived partners.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getAll() {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}partners?archived=false`,
            method: "GET"
        });
    }

    /**
     * Get all archived partners.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getAllArchived() {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}partners?archived=true`,
            method: "GET"
        });
    }

    /**
     * Post a new partner.
     * @param {Object} partner The partner to create.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    post(partner) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}partners`,
            method: 'POST',
            data: partner,
        });
    }

    /**
     * Archive or unarchive a partner.
     * @param {number} partnerId The identifier of the partner to archive or unarchive.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    archiving(partnerId) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}partners/${partnerId}/archiving`,
            method: "POST"
        });
    }

    /**
     * Patch an existing resource. Only the properties that are set on the patch will be updated.
     * @param {number} partnerId The identifier of the report.
     * @param {Object} patch The properties to update.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    patch(partnerId, patch) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}partners/${partnerId}`,
            method: 'PATCH',
            data: patch,
        });
    }

    /**
     * Delete an existing partner.
     * @param {number} partnerId The identifier of the user.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    remove(partnerId) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}partners/${partnerId}`,
            method: 'DELETE',
        });
    }
}

const service = new PartnerService();
export default service;