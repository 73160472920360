import React, { useEffect } from "react";
import { Form, Button } from "antd";
import Locale from "locale/LocaleFactory";
import FormItem from "components/forms/FormItems";

interface IProps {
  userType?: any
  loading: boolean
  skills: any[]
  readOnly?: boolean
  features: any[]
  onSubmit: (establishment: any) => void
}
const UserTypeForm: React.FC<IProps> = ({ userType, skills, loading, readOnly, features, onSubmit }) => {
  const [form] = Form.useForm();

  const getEntityFromValues = (values: any) => {
    const entity: { [key: string]: any } = {
      permissions: [],
    };
    const keys = Object.keys(values);
    const ignoredKeys: string[] = [];

    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      if (k.startsWith("permissions_")) {
        const tK = k.replace("permissions_", "");
        entity.permissions.push({
          moduleName: tK,
          accessibility: values[k],
        });
      } else if (ignoredKeys.indexOf(k) === -1) {
        entity[k] = values[k];
      }
    }
    return entity;
  };

  const handleSubmit = (values: any) => {
    const establishment = getEntityFromValues(values);
    onSubmit(establishment);
  };

  const defaultValues = userType;
  useEffect(() => {
    if (defaultValues) {
      let initialValues = { ...defaultValues };
      defaultValues.permissions?.forEach((p: any) => {
        initialValues[`permissions_${p.moduleName}`] = p.accessibility.toString();
      });
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [form, defaultValues]);

  return (
    <Form initialValues={defaultValues} onFinish={handleSubmit} layout="vertical" form={form}>
      <FormItem.Input
        id="title"
        required
        label={Locale.trans("userType.title")}
        form={form}
        hasFeedback
      />

      {features.map((feature) => (
        <FormItem.Select
          key={feature}
          id={`permissions_${feature}`}
          label={Locale.trans(`features.${feature}`)}
          form={form}
          options={[
            { label: "Aucun accès", value: "0" },
            { label: "Lecture", value: "1" },
            { label: "Lecture + Modification", value: "3" },
            { label: "Lecture + Création", value: "5" },
            { label: "Lecture + Modification + Création + Suppression", value: "15" },
          ]}
        />
      ))}

      <Button
        type="primary"
        htmlType="submit"
        className="login-form-button"
        loading={loading}
      >
        {Locale.trans("save")}
      </Button>
    </Form>
  );
}
export default UserTypeForm;
