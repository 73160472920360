import React, { useEffect } from "react";
import { Form, Button } from "antd";
import Locale from "locale/LocaleFactory";
import FormItem from "components/forms/FormItems";
import RangePickerFormItem from "components/form-items/RangePickerFormItem";
import DatePickerFormItem from "components/form-items/DatePickerFormItem";
import StringService from "services/utils/StringService";
import ArrayService from "services/utils/ArrayService";
import DateConstants from "constants/DateConstants.js";
import { FormInstance, useWatch } from "antd/lib/form/Form";
import dayjs from "dayjs";
import UserSelectFormItem from "components/form-items/UserSelectFormItem";

function cmpByName(s1: any, s2: any) {
  return StringService.compareCaseInsensitive(s1.name, s2.name);
}
interface IProps {
  mission?: any
  loading: boolean
  jobs: any[]
  userTypes?: any[]
  establishments: any[]
  hostCompanies: any[]
  partners: any[]
  skills: any[]
  readOnly?: boolean
  onSubmit: (establishment: any) => void
}
const MissionForm: React.FC<IProps> = ({ mission, jobs, userTypes, establishments, hostCompanies, partners, skills, loading, readOnly, onSubmit }) => {
  const [form] = Form.useForm();

  const getEntityFromValues = (values: any) => {
    const entity: { [key: string]: any } = {
      users: [],
      reviewAlertDates: []
    };
    const keys = Object.keys(values);
    const ignoredKeys = [];

    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      if (k.startsWith("reviewAlertDate_")) {
        const sK = k.split("_");
        const reviewType = sK[1];
        if (values[k]) {
          entity.reviewAlertDates.push({
            type: reviewType,
            date: values[k].format('YYYY-MM-DD')
          });
        }
      } else if (!k.endsWith("_ids") && k !== "user") {
        if (k.endsWith("_id")) {
          const tK = k.replace("_id", "");
          entity[tK] = values[k];
          ignoredKeys.push(tK);
        } else if (ignoredKeys.indexOf(k) === -1) {
          entity[k] = values[k];
        }
      } else if (k.startsWith("users_")) {
        const sK = k.split("_");
        const userTypeId = sK[1];
        entity.users = [
          ...entity.users,
          ...values[k].map((u: any) => ({
            type: userTypeId,
            user: u,
          })),
        ];
      }
    }
    return entity;
  };

  const handleSubmit = (values: any) => {
    const mission = getEntityFromValues(values);
    mission.skill = values.skill_ids;
    mission.establishment = values.establishment_ids;
    mission.hostCompany = values.hostCompany_ids;
    mission.partners = values.partner_ids;

    if (!mission.job) {
      mission.job = null;
    }
    /* TODO: Find a more generic way to format the date */
    if (values.period) {
      mission.periodStart = {
        day: values.period[0].date(),
        month: values.period[0].month() + 1,
        year: values.period[0].year(),
      };
      mission.periodEnd = {
        day: values.period[1].date(),
        month: values.period[1].month() + 1,
        year: values.period[1].year(),
      };
    }
    delete mission.period;

    onSubmit(mission);
  };

  const defaultValues = mission;
  useEffect(() => {
    if (defaultValues) {
      let defaultFieldsValue = {
        ...defaultValues,
        job: defaultValues.job?.id,
        establishment_ids: defaultValues.establishment?.map((e: any) => e.id.toString()) ?? [],
        hostCompany_ids: defaultValues.hostCompany?.map((e: any) => e.id.toString()) ?? [],
        partner_ids: defaultValues.partners?.map((e: any) => e.id.toString()) ?? [],
        skill_ids: defaultValues.skill?.map((e: any) => e.id.toString()) ?? [],
        period: [dayjs(defaultValues.periodStart), dayjs(defaultValues.periodEnd)]
      };

      defaultValues.reviewAlertDates?.forEach((a: any) => {
        defaultFieldsValue[`reviewAlertDate_${a.type}`] = dayjs(a.date);
      });
      delete defaultFieldsValue['reviewAlertDates'];

      form.setFieldsValue(defaultFieldsValue);
    } else {
      form.resetFields();
    }
  }, [form, defaultValues]);

  const missionTypes = ["Découverte", "Recrutement"];
  const typeOptions = missionTypes.map((u) => ({
    value: u,
    label: u,
  }));

  const emargementTypeOptions = ['day', 'half-day'].map((u) => ({
    value: u,
    label: Locale.trans(`mission.emargementTypes.${u}`),
  }));

  const jobOptions = jobs.map((job) => ({
    value: job.id,
    label: job.name,
  }));

  const type = useWatch("type", form);
  const reviewTypes = type === "Recrutement" ?
    ["recrutement-coaching", "recrutement-preorientation", "recrutement-intermediate", "recrutement-final"] :
    ["initial", "intermediate", "final"];

  return (
    <Form initialValues={defaultValues} onFinish={handleSubmit} layout="vertical" form={form}>
      <div>
        <FormItem.Input
          id="name"
          required={!readOnly}
          label={Locale.trans("mission.name")}
          form={form}
          hasFeedback
          readOnly={readOnly}
        />
        <FormItem.Select
          id="type"
          required
          allowClear
          label={Locale.trans("mission.type")}
          options={typeOptions}
          form={form}
          readOnly={readOnly}
          hasFeedback
        />

        <FormItem.Input
          id="totalSessions"
          label={Locale.trans("session.number")}
          form={form}
        />

        <RangePickerFormItem
          id="period"
          required={!readOnly}
          label={Locale.trans("mission.period")}
          form={form}
          readOnly={readOnly}
          format={DateConstants.DISPLAY_DATE_FORMAT}
        />

        <FormItem.Select
          id="emargementType"
          required
          allowClear
          label={Locale.trans("mission.emargementType")}
          options={emargementTypeOptions}
          form={form}
          readOnly={readOnly}
          hasFeedback
        />

        <FormItem.Input
          id="emargementHours"
          required={!readOnly}
          //defaultValue={form.getFieldValue("type") === "Découverte" ? 3 : 7}
          label={Locale.trans("mission.hours")}
          form={form}
        />

        {form.getFieldValue("type") === "Découverte" && (
          <FormItem.Input
            id="schedules"
            label={Locale.trans("user.schedules")}
            form={form}
            readOnly={readOnly}
          />
        )}

        <FormItem.Select
          id="job"
          allowClear
          label={Locale.trans("mission.job")}
          options={jobOptions}
          form={form}
          readOnly={readOnly}
          hasFeedback
          onChange={(jobId: string) => {
            if (!mission) {
              const job = jobs.find((j) => j.id === parseInt(jobId));
              const skillIds = job
                ? job.skills.map((s: any) => s.id.toString())
                : [];
              form.setFieldsValue({
                skill_ids: skillIds,
              });
            }
          }}
        />

        {userTypes?.filter(u => u.isVisibleOnMission)
          .map((u) => <UserField key={u.id} userType={u} mission={mission} readOnly={readOnly} form={form} />)}

        <FormItem.Select
          id="establishment_ids"
          multiple
          initialValue={[]}
          label={Locale.trans("mission.establishments")}
          options={(mission ? ArrayService.uniqueEntity(establishments.concat(mission.establishment)) : establishments)
            .sort(cmpByName).map((s) => ({
              value: s.id.toString(),
              label: s.name,
            }))}
          form={form}
          readOnly={readOnly}
        />

        <FormItem.Select
          id="hostCompany_ids"
          multiple
          initialValue={[]}
          label={Locale.trans("mission.hostCompanies")}
          options={(mission ? ArrayService.uniqueEntity(hostCompanies.concat(mission.hostCompany)) : hostCompanies)
            .sort(cmpByName).map((s) => ({
              value: s.id.toString(),
              label: s.name,
            }))}
          form={form}
          readOnly={readOnly}
        />
        
        <FormItem.Select
          id="partner_ids"
          multiple
          initialValue={[]}
          label={Locale.trans("mission.partner")}
          options={(mission ? ArrayService.uniqueEntity(partners.concat(mission.partners)) : partners).sort(cmpByName).map((s: any) => ({
            value: s.id.toString(),
            label: s.name,
          }))}
          form={form}
          readOnly={readOnly}
        />

        <FormItem.Select
          id="skill_ids"
          multiple
          initialValue={[]}
          label={Locale.trans("mission.skills")}
          options={(mission ? ArrayService.uniqueEntity(skills.concat(mission.skill)) : skills)
            .sort(cmpByName).map((s) => ({
              value: s.id.toString(),
              label: s.name,
            }))}
          form={form}
          readOnly={readOnly}
        />

        <h3>{Locale.trans('mission.reviewAlertDate')}</h3>
        {reviewTypes.map(reviewType =>
          <DatePickerFormItem
            key={reviewType}
            id={`reviewAlertDate_${reviewType}`}
            label={Locale.trans(`review.${reviewType}`)}
            form={form}
            readOnly={readOnly}
          />
        )}
      </div>
      {
        !readOnly && (
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={loading}
          >
            {Locale.trans("save")}
          </Button>
        )
      }
    </Form >
  );
}

interface IUserFieldProps {
  userType: any
  mission: any
  form: FormInstance
  readOnly?: boolean
}
const UserField: React.FC<IUserFieldProps> = ({ userType, mission, form, readOnly }) => {
  if (
    mission &&
    mission.type === "Découverte" &&
    userType &&
    userType.title === "Interlocuteur Partenaire"
  ) {
    return null;
  }

  const { getFieldValue } = form;

  let initialValue = [];
  if (mission) {
    initialValue = (getFieldValue("users") || [])
      .filter((u: any) => u.type.id === userType.id)
      .map((u: any) => u.user.id.toString());
  }

  return (
    <UserSelectFormItem
      key={userType.id}
      id={`users_${userType.id}_ids`}
      multiple
      label={userType.title}
      initialValue={initialValue}
      readOnly={readOnly}
      extraFilters={{
        archived: false,
        types: [userType.id]
      }}
      initialUsers={mission?.users.filter((u: any) => u.type.id === userType.id).map((u: any) => u.user)}
    />
  );
};


export default MissionForm;
