import React from 'react';
import { Modal } from 'antd';

import SkillForm from 'components/forms/SkillForm';

import ToastActions from 'actions/ToastActions';

import SkillActions from 'actions/SkillActions';
import Locale, { Locale as LocaleComponent } from 'locale/LocaleFactory';

/**
 * The modal to edit a skill.
 */
export default class EditSkillModal extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loading: false,
            fields: this.getFieldsFromEntity(props.skill),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.visible && !this.props.visible) {
            this.setState({
                fields: this.getFieldsFromEntity(nextProps.skill),
            });
        }
    }

    getFieldsFromEntity = (entity) => {
        if (!entity) {
            return {};
        }
        const fields = {};
        const keys = Object.keys(entity);
        for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            fields[k] = { value: entity[k] };
        }
        return fields;
    };

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    handleSubmit = (skill) => {
        if (!this.props.skill) {
            return;
        }
        const skillId = this.props.skill.id;

        this.setState({
            loading: true,
        });
        SkillActions.edit(skillId, skill)
            .then((newSkill) => {
                this.setState({
                    fields: {},
                    loading: false,
                });
                ToastActions.createToastSuccess(Locale.trans('skill.update.success', {name: newSkill.name}));
                this.props.onCancel();
            })
            .catch(this.handleError);
    };

    handleError = (err) => {
        this.setState({
            loading: false,
        });
        try {
            const resp = JSON.parse(err.response);
            ToastActions.createToastError(resp.message);
        } catch (e) {
            ToastActions.createToastError('Une erreur est survenue');
        }
    };

    render() {
        const { skill, visible, onCancel } = this.props;
        const { fields, loading } = this.state;
        return (
            skill && visible && <Modal
                title={<LocaleComponent transKey="skill.update" />}
                open
                onCancel={onCancel}
                footer={null}
            >
                <SkillForm
                    skill={skill}
                    onChange={this.handleFormChange}
                    fields={fields}
                    onSubmit={this.handleSubmit}
                    loading={loading}
                />
            </Modal>
        );
    }
}
