import ServiceBase from "../services/ServiceBase";
import BaseUrlConstants from "../constants/BaseUrlConstants";
import TokenContainer from "services/TokenContainer";

const URL = `${BaseUrlConstants.BASE_URL}`;

class ReviewService extends ServiceBase {
  constructor() {
    super(URL);
  }

  /**
   * Get a review by unique identifier.
   * @param {number} reviewId The identifier of the review.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getById(reviewId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}reviews/${reviewId}`,
      method: "GET"
    });
  }

  /**
   * Get reviews of a user.
   * @param {number} userId The identifier of the user.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getByUser(userId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}users/${userId}/reviews`,
      method: "GET"
    });
  }

  /**
   * Get reviews of a user.
   * @param {number} missionId The identifier of the mission.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getByMission(missionId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}missions/${missionId}/reviews`,
      method: "GET"
    });
  }

  /**
     * Get all unarchived reviews.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getAll() {
      return this.execute({
          url: `${BaseUrlConstants.BASE_URL}reviews?archived=false`,
          method: "GET"
      });
  }

  /**
   * Get all archived reviews.
   *
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getAllArchived() {
      return this.execute({
          url: `${BaseUrlConstants.BASE_URL}reviews?archived=true`,
          method: "GET"
      });
  }

  /**
   * Post a new review to an user.
   * @param {Object} review The review to create.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  post(review) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}reviews`,
      method: "POST",
      data: review
    });
  }

  /**
   * Patch an existing resource. Only the properties that are set on the patch will be updated.
   * @param {number} reviewId The identifier of the review.
   * @param {Object} patch The properties to update.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  patch(reviewId, patch) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}reviews/${reviewId}`,
      method: "PATCH",
      data: patch
    });
  }

  /**
   * Delete an existing review.
   * @param {number} reviewId The identifier of the review.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  remove(reviewId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}reviews/${reviewId}`,
      method: "DELETE"
    });
  }

  /**
   * @param  {number} reviewId The ID of the review.
   * @return {[type]}            [description]
   */
  download(reviewId) {
    const token = TokenContainer.get().replace(/[+]/g, "%2B");
    const url = `${BaseUrlConstants.BASE_URL}reviews/${reviewId}/download`;
    window.location = `${url}?X-Auth-Token=${token}`;
  }
}

const service = new ReviewService();
export default service;