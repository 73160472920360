const values = {
    EMARGEMENT: 'emargement',
    PRINT_EMARGEMENT: 'print-emargement',
    SKILL: 'skill',
    ESTABLISHMENT: 'establishment',
    HOST_COMPANY: 'host-company',
    PARTNER: 'partner',
    MISSION: 'mission',
    ALL_MISSIONS: 'all-missions',
    REPORT: 'report',
    REVIEW: 'review',
    USER: 'user',
    JOB: 'job',
    CONFIGURATION: 'configuration',
};
export default values;