const values = {
    RECEIVE_SUB_SKILL: 'RECEIVE_SUB_SKILL',
    RECEIVE_SUB_SKILLS: 'RECEIVE_SUB_SKILLS',
    RECEIVE_ARCHIVED_SUB_SKILL: 'RECEIVE_ARCHIVED_SUB_SKILL',
    RECEIVE_ALL_SUB_SKILLS: 'RECEIVE_ALL_SUB_SKILLS',
    RECEIVE_SUB_SKILLS_OF_SKILL: 'RECEIVE_SUB_SKILLS_OF_SKILL',
    RECEIVE_SUB_SKILLS_OF_USER_HAS_SKILL: 'RECEIVE_SUB_SKILLS_OF_USER_HAS_SKILL',
    CHANGE_CURRENT_SUB_SKILL: 'CHANGE_CURRENT_SUB_SKILL',
    RECEIVE_ARCHIVED_SUB_SKILLS: 'RECEIVE_ARCHIVED_SUB_SKILLS',
}

export default values;