import React, { useCallback, useMemo, useState } from 'react';
import SelectFormItem from './SelectFormItem';
import UserService, { IUser } from 'services/UserService';
import { Spin } from 'antd';
import ArrayService from 'services/utils/ArrayService';
import StringService from 'services/utils/StringService';

function cmpUsersByName(u1: IUser, u2: IUser) {
    const uN1 = u1.firstName + u1.lastName;
    const uN2 = u2.firstName + u2.lastName;
    return StringService.compareCaseInsensitive(uN1, uN2);
}

interface IProps {
    id: string
    label: string
    multiple?: boolean
    initialValue?: number | number[]
    readOnly?: boolean

    extraFilters?: {
        archived?: boolean
        types?: number[]
    }

    initialUsers?: IUser[]
    placeholder?: string
}
const UserSelectFormItem: React.FC<IProps> = ({ id, label, multiple, initialValue, readOnly, extraFilters, initialUsers, placeholder }) => {

    const [users, setUsers] = useState<IUser[]>([]);
    const [loading, setLoading] = useState(false);

    const strExtraFilters = JSON.stringify(extraFilters ?? {});

    const onSearch = useCallback(async (nameFilter: string) => {
        if (nameFilter && nameFilter.length >= 2) {
            setLoading(true);
            const res = await UserService.getAll({
                ...JSON.parse(strExtraFilters),
                name: nameFilter
            });
            setUsers(res.data);
            setLoading(false);
        } else {
            setUsers([]);
        }
    }, [strExtraFilters]);

    const options = useMemo(() => {
        return ArrayService.uniqueEntity([...(initialUsers ?? []), ...users])
            .sort(cmpUsersByName)
            .map(u => ({
                value: u.id,
                label: `${u.lastName} ${u.firstName}`
            }))
    }, [initialUsers, users]);

    return <SelectFormItem
        id={id}
        multiple={multiple}
        label={label}
        initialValue={initialValue}
        onSearch={onSearch}
        notFoundContent={loading ? <Spin size="small" /> : null}
        filterOption={false}
        options={options}
        readOnly={readOnly}
        placeholder={placeholder}
    />;
}

export default UserSelectFormItem;