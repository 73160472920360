import dispatcher from "../dispatchers/AppDispatcher";
import ActionsBase from "../actions/ActionsBase";

import MissionConstants from "../constants/MissionConstants";
import MissionService from "../services/MissionService";

import CacheManager from "../services/CacheManager";

const CACHE_DURATION = 30 * 1000;

class MissionActions extends ActionsBase {
  create = mission => {
    const $this = this;
    return new Promise((resolve, reject) => {
      function handleSuccess(newMission) {
        dispatcher.dispatch({
          type: MissionConstants.RECEIVE_MISSION,
          payload: {
            mission: newMission
          }
        });
        resolve(newMission);
      }

      function handleError(err) {
        $this.handleError(err, reject);
      }

      MissionService.post(mission)
        .then(handleSuccess)
        .catch(handleError);
    });
  };

  edit = (missionId, mission) => {
    const $this = this;
    return new Promise((resolve, reject) => {
      function handleSuccess(newMission) {
        dispatcher.dispatch({
          type: MissionConstants.RECEIVE_MISSION,
          payload: {
            mission: newMission
          }
        });
        resolve(newMission);
      }

      function handleError(err) {
        $this.handleError(err, reject);
      }

      MissionService.patch(missionId, mission)
        .then(handleSuccess)
        .catch(handleError);
    });
  };

  archiving = (missionId) => {
    const $this = this;
    return new Promise((resolve, reject) => {
        function handleSuccess(newMission) {
            dispatcher.dispatch({
                type: MissionConstants.RECEIVE_ARCHIVED_MISSION,
                payload: {
                    mission: newMission,
                },
            });
            resolve();
        }
        function handleError(err) {
            $this.handleError(err, reject);
        }
        MissionService.archiving(missionId)
            .then(handleSuccess)
            .catch(handleError);
    });
  };

  delete = id => {
    const $this = this;
    return new Promise((resolve, reject) => {
      function handleSuccess() {
        dispatcher.dispatch({
          type: MissionConstants.DELETE_MISSION,
          payload: {
            id
          }
        });
        resolve();
      }

      function handleError(err) {
        $this.handleError(err, reject);
      }

      MissionService.remove(id)
        .then(handleSuccess)
        .catch(handleError);
    });
  };

  reload = () => {
    const $this = this;
    return new Promise((resolve, reject) => {
      function handleSuccess(missions) {
        dispatcher.dispatch({
          type: MissionConstants.RECEIVE_MISSIONS,
          payload: {
            missions
          }
        });
        resolve();
      }

      function handleError(err) {
        $this.handleError(err, reject);
      }

      MissionService.getAll({archived: false})
        .then(handleSuccess)
        .catch(handleError);
    });
  };

  reloadArchived = () => {
    const $this = this;
    return new Promise((resolve, reject) => {
        function handleSuccess(missions) {
            dispatcher.dispatch({
                type: MissionConstants.RECEIVE_ARCHIVED_MISSIONS,
                payload: {
                    missions,
                },
            });
            resolve();
        }
        function handleError(err) {
            $this.handleError(err, reject);
        }
        MissionService.getAll({archived: true})
            .then(handleSuccess)
            .catch(handleError);
    });
  };

  changeCurrentMissionId = missionId => {
    return new Promise((resolve, reject) => {
      dispatcher.dispatch({
        type: MissionConstants.CHANGE_CURRENT_MISSION,
        payload: {
          missionId
        }
      });
      resolve();
    });
  };

  reloadById = id => {
    const $this = this;
    return new Promise((resolve, reject) => {
      function handleSuccess(mission) {
        dispatcher.dispatch({
          type: MissionConstants.RECEIVE_MISSION,
          payload: {
            mission
          }
        });
        resolve();
      }

      function handleError(err) {
        $this.handleError(err, reject);
      }

      MissionService.getById(id)
        .then(handleSuccess)
        .catch(handleError);
    });
  };

  reloadByUser = user => {
    const $this = this;
    return new Promise((resolve, reject) => {
      if (
        CacheManager.isCached(
          "Mission:reloadByUser",
          user.id.toString(),
          CACHE_DURATION
        )
      ) {
        resolve();
        return;
      }

      function handleSuccess(missions) {
        dispatcher.dispatch({
          type: MissionConstants.RECEIVE_MISSIONS_OF_USER,
          payload: {
            user,
            missions
          }
        });
        resolve();
      }

      function handleError(err) {
        $this.handleError(err, reject);
      }

      MissionService.getByUser(user.id)
        .then(handleSuccess)
        .catch(handleError);
    });
  };
}

const action = new MissionActions();
export default action;
