import EntityStoreBase from '../stores/EntityStoreBase';
import EmargementConstants from '../constants/EmargementConstants';

class EmargementStore extends EntityStoreBase {
    getInitialState() {
        return {
            entities: [],
        };
    }

    reduce(state, action) {
        const { type, payload } = action;
        let newState = Object.assign({}, state);

        switch (type) {
        case EmargementConstants.RECEIVE_EMARGEMENTS_OF_MISSION:
            newState = this._receiveByMission(state, payload.mission, payload.emargements);
            break;
        case EmargementConstants.RECEIVE_EMARGEMENTS_OF_USER:
            newState = this._receiveByUser(state, payload.user, payload.emargements);
            break;
        case EmargementConstants.RECEIVE_EMARGEMENT:
            newState = this._receiveEntity(state, payload.emargement);
            break;
        case EmargementConstants.RECEIVE_EMARGEMENTS:
            newState = this._receiveMany(state, payload.emargements);
            break;
        case EmargementConstants.DELETE_EMARGEMENT:
            newState = this._deleteOne(state, payload.id);
            break;
        case 'clearAll':
            newState = this.getInitialState();
            break;
        default:
            newState = state;
        }
        return newState;
    }

    _receiveByMission = (state, mission, emargements) => {
        const newState = Object.assign({}, state);
        newState.entities = state.entities.filter(e => e.mission.id !== mission.id);
        newState.entities.push(...emargements);
        return newState;
    };

    _receiveByUser = (state, user, emargements) => {
        const newState = Object.assign({}, state);
        newState.entities = state.entities.filter(e => e.user.id !== user.id);
        newState.entities.push(...emargements);
        return newState;
    };

    _receiveMany = (state, emargements) => {
        const newState = Object.assign({}, state);
        // We remove all the entities that have the same ID as the new emargements
        newState.entities = state.entities.filter(e => emargements.findIndex(a => e.id === a.id) === -1);
        newState.entities.push(...emargements);
        return newState;
    };

    _deleteOne = (state, emargementId) => {
        const i = state.entities.findIndex(c => c.id === emargementId);
        if (i === -1) {
            return state;
        }
        const newState = Object.assign({}, state);
        newState.entities = state.entities.slice();
        newState.entities.splice(i, 1);
        return newState;
    };

    // /// Public Methods  /////

    getEmargement() {
        return this.getState().emargement;
    }

    /**
     * Get a emargement by ID.
     * @param {number} id The identifier.
     * @return {?Emargement} The emargement, or NULL if no entity is found.
     */
    getById = (id) => this.getState().entities.find(e => e.id === id);

    /**
     * Get all the emargements.
     */
    getAll = () => this.getState().entities;

    /**
     * Get all the emargements of a mission.
     */
    getByMission = (mission) => {
        return this.getState().entities.filter(e => e.mission.id === mission.id);
    }

    /**
     * Get all the emargements of an user.
     */
    getByUser = (user) => this.getState().entities.filter(e => e.user.id === user.id);
}

const store = new EmargementStore();
export default store;