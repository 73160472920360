import dispatcher from '../dispatchers/AppDispatcher';
import ActionsBase from '../actions/ActionsBase';

import UserConstants from '../constants/UserConstants';
import UserService from '../services/UserService';

import CacheManager from '../services/CacheManager';

const CACHE_DURATION = 30 * 1000;

class UserActions extends ActionsBase {
    create = (user) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(newUser) {
                dispatcher.dispatch({
                    type: UserConstants.RECEIVE_USER,
                    payload: {
                        user: newUser,
                    },
                });
                resolve(newUser);
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            UserService.post(user)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    edit = (userId, user) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(newUser) {
                dispatcher.dispatch({
                    type: UserConstants.RECEIVE_USER,
                    payload: {
                        user: newUser,
                    },
                });
                resolve(newUser);
            }
            function handleError(err) {
                $this.handleError(err, reject);
            }
            UserService.patch(userId, user)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    archiving = (userId) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(newUser) {
                dispatcher.dispatch({
                    type: UserConstants.RECEIVE_ARCHIVED_USER,
                    payload: {
                        user: newUser,
                    },
                });
                resolve();
            }
            function handleError(err) {
                $this.handleError(err, reject);
            }
            UserService.archiving(userId)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    delete = (id) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess() {
                dispatcher.dispatch({
                    type: UserConstants.DELETE_USER,
                    payload: {
                        id,
                    },
                });
                resolve();
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            UserService.remove(id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    changeCurrentUserId = (userId) => {
        return new Promise((resolve, reject) => {
            dispatcher.dispatch({
                type: UserConstants.CHANGE_CURRENT_USER,
                payload: {
                    userId,
                },
            });
            resolve();
        });
    };

    reloadInterns = () => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(users) {
                dispatcher.dispatch({
                    type: UserConstants.RECEIVE_INTERNS,
                    payload: {
                        users,
                    },
                });
                resolve();
            }
            function handleError(err) {
                $this.handleError(err, reject);
            }
            UserService.getInterns()
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadArchived = () => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(users) {

                dispatcher.dispatch({
                    type: UserConstants.RECEIVE_ARCHIVED_USERS,
                    payload: {
                        users,
                    },
                });
                resolve();
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            UserService.getAllArchived()
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadArchivedInterns = () => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(users) {

                dispatcher.dispatch({
                    type: UserConstants.RECEIVE_ARCHIVED_INTERNS,
                    payload: {
                        users,
                    },
                });
                resolve();
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            UserService.getArchivedInterns()
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadByMission = (mission) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            if (
                CacheManager.isCached(
                    'User:reloadByMission',
                    mission.id.toString(),
                    CACHE_DURATION,
                )
            ) {
                resolve();
                return;
            }

            function handleSuccess(users) {
                dispatcher.dispatch({
                    type: UserConstants.RECEIVE_USERS_OF_MISSION,
                    payload: {
                        mission,
                        users,
                    },
                });
                resolve();
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            UserService.getByMission(mission.id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadById = (id) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(user) {
                dispatcher.dispatch({
                    type: UserConstants.RECEIVE_USER,
                    payload: {
                        user,
                    },
                });
                resolve();
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            UserService.getById(id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };
}

const action = new UserActions();
export default action;
