import ArchivedEntityStoreBase from "../stores/ArchivedEntityStoreBase";
import UserConstants from "../constants/UserConstants";

class UserStore extends ArchivedEntityStoreBase {
    getInitialState() {
        return {
            missionUsers: {},
            currentUserId: null,
            entities: [],
            archived: [],
        };
    }

    reduce(state, action) {
        const { type, payload } = action;
        let newState = Object.assign({}, state);

        switch (type) {
            case UserConstants.RECEIVE_USERS_OF_MISSION:
                newState = this._receiveByMission(
                    state,
                    payload.mission,
                    payload.users
                );
                break;
            case UserConstants.RECEIVE_USER:
                newState = this._receiveEntity(state, payload.user);
                break;
            case UserConstants.RECEIVE_ARCHIVED_USER:
                newState = this._receiveArchivedEntity(state, payload.user);
                break;
            case UserConstants.RECEIVE_USERS:
                newState = this._receiveMany(state, payload.users);
                break;
            case UserConstants.RECEIVE_INTERNS:
                newState = this._receiveMany(state, payload.users);
                break;
            /*case UserConstants.RECEIVE_ALL_USERS:
                newState = this._receiveAll(state, payload.users);
                break;*/
            case UserConstants.RECEIVE_ARCHIVED_USERS:
                newState = this._receiveArchived(state, payload.users);
                break;
            case UserConstants.RECEIVE_ARCHIVED_INTERNS:
                newState = this._receiveArchived(state, payload.users);
                break;
            case UserConstants.CHANGE_CURRENT_USER:
                newState.currentUserId = payload.userId;
                break;
            case "clearAll":
                newState = this.getInitialState();
                break;
            default:
                newState = state;
        }
        return newState;
    }

    remove(arr) {
        var what, a = arguments, L = a.length, ax;
        while (L > 1 && arr.length) {
            what = a[--L];
            while ((ax= arr.indexOf(what)) !== -1) {
                arr.splice(ax, 1);
            }
        }
    }

    _receiveByMission = (state, users) => {
        let newState = Object.assign({}, state);
        newState.entities = state.entities.filter(
            e => users.findIndex(a => e.id === a.id) === -1
        );
        newState.entities.push(...users);
        return newState;
    };

    _receiveMany = (state, users) => {
        let newState = Object.assign({}, state);
        // We remove all the entities that have the same ID as the new users
        newState.entities = state.entities.filter(
            e => users.findIndex(a => e.id === a.id) === -1
        );
        newState.entities.push(...users);
        
        return newState;
    };

    _receiveArchived = (state, users) => {
        let newState = Object.assign({}, state);
        // We remove all the entities that have the same ID as the new users
        newState.archived = state.archived.filter(
            e => users.findIndex(a => e.id === a.id) === -1
        );
        newState.archived.push(...users);
        return newState;
    };

    // /// Public Methods  /////

    getUser() {
        return this.getState().user;
    }

    /**
     * Get all the users of a mission.
     */
    getByMission = (mission) => {
        return this.getState().entities.filter(e => !!e.mission.find(a => a.id === mission.id));
    };

    /**
     * Get a user by ID.
     * @param {number | string} id The identifier of the user.
     * @return {?User}    The user, or NULL if no entity is found.
     */
    getById = (id) => {
        return this.getState().entities.find(e => e.id === id);
    };

    /**
     * Get an archived user by ID.
     * @param {number | string} id The identifier of the archived user.
     * @return {?User}    The archived user, or NULL if no entity is found.
     */
    getArchivedById = (id) => {
        return this.getState().archived.find(e => e.id === id);
    };

    /**
     * Get all users.
     */
    getAll = () => this.getState().entities.concat(this.getState().archived);

    /**
     * Get unarchived users.
     */
    getUsers = () => this.getState().entities;

    /**
     * Get unarchived interns.
     */
    getInterns = () => {
        let interns = [];
        this.getState().entities.forEach(e => {
            e.types.forEach(t => {
                if(t.title === "Stagiaire") {
                    interns.push(e);
                }
            })
        });
        return interns;
    }

    getArchivedUsers = () => this.getState().archived;

    getArchivedInterns = () => {
        let interns = [];
        this.getState().archived.forEach(e => {
            e.types.forEach(t => {
                if(t.title === "Stagiaire") {
                    interns.push(e);
                }
            })
        });
        return interns;
    }

    /**
     * Get the identifier of the current user.
     * @return {[type]} [description]
     */
    getCurrentUserId = () => {
        return (
            this.getState().currentUserId ||
            (this.getState().entities.length
                ? this.getState().entities[0].id
                : null)
        );
    };

    /**
     * Get the identifier of the current user.
     * @return {[type]} [description]
     */
    getCurrentUser = () => this.getById(this.getCurrentUserId());
}

const store = new UserStore();
export default store;