import React from "react";

import { Table, Button, Tooltip } from "antd";
import TableColumnFilter from "components/TableColumnFilter.jsx";
import FilterIcon from "components/FilterIcon.jsx";
import LoadingIcon from "components/LoadingIcon.jsx";

import ShowIncidentModal from "views/Incident/ShowIncidentModal.jsx";

import Locale from "locale/LocaleFactory";
import ArrayService from "services/utils/ArrayService";
import DateService from "services/utils/DateService";
import StringService from "services/utils/StringService";
import FilterService from "services/utils/FilterService";

import IncidentActions from "actions/IncidentActions";
import IncidentStore from "stores/IncidentStore";
import IncidentService from "services/IncidentService";
import { EyeInvisibleOutlined, EyeOutlined, PrinterOutlined } from "@ant-design/icons";

// Sorting Methods
function sortDateColumn(r1, r2) {
  return StringService.compareCaseInsensitive(r1.date, r2.date);
}

function sortMissionColumn(r1, r2) {
  return StringService.compareCaseInsensitive(r1.mission.name, r2.mission.name);
}

function sortAuthorColumn(r1, r2) {
  const s1 = `${r1.author.firstName} ${r1.author.lastName}`;
  const s2 = `${r2.author.firstName} ${r2.author.lastName}`;
  return StringService.compareCaseInsensitive(s1, s2);
}

/**
 * The list of the incidents.
 */
export default class IncidentList extends React.Component {
  constructor() {
    super();

    const incidents = IncidentStore.getIncidents();

    this.state = {
      loading: !incidents.length,
      incidents,

      filteredIncidents: [],

      filters: {
        date: [],
        mission: [],
        author: [],
      },

      incidentToShow: null,
      archivedVisible: false,
    };
  }

  componentDidMount() {
    this.incidentListener = IncidentStore.addListener(this.receiveIncidents);
    this.loadIncidents();

    this.updateFilters();
  }

  componentWillUnmount() {
    this.incidentListener.remove();
  }

  loadIncidents = () => {
    this.setState({
      loading: true,
      archivedVisible: false,
    });
    IncidentActions.reload().finally(() => {
      this.setState({
        loading: false,
      });
    });
  };

  loadArchivedIncidents = () => {
    this.setState({
      loading: true,
      archivedVisible: true,
    });
    IncidentActions.reloadArchived().finally(() => {
      this.setState({
        loading: false,
      });
    });
  };

  receiveIncidents = () => {
    this.setState((prev) => {
      return {
        incidents: prev.archivedVisible
          ? IncidentStore.getArchivedIncidents()
          : IncidentStore.getIncidents(),
      };
    }, this.updateFilters);
  };

  // Filters
  updateFilters = () => {
    const { incidents } = this.state;
    const filteredIncidents = incidents.filter(this.incidentMatchFilters);
    this.setState({ filteredIncidents });
  };

  incidentMatchFilters = (e) => {
    const { filters } = this.state;
    return (
      FilterService.matchFilter(filters.date, e.date) &&
      FilterService.matchFilter(filters.mission, e.mission.id) &&
      FilterService.matchFilter(filters.author, e.author.id)
    );
  };

  handleFilterChange = (name, values) => {
    let prevState = this.state.filters;
    prevState[name] = values;
    this.setState({ filters: prevState });
    this.updateFilters();
  };

  getIncidentDates = () =>
    ArrayService.unique(this.state.incidents.map((c) => c.date));

  showIncident = (incident) => {
    this.setState({
      incidentToShow: incident,
    });
  };

  hideIncident = () => {
    this.setState({
      incidentToShow: null,
    });
  };

  render() {
    const { incidentToShow, archivedVisible } = this.state;
    return (
      <div className="report-list">
        <Button
          type="danger"
          style={{
            color: !archivedVisible && "#f04134",
            marginBottom: "1%",
            zIndex: 1,
            float: "right",
          }}
          ghost={!archivedVisible}
          icon={archivedVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          onClick={() => {
            archivedVisible
              ? this.loadIncidents()
              : this.loadArchivedIncidents();
          }}
        >
          {archivedVisible
            ? Locale.trans("archive.unarchived")
            : Locale.trans("archive.archived")}
        </Button>
        {this.renderIncidentTable()}
        {incidentToShow &&
        <ShowIncidentModal
          incident={incidentToShow}
          onCancel={this.hideIncident}
          visible={!!incidentToShow}
        />}
      </div>
    );
  }

  renderIncidentTable() {
    const { filters, incidents, filteredIncidents } = this.state;

    const columns = [
      {
        title: Locale.trans("incident.date"),
        key: "date",
        sorter: sortDateColumn,
        defaultSortOrder: "descend",
        filterIcon: <FilterIcon active={filters.date.length > 0} />,
        render: this.renderIncidentDateCell,
        filterDropdown: (
          <TableColumnFilter
            name="date"
            selectedValues={filters.date}
            values={this.getIncidentDates().map((d) => ({
              text: DateService.formatApiToDisplay(d),
              value: d,
            }))}
            onChange={this.handleFilterChange}
          />
        ),
      },
      {
        title: Locale.trans("incident.mission"),
        key: "mission",
        sorter: sortMissionColumn,
        filterIcon: <FilterIcon active={filters.mission.length > 0} />,
        render: this.renderIncidentMissionCell,
        filterDropdown: (
          <TableColumnFilter
            name="mission"
            selectedValues={filters.mission}
            values={ArrayService.uniqueEntity(
              incidents.map((r) => r.mission)
            ).map((m) => ({
              text: m.name,
              value: m.id,
            }))}
            onChange={this.handleFilterChange}
          />
        ),
      },
      {
        title: Locale.trans("incident.author"),
        key: "author",
        sorter: sortAuthorColumn,
        filterIcon: <FilterIcon active={filters.author.length > 0} />,
        render: this.renderIncidentAuthorCell,
        filterDropdown: (
          <TableColumnFilter
            name="author"
            selectedValues={filters.author}
            values={ArrayService.uniqueEntity(
              filteredIncidents.map((r) => r.author)
            ).map((m) => ({
              text: `${m.firstName} ${m.lastName}`,
              value: m.id,
            }))}
            onChange={this.handleFilterChange}
          />
        ),
      },
      {
        title: null,
        key: "actions",
        width: "120px",
        render: this.rendActionsCell,
      },
    ];

    return (
      <Table
        dataSource={filteredIncidents}
        rowKey="id"
        columns={columns}
        locale={Locale.Table}
        ref={(r) => {
          this.table = r;
        }}
        loading={this.state.loading && { indicator: <LoadingIcon /> }}
      />
    );
  }

  renderIncidentDateCell = (incident) =>
    DateService.formatApiToDisplay(incident.date);

  renderIncidentMissionCell = (incident) => {
    return incident.mission.name;
  };

  renderIncidentAuthorCell = (incident) => {
    return `${incident.author.firstName} ${incident.author.lastName}`;
  };

  rendActionsCell = (incident) => (
    <div className="actions">
      {this.rendPrintCell(incident)}
      <Tooltip title={Locale.trans("view")}>
        <Button
          type="primary"
          shape="circle"
          icon={<EyeOutlined />}
          onClick={(e) => {
            this.showIncident(incident);
            e.stopPropagation();
            e.preventDefault();
            return false;
          }}
        />
      </Tooltip>
    </div>
  );

  rendPrintCell = (incident) => (
    <Tooltip title={Locale.trans("print")}>
      <Button
        type="primary"
        shape="circle"
        icon={<PrinterOutlined />}
        onClick={() => IncidentService.download(incident.id)}
      />
    </Tooltip>
  );
}
