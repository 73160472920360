
import dispatcher from "../dispatchers/AppDispatcher";
import ActionsBase from "../actions/ActionsBase";

import MediaConstants from "../constants/MediaConstants";
import MediaService from "../services/MediaService";

import CacheManager from "../services/CacheManager";

const CACHE_DURATION = 30 * 1000;

class MediaActions extends ActionsBase {
  create = (media) => {
    const $this = this;
    return new Promise((resolve, reject) => {
        function handleSuccess(newMedia) {
          dispatcher.dispatch({
            type: MediaConstants.RECEIVE_MEDIA,
            payload: {
              media: newMedia
            }
          });
          resolve(newMedia);
        }

        function handleError(err) {
          $this.handleError(err, reject);
        }

        MediaService.post(media)
          .then(handleSuccess)
          .catch(handleError);
      }
    );
  };

  edit = (mediaId, media) => {
    const $this = this;
    return new Promise((resolve, reject) => {
        function handleSuccess(newMedia) {
          dispatcher.dispatch({
            type: MediaConstants.RECEIVE_MEDIA,
            payload: {
              media: newMedia
            }
          });
          resolve(newMedia);
        }

        function handleError(err) {
          $this.handleError(err, reject);
        }

        MediaService.patch(mediaId, media)
          .then(handleSuccess)
          .catch(handleError);
      }
    );
  };

  delete = (id) => {
    const $this = this;
    return new Promise(
      (resolve, reject) => {
        function handleSuccess() {
          dispatcher.dispatch({
            type: MediaConstants.DELETE_MEDIA,
            payload: {
              id
            }
          });
          resolve();
        }

        function handleError(err) {
          $this.handleError(err, reject);
        }

        MediaService.remove(id)
          .then(handleSuccess)
          .catch(handleError);
      }
    );
  };

  reload = () => {
    const $this = this;
    return new Promise(
      (resolve, reject) => {
        if (CacheManager.isCached("Media:reload", "", CACHE_DURATION)) {
          resolve();
          return;
        }

        function handleSuccess(medias) {
          dispatcher.dispatch({
            type: MediaConstants.RECEIVE_MEDIAS,
            payload: {
              medias
            }
          });
          resolve();
        }

        function handleError(err) {
          $this.handleError(err, reject);
        }

        MediaService.getAll()
          .then(handleSuccess)
          .catch(handleError);
      }
    );
  };

  reloadByReport = (report) => {
    const $this = this;
    return new Promise(
      (resolve, reject) => {
        if (
          CacheManager.isCached(
            "Media:reloadByReport",
            report.id.toString(),
            CACHE_DURATION
          )
        ) {
          resolve();
        }

        function handleSuccess(medias) {
          dispatcher.dispatch({
            type: MediaConstants.RECEIVE_MEDIAS_OF_REPORT,
            payload: {
              report,
              medias
            }
          });
          resolve();
        }

        function handleError(err) {
          $this.handleError(err, reject);
        }

        MediaService.getByReport(report.id)
          .then(handleSuccess)
          .catch(handleError);
      }
    );
  };

  reloadByIncident = (incident) => {
    const $this = this;
    return new Promise(
      (resolve, reject) => {
        if (
          CacheManager.isCached(
            "Media:reloadByIncident",
            incident.id.toString(),
            CACHE_DURATION
          )
        ) {
          resolve();
        }

        function handleSuccess(medias) {
          dispatcher.dispatch({
            type: MediaConstants.RECEIVE_MEDIAS_OF_INCIDENT,
            payload: {
              incident,
              medias
            }
          });
          resolve();
        }

        function handleError(err) {
          $this.handleError(err, reject);
        }

        MediaService.getByIncident(incident.id)
          .then(handleSuccess)
          .catch(handleError);
      }
    );
  };

  reloadByReview = (review) => {
    const $this = this;
    return new Promise(
      (resolve, reject) => {
        if (
          CacheManager.isCached(
            "Media:reloadByReview",
            review.id.toString(),
            CACHE_DURATION
          )
        ) {
          resolve();
        }

        function handleSuccess(medias) {
          dispatcher.dispatch({
            type: MediaConstants.RECEIVE_MEDIAS_OF_REVIEW,
            payload: {
              review,
              medias
            }
          });
          resolve();
        }

        function handleError(err) {
          $this.handleError(err, reject);
        }

        MediaService.getByReview(review.id)
          .then(handleSuccess)
          .catch(handleError);
      }
    );
  };

  reloadByArticle = article => {
    const $this = this;
    return new Promise((resolve, reject) => {
      if (
        CacheManager.isCached(
          "Media:reloadByArticle",
          article.id.toString(),
          CACHE_DURATION
        )
      ) {
        resolve();
      }

      function handleSuccess(medias) {
        dispatcher.dispatch({
          type: MediaConstants.RECEIVE_MEDIAS_OF_ARTICLE,
          payload: {
            article,
            medias
          }
        });
        resolve();
      }

      function handleError(err) {
        $this.handleError(err, reject);
      }

      MediaService.getByArticle(article.id)
        .then(handleSuccess)
        .catch(handleError);
    });
  };

  reloadByArticles = articles => {
    return Promise.all(articles.map(this.reloadByArticle));
  };

  reloadById = (id) => {
    const $this = this;
    return new Promise(
      (resolve, reject) => {
        function handleSuccess(media) {
          dispatcher.dispatch({
            type: MediaConstants.RECEIVE_MEDIA,
            payload: {
              media
            }
          });
          resolve();
        }

        function handleError(err) {
          $this.handleError(err, reject);
        }

        MediaService.getById(id)
          .then(handleSuccess)
          .catch(handleError);
      }
    );
  };
}

const action = new MediaActions();
export default action;
