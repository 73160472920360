import React, { useCallback, useMemo, useState } from 'react';
import SelectFormItem from './SelectFormItem';
import { Spin } from 'antd';
import ArrayService from 'services/utils/ArrayService';
import StringService from 'services/utils/StringService';
import MissionService, { IMission } from 'services/MissionService';

function cmpUsersByName(a: IMission, b: IMission) {
    return StringService.compareCaseInsensitive(a.name, b.name);
}

interface IProps {
    id: string
    label: string
    multiple?: boolean
    initialValue?: number | number[]
    readOnly?: boolean

    extraFilters?: {
        archived?: boolean
        types?: string[]
    }

    initialMissions?: IMission[]
    placeholder?: string
}
const MissionSelectFormItem: React.FC<IProps> = ({ id, label, multiple, initialValue, readOnly, extraFilters, initialMissions, placeholder }) => {

    const [missions, setMissions] = useState<IMission[]>([]);
    const [loading, setLoading] = useState(false);

    const strExtraFilters = JSON.stringify(extraFilters ?? {});

    const onSearch = useCallback(async (nameFilter: string) => {
        if (nameFilter && nameFilter.length >= 2) {
            setLoading(true);
            const res = await MissionService.getAll({
                ...JSON.parse(strExtraFilters),
                name: nameFilter
            });
            setMissions(res);
            setLoading(false);
        } else {
            setMissions([]);
        }
    }, [strExtraFilters]);

    const options = useMemo(() => {
        return ArrayService.uniqueEntity([...(initialMissions ?? []), ...missions])
            .sort(cmpUsersByName)
            .map(u => ({
                value: u.id,
                label: u.name
            }))
    }, [initialMissions, missions]);

    return <SelectFormItem
        id={id}
        multiple={multiple}
        label={label}
        initialValue={initialValue}
        onSearch={onSearch}
        notFoundContent={loading ? <Spin size="small" /> : null}
        filterOption={false}
        options={options}
        readOnly={readOnly}
        placeholder={placeholder}
    />;
}

export default MissionSelectFormItem;