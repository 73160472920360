import ArchivedEntityStoreBase from "../stores/ArchivedEntityStoreBase";
import HostCompanyConstants from "../constants/HostCompanyConstants";

class HostCompanyStore extends ArchivedEntityStoreBase {
    getInitialState() {
        return {
            currentHostCompanyId: null,
            entities: [],
            archived: [],
        };
    }

    reduce(state, action) {
        const { type, payload } = action;
        let newState = Object.assign({}, state);

        switch (type) {
            case HostCompanyConstants.RECEIVE_HOST_COMPANIES_OF_MISSION:
                newState = this._receiveByMission(
                    state,
                    payload.mission,
                    payload.hostCompanies
                );
                break;
            case HostCompanyConstants.RECEIVE_HOST_COMPANY:
                newState = this._receiveEntity(state, payload.hostCompany);
                break;
            case HostCompanyConstants.RECEIVE_ARCHIVED_HOST_COMPANY:
                newState = this._receiveArchivedEntity(state, payload.hostCompany);
                break;
            case HostCompanyConstants.RECEIVE_HOST_COMPANIES:
                newState = this._receiveMany(state, payload.hostCompanies);
                break;
            case HostCompanyConstants.RECEIVE_ALL_HOST_COMPANIES:
                newState = this._receiveAll(state, payload.hostCompanies);
                break;
            case HostCompanyConstants.RECEIVE_ARCHIVED_HOST_COMPANIES:
                newState = this._receiveArchived(state, payload.hostCompanies);
                break;
            case HostCompanyConstants.CHANGE_CURRENT_HOST_COMPANY:
                newState.currentHostCompanyId = payload.hostCompanyId;
                break;
            case "clearAll":
                newState = this.getInitialState();
                break;
            default:
                newState = state;
        }
        return newState;
    }

    _receiveByMission = (state, mission, hostCompanies) => {
        let newState = Object.assign({}, state);
        newState.entities = hostCompanies;
        return newState;
    };

    _receiveMany = (state, hostCompanies) => {
        let newState = Object.assign({}, state);
        // We remove all the entities that have the same ID as the new host companies
        newState.entities = state.entities.filter(
            e => hostCompanies.findIndex(a => e.id === a.id) === -1
        );
        newState.entities.push(...hostCompanies);
        return newState;
    };

    _receiveArchived = (state, hostCompanies) => {
        let newState = Object.assign({}, state);
        // We remove all the entities that have the same ID as the new host companies
        newState.archived = state.archived.filter(
            e => hostCompanies.findIndex(a => e.id === a.id) === -1
        );
        newState.archived.push(...hostCompanies); 
        return newState;
      };

    ///// Public Methods  /////

    getHostCompany() {
        return this.getState().hostCompany;
    }

    /**
     * Get all the host companies of a mission.
     */
    getByMission = (mission) => {
        return this.getState().entities.filter(e => !!e.mission.find(a => a.id === mission.id));
    }

    /**
     * Get a host company by ID.
     * @param {number} id The identifier.
     * @return {?HostCompany}    The host company, or NULL if no entity is found.
     */
    getById = (id) => {
        return this.getState().entities.find(e => e.id === id);
    }

    /**
     * Get an archived host company by ID.
     * @param {number} id The identifier of the archived host company.
     * @return {?HostCompany}    The archived host company, or NULL if no entity is found.
     */
    getArchivedById = id => this.getState().archived.find(e => e.id === id);

    /**
     * Get all the host companies.
     */
    getAll = () => this.getState().entities.concat(this.getState().archived);

    /**
     * Get unarchived host companies.
     */
    getHostCompanies = () => this.getState().entities;

    getArchivedHostCompanies = () => this.getState().archived;

    /**
     * Get the identifier of the current host company.
     * @return {[type]} [description]
     */
    getCurrentHostCompanyId = () => {
        return this.getState().currentHostCompanyId ||
        (this.getState().entities.length
            ? this.getState().entities[0].id
            : null);
    }

    /**
     * Get the identifier of the current host company.
     * @return {[type]} [description]
     */
    getCurrentHostCompany = () => {
        return this.getById(this.getCurrentHostCompanyId());
    }
}

const store = new HostCompanyStore();
export default store;