import React from "react";
import { Modal } from "antd";

import UserTypeForm from "./UserTypeForm";

import ToastActions from "actions/ToastActions";

import UserTypeActions from "actions/UserTypeActions";
import Locale, { Locale as LocaleComponent } from "locale/LocaleFactory";
import UserTypeService from "services/UserTypeService.js";

/**
 * The modal to edit a user type.
 */
export default class EditUserTypeModal extends React.Component {
  constructor(props) {
    super();
    this.state = {
      loading: false,
      features: [],
      fields: this.getFieldsFromEntity(props.userType),
    };
  }

  componentDidMount() {
    UserTypeService.getFeatures().then((res) => {
      this.setState({ features: res });
    });
  }

  componentWillUnmount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.visible && !this.props.visible) {
      this.setState({
        fields: this.getFieldsFromEntity(nextProps.userType),
      });
    }
  }

  getFieldsFromEntity = (entity) => {
    if (!entity) {
      return {};
    }
    const fields = {};
    const keys = Object.keys(entity);
    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      if (k === "permissions") {
        entity[k].forEach((p) => {
          fields[`permissions_${p.moduleName}`] = {
            value: `${p.accessibility}`,
          };
        });
      } else {
        fields[k] = { value: entity[k] };
      }
    }
    return fields;
  };

  handleFormChange = (changedFields) => {
    this.setState(({ fields }) => ({
      fields: { ...fields, ...changedFields },
    }));
  };

  handleSubmit = (entity) => {
    if (!this.props.userType) {
      return;
    }

    const id = this.props.userType.id;

    this.setState({
      loading: true,
    });
    UserTypeActions.edit(id, entity)
      .then((newEntity) => {
        this.setState({
          fields: {},
          loading: false,
        });
        ToastActions.createToastSuccess(
          Locale.trans("userType.update.success", { title: newEntity.title })
        );
        this.props.onCancel();
      })
      .catch(this.handleError);
  };

  handleError = (err) => {
    this.setState({
      loading: false,
    });
    try {
      const resp = JSON.parse(err.response);
      ToastActions.createToastError(resp.message);
    } catch (e) {
      ToastActions.createToastError(Locale.trans("userType.update.error"));
    }
  };

  render() {
    const { userType, visible, onCancel } = this.props;
    const { fields, features, loading } = this.state;
    if (features.length === 0) {
      return null;
    }
    return (
      userType && visible && <Modal
        title={<LocaleComponent transKey="userType.update" />}
        open
        onCancel={onCancel}
        footer={null}
      >
        <UserTypeForm
          features={features}
          userType={userType}
          onChange={this.handleFormChange}
          fields={fields}
          onSubmit={this.handleSubmit}
          loading={loading}
        />
      </Modal>
    );
  }
}
