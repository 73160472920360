import React from 'react';
import FormItemBase from 'components/form-items/FormItemBase.jsx';
import { Form, DatePicker } from 'antd';

import DateConstants from '../../constants/DateConstants';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

export default class RangePickerFormItem extends FormItemBase {
    render() {
        const {
            id, initialValue, readOnly, format,
        } = this.props;
        const {
            label, labelCol, wrapperCol, extra,
        } = this.props;
        return (
            <Form.Item
                name={id}
                // validateStatus={this.getValidateStatus(id)}
                // help={this.getHelp(id)}
                // hasFeedback
                label={label}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                extra={extra}
                initialValue={initialValue
                    ? dayjs(initialValue, DateConstants.API_DATE_FORMAT)
                    : null}
                rules={this.getRules()}
            >
                <RangePicker readOnly={readOnly} format={format} />
            </Form.Item>
        );
    }
}
