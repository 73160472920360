import ArchivedEntityStoreBase from "../stores/ArchivedEntityStoreBase";
import ReviewConstants from "../constants/ReviewConstants";

class ReviewStore extends ArchivedEntityStoreBase {
    getInitialState() {
        return {
            entities: [],
            archived: [],
        };
    }

    reduce(state, action) {
        const { type, payload } = action;
        let newState = Object.assign({}, state);

        switch (type) {
            case ReviewConstants.RECEIVE_REVIEWS_OF_USER:
                newState = this._receiveByUser(
                    state,
                    payload.user,
                    payload.reviews
                );
                break;
            case ReviewConstants.RECEIVE_REVIEWS_OF_MISSION:
                newState = this._receiveByMission(
                    state,
                    payload.mission,
                    payload.reviews
                );
                break;
            case ReviewConstants.RECEIVE_REVIEW:
                newState = this._receiveEntity(state, payload.review);
                break;
            case ReviewConstants.RECEIVE_REVIEWS:
                newState = this._receiveMany(state, payload.reviews);
                break;
            case ReviewConstants.DELETE_REVIEW:
                newState = this._deleteOne(state, payload.id);
                break;
            case ReviewConstants.RECEIVE_ALL_REVIEWS:
                newState = this._receiveAll(state, payload.reviews);
                break;
            case ReviewConstants.RECEIVE_ARCHIVED_REVIEWS:
                newState = this._receiveArchived(state, payload.reviews);
                break;
            case "clearAll":
                newState = this.getInitialState();
                break;
            default:
                newState = state;
        }
        return newState;
    }

    _receiveByUser = (state, user, reviews) => {
        let newState = Object.assign({}, state);
        newState.entities = state.entities.filter(
            e => reviews.findIndex(a => e.id === a.id) === -1
        );
        newState.entities.push(...reviews);
        return newState;
    };

    _receiveByMission = (state, mission, reviews) => {
        let newState = Object.assign({}, state);
        newState.entities = state.entities.filter(
            e => reviews.findIndex(a => e.id === a.id) === -1
        );
        newState.entities.push(...reviews);
        return newState;
    };

    _receiveMany = (state, reviews) => {
        let newState = Object.assign({}, state);
        // We remove all the entities that have the same ID as the new reviews
        newState.entities = state.entities.filter(
            e => reviews.findIndex(a => e.id === a.id) === -1
        );
        newState.entities.push(...reviews);
        return newState;
    };

    _receiveArchived = (state, reviews) => {
        let newState = Object.assign({}, state);
        newState.archived = state.archived.filter(
            e => reviews.findIndex(a => e.id === a.id) === -1
        );
        newState.archived.push(...reviews);
        return newState;
    };

    _deleteOne = (state, reviewId) => {
        const i = state.entities.findIndex(c => c.id === reviewId);
        if (i === -1) {
            return state;
        }
        let newState = Object.assign({}, state);
        newState.entities = state.entities.slice();
        newState.entities.splice(i, 1);
        return newState;
    };

    ///// Public Methods  /////

    getReview() {
        return this.getState().review;
    }

    /**
     * Get all the reviews of an user.
     */
    getByUser = (user) => {
        return this.getState().entities.filter(e => e.user.id === user.id);
    };

    /**
     * Get all the reviews of an mission.
     */
    getByMission = (mission) => {
        return this.getState().entities.filter(e => e.mission.id === mission.id);
    };

    /**
     * Get a review by ID.
     * @param {number} id The identifier.
     * @return {?Review} The review, or NULL if no entity is found.
     */
    getById = (id) => {
        return this.getState().entities.find(e => e.id === id);
    };

    /**
     * Get an archived review by ID.
     * @param {number} id The identifier of the archived review.
     * @return {?Review}    The archived review, or NULL if no entity is found.
     */
    getArchivedById = id => this.getState().archived.find(e => e.id === id);

    /**
     * Get all the reviews.
     */
    getAll = () => this.getState().entities.concat(this.getState().archived);

    /**
     * Get unarchived reviews.
     */
    getReviews = () => this.getState().entities;

    getArchivedReviews = () => this.getState().archived;
}

const store = new ReviewStore();
export default store;