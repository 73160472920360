import React from 'react';
import { Modal } from 'antd';

import SubSkillForm from 'components/forms/SubSkillForm';

import ToastActions from 'actions/ToastActions';

import SubSkillActions from 'actions/SubSkillActions';

import SkillActions from 'actions/SkillActions';
import SkillStore from 'stores/SkillStore';
import Locale, { Locale as LocaleComponent } from 'locale/LocaleFactory';

/**
 * The modal to create a new subskill.
 */
export default class CreateSubSkillModal extends React.Component {
    skillListener;

    constructor() {
        super();
        this.state = {
            loading: false,
            fields: {},
            skills: SkillStore.getSkills(),
        };
    }

    componentDidMount() {
        this.skillListener = SkillStore.addListener(this.receiveSkills);
        SkillActions.reload();
    }
    componentWillUnmount() {
        this.skillListener.remove();
    }
    receiveSkills = () => {
        this.setState({
            skills: SkillStore.getSkills(),
        });
    }

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    handleSubmit = (subSkill) => {
        this.setState({
            loading: true,
        });
        SubSkillActions.create(subSkill)
            .then((newSubSkill) => {
                this.setState({
                    fields: {},
                    loading: false,
                });
                ToastActions.createToastSuccess(Locale.trans('subSkill.add.success', {name: newSubSkill.name}));
                this.props.onCancel();
            })
            .catch(this.handleError);
    };

    handleError = (err) => {
        this.setState({
            loading: false,
        });
        try {
            const resp = JSON.parse(err.response);
            ToastActions.createToastError(resp.message);
        } catch (e) {
            ToastActions.createToastError('Une erreur est survenue');
        }
    };

    render() {
        const { visible, onCancel } = this.props;
        const { fields, loading, skills } = this.state;
        return (
            <Modal
                title={<LocaleComponent transKey="subSkill.add" />}
                open={visible}
                onCancel={onCancel}
                footer={null}
            >
                <SubSkillForm
                    skills={skills}
                    onChange={this.handleFormChange}
                    fields={fields}
                    onSubmit={this.handleSubmit}
                    loading={loading}
                />
            </Modal>
        );
    }
}
