import ServiceBase from "../services/ServiceBase";
import BaseUrlConstants from "../constants/BaseUrlConstants";
import TokenContainer from "services/TokenContainer";

const URL = `${BaseUrlConstants.BASE_URL}`;

class IncidentService extends ServiceBase {
  constructor() {
    super(URL);
  }

  /**
   * Get a incident by unique identifier.
   * @param {number} incidentId The identifier of the incident.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getById(incidentId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}incidents/${incidentId}`,
      method: "GET"
    });
  }

  /**
   * Get incidents of a user.
   * @param {number} userId The identifier of the user.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getByUser(userId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}users/${userId}/incidents`,
      method: "GET"
    });
  }

  /**
   * Get incidents of a user.
   * @param {number} missionId The identifier of the mission.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getByMission(missionId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}missions/${missionId}/incidents`,
      method: "GET"
    });
  }

  /**
     * Get all unarchived incidents.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getAll() {
      return this.execute({
          url: `${BaseUrlConstants.BASE_URL}incidents?archived=false`,
          method: "GET"
      });
  }

  /**
   * Get all archived incidents.
   *
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  getAllArchived() {
      return this.execute({
          url: `${BaseUrlConstants.BASE_URL}incidents?archived=true`,
          method: "GET"
      });
  }

  /**
   * Post a new incident to an user.
   * @param {Object} incident The incident to create.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  post(incident) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}incidents`,
      method: "POST",
      data: incident
    });
  }

  /**
   * Patch an existing incident. Only the properties that are set on the patch will be updated.
   * @param {number} incidentId The identifier of the incident.
   * @param {Object} patch The properties to update.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  patch(incidentId, patch) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}incidents/${incidentId}`,
      method: "PATCH",
      data: patch
    });
  }

  /**
   * Delete an existing incident.
   * @param {number} incidentId The identifier of the incident.
   * @returns {Promise} A promise to handle the request ascynchronously.
   */
  remove(incidentId) {
    return this.execute({
      url: `${BaseUrlConstants.BASE_URL}incidents/${incidentId}`,
      method: "DELETE"
    });
  }

  /**
   * @param  {number} incidentId The ID of the incident.
   * @return {[type]}            [description]
   */
  download(incidentId) {
    const token = TokenContainer.get().replace(/[+]/g, "%2B");
    const url = `${BaseUrlConstants.BASE_URL}incidents/${incidentId}/download`;
    window.location = `${url}?X-Auth-Token=${token}`;
  }
}

const service = new IncidentService();
export default service;