import EntityStoreBase from "./EntityStoreBase";
import UserTypeConstants from "../constants/UserTypeConstants";

class UserTypeStore extends EntityStoreBase {
  getInitialState() {
    return {
      entities: [],
    };
  }

  reduce(state, action) {
    const { type, payload } = action;
    let newState = Object.assign({}, state);

    switch (type) {
      case UserTypeConstants.RECEIVE_USER_TYPE:
        newState = this._receiveEntity(state, payload.userType);
        break;
      case UserTypeConstants.RECEIVE_USER_TYPES:
        newState = this._receiveMany(state, payload.userTypes);
        break;

      /*
            case "clearAll":
                newState = this.getInitialState();
                break;

    // */
      default:
        newState = state;
    }
    return newState;
  }

  _receiveMany = (state, userTypes) => {
    const newState = Object.assign({}, state);
    // We remove all the entities that have the same ID as the new user types
    newState.entities = state.entities.filter(
      (e) => userTypes.findIndex((a) => e.id === a.id) === -1
    );
    newState.entities.push(...userTypes);
    return newState;
  };

  // /// Public Methods  /////

  /**
   * Get a user type by ID.
   * @param {number} id The identifier.
   * @return {?IUserType}    The user type, or NULL if no entity is found.
   */
  getById = (id) => this.getState().entities.find((e) => e.id === id);
  /**
   * Get all the user types.
   */
  getAll = () => this.getState().entities;
}

const store = new UserTypeStore();
export default store;
