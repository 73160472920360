import React from "react";
import { Modal, Table } from "antd";

import DeleteButton from "components/forms/DeleteButton.jsx";

import TableColumnFilter from "components/TableColumnFilter.jsx";
import FilterIcon from "components/FilterIcon.jsx";
import LoadingIcon from "components/LoadingIcon.jsx";
import EmargementPicture from "components/EmargementPicture.jsx";

import Locale from "locale/LocaleFactory";
import ArrayService from "services/utils/ArrayService";
import StringService from "services/utils/StringService";
import DateService from "services/utils/DateService";
import FilterService from "services/utils/FilterService";
import SecurityService from "services/SecurityService";
import Access from "constants/AccessLevel";
import Resource from "constants/Resource";

import ToastActions from "actions/ToastActions";

import EmargementActions from "actions/EmargementActions";
import EmargementStore from "stores/EmargementStore";

// Sorting Methods
function sortDateColumn(c1, c2) {
  return StringService.compareCaseInsensitive(
    c1.emargementDate,
    c2.emargementDate
  );
}
function sortNameColumn(u1, u2) {
  const s1 = u1 ? u1.firstName + u1.lastName : "";
  const s2 = u2 ? u2.firstName + u2.lastName : "";
  return StringService.compareCaseInsensitive(s1, s2);
}

/**
 * The modal to list the emargements of a mission.
 */
export default class MissionEmargementsModal extends React.Component {
  constructor(props) {
    super();
    const emargements = EmargementStore.getByMission(props.mission);
    this.state = {
      loading: !emargements.length,
      emargements,
      filters: {
        date: [],
        user: []
      },
      filteredEmargements: []
    };
  }
  componentDidMount() {
    this.emargementListener = EmargementStore.addListener(this.receiveEmargements);

    this.loadEmargements();
    this.updateFilters();
  }

  componentWillUnmount() {
    this.emargementListener.remove();
  }

  loadEmargements = () => {
    this.setState({
      loading: true
    });
    EmargementActions.reloadByMission(this.props.mission).then(() => {
      this.setState({
        loading: false,
      });
    });
    this.emargementListener = EmargementStore.addListener(this.receiveEmargements);
  };

  receiveEmargements = () => {
    const { mission } = this.props;
    this.setState(
      {
        emargements: EmargementStore.getByMission(mission)
      },
      this.updateFilters
    );
  };

  // Filters
  updateFilters = () => {
    const { emargements } = this.state;
    const filteredEmargements = emargements
      .filter(this.emargementMatchFilters)
      .sort((e1, e2) => sortDateColumn(e2, e1));
    this.setState({ filteredEmargements });
  };

  handleFilterChange = (name, values) => {
    // eslint-disable-next-line
    this.state.filters[name] = values;
    this.updateFilters();
  };

  emargementMatchFilters = (e) => {
    const { filters } = this.state;
    return (
      FilterService.matchFilter(filters.date, e.emargementDate) &&
      FilterService.matchFilter(filters.user, e.user && e.user.id)
    );
  };

  getDates = () =>
    ArrayService.unique(this.state.emargements.map(c => c.emargementDate));

  getUsers = () =>
    ArrayService.uniqueEntity(
      this.state.emargements
        .map(e => e.user)
        .filter(u => !!u)
        .sort(sortNameColumn)
    );

  deleteEmargement = (emargement) => {
    EmargementActions.delete(emargement.id)
      .then(() => {
        ToastActions.createToastSuccess("Emargement supprimé");
      })
      .catch(() => {
        ToastActions.createToastError("Une erreur est survenue");
      });
  };

  render() {
    const { onCancel, mission } = this.props;

    return (
      <Modal
        title={`Emargements de ${mission.name}`}
        open={!!mission}
        onCancel={onCancel}
        footer={null}
        width={800}
      >
        {this.renderEmargementTable()}
      </Modal>
    );
  }

  renderEmargementTable() {
    const { filters, filteredEmargements, loading } = this.state;
    const columns = [
      {
        title: "Date",
        key: "date",
        sorter: sortDateColumn,
        filterIcon: <FilterIcon active={filters.date.length > 0} />,
        render: this.renderDateCell,
        filterDropdown: (
          <TableColumnFilter
            name="date"
            selectedValues={filters.name}
            values={this.getDates().map(r => ({
              text: DateService.formatApiToDisplay(r),
              value: r
            }))}
            onChange={this.handleFilterChange}
          />
        )
      },
      {
        title: "Utilisateur",
        key: "user",
        sorter: sortNameColumn,
        filterIcon: <FilterIcon active={filters.user.length > 0} />,
        render: this.renderUserCell,
        filterDropdown: (
          <TableColumnFilter
            name="user"
            selectedValues={filters.user}
            values={this.getUsers().map(u => ({
              text: `${u.firstName} ${u.lastName}`,
              value: u.id
            }))}
            onChange={this.handleFilterChange}
          />
        )
      },
      filteredEmargements.findIndex(r => r.emargementType !== "day") > -1 ?
      {
        title: "Période",
        key: "user",
        sorter: sortNameColumn,
        filterIcon: <FilterIcon active={filters.user.length > 0} />,
        render: (emargement) => Locale.trans(`mission.emargementTypes.${emargement.emargementType}`)
      } : undefined,
      {
        title: "Signature",
        key: "signature",
        width: "100px",
        render: this.renderSignatureCell
      }
    ].filter(r => !!r);

    if (SecurityService.isGranted(Resource.EMARGEMENT, Access.DELETE)) {
      columns.push({
        title: null,
        key: "actions",
        width: "50px",
        render: this.rendActionsCell
      });
    }

    return (
      <Table
        dataSource={filteredEmargements}
        rowKey="id"
        columns={columns}
        locale={Locale.Table}
        loading={loading && { indicator: <LoadingIcon /> }}
      />
    );
  }

  renderDateCell = (emargement) =>
    DateService.formatApiToDisplay(emargement.emargementDate);

  renderUserCell = (emargement) => {
    const user = emargement.user;
    return user && `${user.firstName} ${user.lastName}`;
  };

  renderSignatureCell = (emargement) => (
    <EmargementPicture emargement={emargement} />
  );

  rendActionsCell = (emargement) => (
    <DeleteButton
      shape="circle"
      onDelete={() => {
        this.deleteEmargement(emargement);
      }}
    />
  );
}
