
import React from 'react';
import { Modal } from 'antd';

import EstablishmentForm from 'components/forms/EstablishmentForm';

import ToastActions from 'actions/ToastActions';

import EstablishmentActions from 'actions/EstablishmentActions';

import UserTypeActions from 'actions/UserTypeActions';
import UserTypeStore from 'stores/UserTypeStore';

/**
 * The modal to create a new establishment.
 */
export default class CreateEstablishmentModal extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            fields: {},
            userTypes: UserTypeStore.getAll(),
        };
    }

    componentDidMount() {
        this.userTypeListener = UserTypeStore.addListener(this.receiveUserTypes);
        UserTypeActions.reload();
    }

    componentWillUnmount() {
        this.userTypeListener.remove();
    }

    receiveUserTypes = () => {
        this.setState({
            userTypes: UserTypeStore.getAll(),
        });
    };

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    handleSubmit = (establishment) => {
        this.setState({
            loading: true,
        });
        EstablishmentActions.create(establishment)
            .then((newEstablishment) => {
                this.setState({
                    fields: {},
                    loading: false,
                });
                ToastActions.createToastSuccess(`Etablissement "${newEstablishment.name}" créé`);
                this.props.onCancel();
            })
            .catch(this.handleError);
    };

    handleError = (err) => {
        this.setState({
            loading: false,
        });
        try {
            const resp = JSON.parse(err.response);
            ToastActions.createToastError(resp.message);
        } catch (e) {
            ToastActions.createToastError('Une erreur est survenue');
        }
    };

    render() {
        const { visible, onCancel } = this.props;
        const { fields, loading, userTypes } = this.state;
        return (
            <Modal
                title="Ajouter un Etablissement"
                open={visible}
                onCancel={onCancel}
                footer={null}
            >
                <EstablishmentForm
                    userTypes={userTypes}
                    onChange={this.handleFormChange}
                    fields={fields}
                    onSubmit={this.handleSubmit}
                    loading={loading}
                />
            </Modal>
        );
    }
}
