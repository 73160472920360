import dispatcher from "../dispatchers/AppDispatcher";
import ActionsBase from "../actions/ActionsBase";

import HostCompanyConstants from "../constants/HostCompanyConstants";
import HostCompanyService from "../services/HostCompanyService";

import CacheManager from "../services/CacheManager";

const CACHE_DURATION = 30 * 1000;

class HostCompanyActions extends ActionsBase {
    create = (hostCompany) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(newCompany) {
                dispatcher.dispatch({
                    type: HostCompanyConstants.RECEIVE_HOST_COMPANY,
                    payload: {
                        hostCompany: newCompany,
                    },
                });
                resolve(newCompany);
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            HostCompanyService.post(hostCompany)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    edit = (companyId, hostCompany) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(newCompany) {
                dispatcher.dispatch({
                    type: HostCompanyConstants.RECEIVE_HOST_COMPANY,
                    payload: {
                        hostCompany: newCompany,
                    },
                });
                resolve(newCompany);
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            HostCompanyService.patch(companyId, hostCompany)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    archiving = (hostCompanyId) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(newHostCompany) {
                dispatcher.dispatch({
                    type: HostCompanyConstants.RECEIVE_ARCHIVED_HOST_COMPANY,
                    payload: {
                        hostCompany: newHostCompany,
                    },
                });
                resolve();
            }
            function handleError(err) {
                $this.handleError(err, reject);
            }
            HostCompanyService.archiving(hostCompanyId)
                .then(handleSuccess)
                .catch(handleError);
        });
      };

    delete = (id) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess() {
                dispatcher.dispatch({
                    type: HostCompanyConstants.DELETE_HOST_COMPANY,
                    payload: {
                        id,
                    },
                });
                resolve();
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            HostCompanyService.remove(id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    changeCurrentHostCompanyId = (hostCompanyId) => {
        return new Promise(
            (resolve, reject) => {
                dispatcher.dispatch({
                    type: HostCompanyConstants.CHANGE_CURRENT_HOST_COMPANY,
                    payload: {
                        hostCompanyId,
                    }
                });
                resolve();
            }
        );
    };

    reload = () => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(hostCompanies) {
                dispatcher.dispatch({
                    type: HostCompanyConstants.RECEIVE_HOST_COMPANIES,
                    payload: {
                        hostCompanies,
                    },
                });
                resolve();
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            HostCompanyService.getAll()
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadArchived = () => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(hostCompanies) {
                dispatcher.dispatch({
                    type: HostCompanyConstants.RECEIVE_ARCHIVED_HOST_COMPANIES,
                    payload: {
                        hostCompanies,
                    },
                });
                resolve();
            }
            function handleError(err) {
                $this.handleError(err, reject);
            }
            HostCompanyService.getAllArchived()
                .then(handleSuccess)
                .catch(handleError);
        });
      };

    reloadById = (id) => {
        const $this = this;
        return new Promise(
            (resolve, reject) => {
                function handleSuccess(hostCompany) {
                    dispatcher.dispatch({
                        type: HostCompanyConstants.RECEIVE_HOST_COMPANY,
                        payload: {
                            hostCompany
                        }
                    });
                    resolve();
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                HostCompanyService.getById(id)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };

    reloadByMission = (mission) => {
        const $this = this;
        return new Promise(
            (resolve, reject) => {
                if (
                    CacheManager.isCached(
                        "HostCompany:reloadByMission",
                        mission.id.toString(),
                        CACHE_DURATION
                    )
                ) {
                    resolve();
                    return;
                }

                function handleSuccess(hostCompanies) {
                    dispatcher.dispatch({
                        type: HostCompanyConstants.RECEIVE_HOST_COMPANIES_OF_MISSION,
                        payload: {
                            hostCompanies,
                            mission
                        }
                    });
                    resolve();
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                HostCompanyService.getByMission(mission.id)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };
}

const action = new HostCompanyActions();
export default action;