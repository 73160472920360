import ArchivedEntityStoreBase from "../stores/ArchivedEntityStoreBase";
import PartnerConstants from "../constants/PartnerConstants";

class PartnerStore extends ArchivedEntityStoreBase {
    getInitialState() {
        return {
            currentPartnerId: null,
            entities: [],
            archived: [],
        };
    }

    reduce(state, action) {
        const { type, payload } = action;
        let newState = Object.assign({}, state);

        switch (type) {
            case PartnerConstants.RECEIVE_PARTNERS_OF_MISSION:
                newState = this._receiveByMission(
                    state,
                    payload.mission,
                    payload.partners
                );
                break;
            case PartnerConstants.RECEIVE_PARTNER:
                newState = this._receiveEntity(state, payload.partner);
                break;
            case PartnerConstants.RECEIVE_ARCHIVED_PARTNER:
                newState = this._receiveArchivedEntity(state, payload.partner);
                break;
            case PartnerConstants.RECEIVE_PARTNERS:
                newState = this._receiveMany(state, payload.partners);
                break;
            case PartnerConstants.RECEIVE_ALL_PARTNERS:
                newState = this._receiveAll(state, payload.partners);
                break;
            case PartnerConstants.RECEIVE_ARCHIVED_PARTNERS:
                newState = this._receiveArchived(state, payload.partners);
                break;
            case PartnerConstants.CHANGE_CURRENT_PARTNER:
                newState.currentPartnerId = payload.partnerId;
                break;
            case "clearAll":
                newState = this.getInitialState();
                break;
            default:
                newState = state;
        }
        return newState;
    }

    _receiveByMission = (state, mission, partners) => {
        let newState = Object.assign({}, state);
        newState.entities = partners;
        return newState;
    };

    _receiveMany = (state, partners) => {
        let newState = Object.assign({}, state);
        // We remove all the entities that have the same ID as the new partners
        newState.entities = state.entities.filter(
            e => partners.findIndex(a => e.id === a.id) === -1
        );
        newState.entities.push(...partners);
        return newState;
    };

    _receiveArchived = (state, partners) => {
        let newState = Object.assign({}, state);
        // We remove all the entities that have the same ID as the new partners
        newState.archived = state.archived.filter(
            e => partners.findIndex(a => e.id === a.id) === -1
        );
        newState.archived.push(...partners); 
        return newState;
      };

    ///// Public Methods  /////
    
    getPartner() {
        return this.getState().partner;
    }

    /**
     * Get all the partners of a mission.
     */
    getByMission = (mission) => {
        return this.getState().entities.filter(e => !!e.mission.find(a => a.id === mission.id));
    }

    /**
     * Get a partner by ID.
     * @param {number} id The identifier.
     * @return {?Partner}    The partner, or NULL if no entity is found.
     */
    getById = (id) => {
        return this.getState().entities.find(e => e.id === id);
    }

    /**
     * Get an archived partner by ID.
     * @param {number} id The identifier of the archived partner.
     * @return {?Partner}    The archived partner, or NULL if no entity is found.
     */
    getArchivedById = id => this.getState().archived.find(e => e.id === id);

    /**
     * Get all the partners.
     */
    getAll = () => this.getState().entities.concat(this.getState().archived);

    /**
     * Get unarchived partners.
     */
    getPartners = () => this.getState().entities;

    getArchivedPartners = () => this.getState().archived;

    /**
     * Get the identifier of the current partner.
     * @return {[type]} [description]
     */
    getCurrentPartnerId = () => {
        return this.getState().currentPartnerId ||
        (this.getState().entities.length
            ? this.getState().entities[0].id
            : null);
    }

    /**
     * Get the identifier of the current partner.
     * @return {[type]} [description]
     */
    getCurrentPartner = () => {
        return this.getById(this.getCurrentPartnerId());
    }
}

const store = new PartnerStore();
export default store;