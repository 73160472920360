import React from 'react';
import { Form, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import Locale from 'locale/LocaleFactory';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
interface IProps {
    loading: boolean
    onLogin: (userName: string, password: string, rememberMe: boolean) => void
}
const LoginForm: React.FC<IProps> = ({ loading, onLogin }) => {
    const [form] = Form.useForm();

    const handleSubmit = (values: any) => {
        onLogin(values.userName, values.password, false);
    };

    return (
        <Form onFinish={handleSubmit} layout="vertical" form={form}>
            <Form.Item
                name="userName"
                hasFeedback
                rules={
                    [
                        {
                            required: true,
                            message: Locale.trans('login.username.error.required'),
                        },
                    ]
                }
            >
                <Input
                    addonBefore={<UserOutlined />}
                    placeholder={Locale.trans('login.username')}
                />
            </Form.Item>
            <Form.Item
                name="password"
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: Locale.trans('login.password.error.required'),
                    },
                ]}
            >
                <Input
                    addonBefore={<LockOutlined />}
                    type="password"
                    placeholder={Locale.trans('login.password')}
                />
            </Form.Item>
            <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
            >
                {Locale.trans('login.logIn.button')}
            </Button>
            <Link to="/reset-password-request" className="reset-password">J&apos;ai oublié mon mot de passe</Link>
        </Form>
    );
}

export default LoginForm;
