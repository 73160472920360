import dispatcher from "../dispatchers/AppDispatcher";
import ActionsBase from "../actions/ActionsBase";

import ReviewConstants from "../constants/ReviewConstants";
import ReviewService from "../services/ReviewService";

import CacheManager from "../services/CacheManager";

const CACHE_DURATION = 30 * 1000;

class ReviewActions extends ActionsBase {
    create = (review) => {
        const $this = this;
        return new Promise((resolve, reject) => {
                function handleSuccess(newReview) {
                    dispatcher.dispatch({
                        type: ReviewConstants.RECEIVE_REVIEW,
                        payload: {
                            review: newReview
                        }
                    });
                    resolve(newReview);
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                ReviewService.post(review)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };

    edit = (reviewId, review) => {
        const $this = this;
        return new Promise(
            (
                resolve,
                reject
            ) => {
                function handleSuccess(newReview) {
                    dispatcher.dispatch({
                        type: ReviewConstants.RECEIVE_REVIEW,
                        payload: {
                            review: newReview
                        }
                    });
                    resolve(newReview);
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                ReviewService.patch(reviewId, review)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };

    delete = (id) => {
        const $this = this;
        return new Promise(
            (resolve, reject) => {
                function handleSuccess() {
                    dispatcher.dispatch({
                        type: ReviewConstants.DELETE_REVIEW,
                        payload: {
                            id
                        }
                    });
                    resolve();
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                ReviewService.remove(id)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };

    reload = () => {
        const $this = this;
        return new Promise((resolve, reject) => {
          function handleSuccess(reviews) {
            dispatcher.dispatch({
              type: ReviewConstants.RECEIVE_REVIEWS,
              payload: {
                reviews
              }
            });
            resolve();
          }
          function handleError(err) {
            $this.handleError(err, reject);
          }
          ReviewService.getAll()
            .then(handleSuccess)
            .catch(handleError);
        });
    };

    reloadArchived = () => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(reviews) {
                dispatcher.dispatch({
                    type: ReviewConstants.RECEIVE_ARCHIVED_REVIEWS,
                    payload: {
                        reviews,
                    },
                });
                resolve();
            }
            function handleError(err) {
                $this.handleError(err, reject);
            }
            ReviewService.getAllArchived()
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    changeCurrentReviewId = (reviewId) => {
        return new Promise(
            (resolve, reject) => {
                dispatcher.dispatch({
                    type: ReviewConstants.CHANGE_CURRENT_REVIEW,
                    payload: {
                        reviewId
                    }
                });
                resolve();
            }
        );
    };

    reloadByUser = (user) => {
        const $this = this;
        return new Promise(
            (resolve, reject) => {
                if (
                    CacheManager.isCached(
                        "Review:reloadByUser",
                        user.id.toString(),
                        CACHE_DURATION
                    )
                ) {
                    resolve();
                    return;
                }

                function handleSuccess(reviews) {
                    dispatcher.dispatch({
                        type: ReviewConstants.RECEIVE_REVIEWS_OF_USER,
                        payload: {
                            user,
                            reviews
                        }
                    });
                    resolve();
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                ReviewService.getByUser(user.id)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };

    reloadByMission = (mission) => {
        const $this = this;
        return new Promise(
            (resolve, reject) => {
                if (
                    CacheManager.isCached(
                        "Review:reloadByMission",
                        mission.id.toString(),
                        CACHE_DURATION
                    )
                ) {
                    resolve();
                    return;
                }

                function handleSuccess(reviews) {
                    dispatcher.dispatch({
                        type: ReviewConstants.RECEIVE_REVIEWS_OF_MISSION,
                        payload: {
                            CACHE_DURATION,
                            reviews
                        }
                    });
                    resolve();
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                ReviewService.getByMission(mission.id)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };

    reloadById = (id) => {
        const $this = this;
        return new Promise(
            (resolve, reject) => {
                function handleSuccess(review) {
                    dispatcher.dispatch({
                        type: ReviewConstants.RECEIVE_REVIEW,
                        payload: {
                            review
                        }
                    });
                    resolve();
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                ReviewService.getById(id)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };
}

const action = new ReviewActions();
export default action;