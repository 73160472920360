import React from 'react';
import { FilterOutlined } from '@ant-design/icons';

export default class FilterIcon extends React.PureComponent {
    render() {
        const { active, onClick } = this.props;
        return (
            <FilterOutlined
              style={{
                  color: active ? '#108ee9' : '#aaa',
              }}
              onClick={onClick}
            />
        );
    }
}
