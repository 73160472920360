const values = {
    RECEIVE_REPORT: 'RECEIVE_REPORT',
    RECEIVE_REPORTS: 'RECEIVE_REPORTS',
    RECEIVE_ALL_REPORTS: 'RECEIVE_ALL_REPORTS',
    RECEIVE_REPORTS_OF_USER: 'RECEIVE_REPORTS_OF_USER',
    RECEIVE_REPORTS_OF_MISSION: 'RECEIVE_REPORTS_OF_MISSION',
    CHANGE_CURRENT_REPORT: 'CHANGE_CURRENT_REPORT',
    DELETE_REPORT: 'DELETE_REPORT',
    RECEIVE_ARCHIVED_REPORTS: 'RECEIVE_ARCHIVED_REPORTS',
}
export default values;