import React from "react";
import { Modal, Table, Button } from "antd";

import TableColumnFilter from "components/TableColumnFilter.jsx";
import FilterIcon from "components/FilterIcon.jsx";
import ArrayService from "services/utils/ArrayService";
import MissionStore from "stores/MissionStore";
import MissionActions from "actions/MissionActions";
import Locale from "locale/LocaleFactory";
import FilterService from "services/utils/FilterService";
import StringService from "services/utils/StringService";
import UserService from "services/UserService";
import LoadingIcon from "components/LoadingIcon";
import { PrinterOutlined } from "@ant-design/icons";

// Sorting Methods
function sortNameColumn(c1, c2) {
  return StringService.compareCaseInsensitive(c1.name, c2.name);
}

/**
 * The modal to select a mission.
 */
export default class SelectMissionModal extends React.Component {
  constructor(props) {
    super();
    this.state = {
      user: props.user,
      missions: props.missions,
      loading: false,

      filteredMissions: [],

      filterGlobal: "",
      filters: {
        name: [],
      },
    };
  }

  componentDidMount() {
    this.missionListener = MissionStore.addListener(this.receiveMissions);
    MissionActions.reloadByUser(this.props.user);
    this.updateFilters();
    // Here we set the default sorted column
    // Temporary solution waiting for AntD to propose a native way to do it.
    /*const column = this.table.findColumn("reference");
    this.table.toggleSortOrder("descend", column);*/
  }

  componentWillUnmount() {
    this.missionListener.remove();
  }

  receiveMissions = () => {
    const missions = MissionStore.getByUser(this.props.user);
    this.setState(
      {
        missions,
      },
      this.updateFilters
    );
  };

  // Filters
  updateFilters = () => {
    const { missions } = this.state;
    const filteredMissions = missions.filter(this.missionMatchFilters);
    this.setState({ filteredMissions });
  };

  missionMatchFilters = (e) => {
    const { filters } = this.state;
    if (!e) {
      return false;
    }
    return (
      FilterService.matchFilter(filters.name, e.name) &&
      this.matchGlobalSearch(e)
    );
  };

  searchGlobal = (e) => {
    this.setState({ filterGlobal: e.target.value.toLowerCase() });
    this.updateFilters();
  };

  matchGlobalSearch = (mission) => {
    const { filterGlobal } = this.state;
    return mission.name.toLowerCase().indexOf(filterGlobal) > -1;
  };

  handleFilterChange = (name, values) => {
    let prevState = this.state.filters;
    prevState[name] = values;
    this.setState({ filters: prevState });
    this.updateFilters();
  };

  getMissionNames = () =>
    ArrayService.unique(
      this.state.missions.filter((c) => !!c).map((c) => c.name)
    );

  render() {
    const { user, visible, onCancel } = this.props;
    return (
      <Modal
        title="Sélectionner une mission"
        open={user && visible}
        onCancel={onCancel}
        footer={null}
      >
        <div className="mission-list">{this.renderMissionTable()}</div>
      </Modal>
    );
  }

  renderMissionTable() {
    const { filters, filteredMissions } = this.state;

    const columns = [
      {
        title: Locale.trans("mission.name"),
        key: "name",
        sorter: sortNameColumn,
        filterIcon: <FilterIcon active={filters.name.length > 0} />,
        render: this.renderMissionNameCell,
        filterDropdown: (
          <TableColumnFilter
            name="name"
            selectedValues={filters.name}
            values={this.getMissionNames().map((r) => ({ text: r, value: r }))}
            onChange={this.handleFilterChange}
          />
        ),
      },
      {
        title: null,
        key: "actions",
        width: "200px",
        render: this.rendActionsCell,
      },
    ];

    return (
      <Table
        dataSource={filteredMissions}
        rowKey="id"
        columns={columns}
        locale={Locale.Table}
        ref={(r) => {
          this.table = r;
        }}
        loading={this.state.loading && { indicator: <LoadingIcon /> }}
      />
    );
  }

  renderMissionNameCell = (mission) => mission.name;

  rendActionsCell = (mission) => (
    <div className="actions-row">
      <Button
        type="primary"
        shape="circle"
        icon={<PrinterOutlined />}
        onClick={() => UserService.download(this.state.user.id, mission.id)}
      />
    </div>
  );
}
