import React from "react";

import { Table, Button, Tooltip } from "antd";
import TableColumnFilter from "components/TableColumnFilter.jsx";
import FilterIcon from "components/FilterIcon.jsx";
import CreateHostCompanyModal from "views/HostCompany/CreateHostCompanyModal.jsx";
import EditHostCompanyModal from "views/HostCompany/EditHostCompanyModal.jsx";
import LoadingIcon from "components/LoadingIcon.jsx";
import ToastActions from "actions/ToastActions";

import Locale, { Locale as LocaleComponent } from "locale/LocaleFactory";
import ArrayService from "services/utils/ArrayService";
import StringService from "services/utils/StringService";
import FilterService from "services/utils/FilterService";
import SecurityService from "services/SecurityService";
import Resource from "constants/Resource";
import Access from "constants/AccessLevel";

import HostCompanyActions from "actions/HostCompanyActions";
import HostCompanyStore from "stores/HostCompanyStore";
import { EditOutlined, EyeInvisibleOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";

// Sorting Methods
function sortNameColumn(c1, c2) {
  return StringService.compareCaseInsensitive(c1.name, c2.name);
}

/**
 * The list of the host companies.
 */
export default class HostCompanyList extends React.Component {
  constructor() {
    super();

    const companies = HostCompanyStore.getHostCompanies();
    this.state = {
      loading: !companies.length,
      companies,

      filteredCompanies: [],

      filterGlobal: "",
      filters: {
        name: []
      },

      createCompanyVisible: false,
      editCompanyVisible: false,
      companyToEdit: null,
      archivedVisible: false,
      selectedRowKeys: [],
    };
  }

  componentDidMount() {
    this.hostCompanyListener = HostCompanyStore.addListener(
      this.receiveCompanies
    );
    this.archivedHostCompanyListener = HostCompanyStore.addListener(this.receiveArchivedHostCompanies);
    this.loadHostCompanies();
    this.updateFilters();
    // Here we set the default sorted column
    // Temporary solution waiting for AntD to propose a native way to do it.
    /*const column = this.table.findColumn("reference");
    this.table.toggleSortOrder("descend", column);*/
  }

  componentWillUnmount() {
    this.hostCompanyListener.remove();
    this.archivedHostCompanyListener.remove();
  }

  loadHostCompanies = () => {
    this.setState({
      loading: true
    });
    HostCompanyActions.reload().then(() => {
      this.setState({
        loading: false,
        archivedVisible: false,
        selectedRowKeys : [],
      });
    });
    this.hostCompanyListener = HostCompanyStore.addListener(this.receiveCompanies);
  };

  loadArchivedHostCompanies = () => {
    this.setState({
      loading: true,
    });
    HostCompanyActions.reloadArchived().then(() => {
      this.setState({
        loading: false,
        archivedVisible: true,
        selectedRowKeys : [],
      });
    });
    this.archivedHostCompanyListener = HostCompanyStore.addListener(this.receiveArchivedHostCompanies);
  };

  receiveCompanies = () => {
    const companies = HostCompanyStore.getHostCompanies();
    this.setState(
      {
        companies
      },
      this.updateFilters
    );
  };

  receiveArchivedHostCompanies = () => {
    this.setState(
      {
        companies: HostCompanyStore.getArchivedHostCompanies()
      },
      this.updateFilters
    );
  };

  // Filters
  updateFilters = () => {
    const { companies } = this.state;
    const filteredCompanies = companies.filter(this.companyMatchFilters);
    this.setState({ filteredCompanies });
  };

  companyMatchFilters = (c) => {
    const { filters } = this.state;
    return (
      FilterService.matchFilter(filters.name, c.name) &&
      this.matchGlobalSearch(c)
    );
  };

  searchGlobal = e => {
    this.setState({filterGlobal: e.target.value.toLowerCase()});
    this.updateFilters();
  };

  matchGlobalSearch = (company) => {
    const { filterGlobal } = this.state;
    return company.name.toLowerCase().indexOf(filterGlobal) > -1;
  };

  handleFilterChange = (name, values) => {
    let prevState = this.state.filters;
    prevState[name] = values;
    this.setState({filters: prevState});
    this.updateFilters();
  };

  getCompanyNames() {
    return ArrayService.unique(this.state.companies.map(c => c.name));
  }

  showCreateCompanyModal = () => {
    this.setState({
      createCompanyVisible: true
    });
  };
  hideCreateCompanyModal = () => {
    this.setState({
      createCompanyVisible: false
    });
  };

  editCompany = (company) => {
    this.setState({
      editCompanyVisible: true,
      companyToEdit: company
    });
  };
  hideEditCompanyModal = () => {
    this.setState({
      editCompanyVisible: false,
      companyToEdit: null
    });
  };

  archiving = (hostCompany, toasVisible) => {
    const { archivedVisible } = this.state;
    if (!hostCompany) return;
    this.setState({
      loading: true
    });
    return new Promise((resolve, reject) => {
      HostCompanyActions.archiving(hostCompany.id).then(newHostCompany => {
        resolve(newHostCompany);
        this.setState({
          loading: false
        });
        if(toasVisible) {
          ToastActions.createToastSuccess(
            archivedVisible ? `Entreprise d'accueil "${hostCompany.name}" rétablit` : `Entreprise d'accueil "${hostCompany.name}" archivé` 
          );
        }
      }).catch(this.handleError);
    })
  };

  archiveSelected = () => {
    const { selectedRowKeys, archivedVisible } = this.state;
    const promises = selectedRowKeys.forEach(r => {
      this.setState({
        loading: true
      });
      const hostCompany = archivedVisible ? HostCompanyStore.getArchivedById(r) : HostCompanyStore.getById(r);
      this.archiving(hostCompany, false);
    });
    if(promises) {
      return Promise.all(promises).then(() => {
        ToastActions.createToastSuccess(
          archivedVisible ? "Entreprises d'accueil rétablis" : "Entreprises d'accueil archivés" 
        );
      });
    }
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  handleError = err => {
    this.setState({
      loading: false
    });
    try {
      const resp = JSON.parse(err.response);
      ToastActions.createToastError(resp.message);
    } catch (e) {
      ToastActions.createToastError("Une erreur est survenue");
    }
  };

  render() {
    const { companyToEdit, loading, filteredCompanies, archivedVisible, selectedRowKeys } = this.state;
    const hasSelected = selectedRowKeys.length > 0;
    return (
      <div className="host-company-list">
        {!loading &&
          SecurityService.isGranted(Resource.HOST_COMPANY, Access.CREATE) && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={this.showCreateCompanyModal}
            >
              <LocaleComponent transKey="hostCompany.add" />
            </Button>
        )}
        {!loading &&
          <Button
            type="danger"
            style={{color: !archivedVisible && "#f04134", marginBottom: "1%", float: "right"}}
            ghost={!archivedVisible}
            icon={archivedVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            onClick={() => {archivedVisible ? this.loadHostCompanies() : this.loadArchivedHostCompanies()} }
          >
            {archivedVisible ? Locale.trans("archive.unarchived") : Locale.trans("archive.archived")}  
          </Button>
        }
        {this.renderCompanyTable()}

        {!loading && (
          <div
            className="actions-row"
            style={{
              marginTop:
                !filteredCompanies || filteredCompanies.length === 0
                  ? "10px"
                  : "-50px"
            }}
          >
            {selectedRowKeys.length > 0 && (
              <Button icon={archivedVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />} type="danger" onClick={this.archiveSelected} disabled={!hasSelected} loading={loading}>
                {archivedVisible ? `Rétablir ${selectedRowKeys.length} entreprise(s) d'accueil` : `Archiver ${selectedRowKeys.length} entreprise(s) d'accueil`}
              </Button>
            )}
          </div>
        )}

        <CreateHostCompanyModal
          onCancel={this.hideCreateCompanyModal}
          visible={this.state.createCompanyVisible}
        />
        <EditHostCompanyModal
          hostCompany={companyToEdit}
          onCancel={this.hideEditCompanyModal}
          visible={this.state.editCompanyVisible}
        />
      </div>
    );
  }

  renderCompanyTable() {
    const { filters, filteredCompanies, selectedRowKeys, loading } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const columns = [
      {
        title: Locale.trans("hostCompany.name"),
        key: "name",
        sorter: sortNameColumn,
        filterIcon: <FilterIcon active={filters.name.length > 0} />,
        render: this.renderCompanyNameCell,
        filterDropdown: (
          <TableColumnFilter
            name="name"
            selectedValues={filters.name}
            values={this.getCompanyNames().map(r => ({ text: r, value: r }))}
            onChange={this.handleFilterChange}
          />
        )
      },
      {
        title: null,
        key: "actions",
        width: "50px",
        render: this.rendActionsCell
      }
    ];

    return (
      <Table
        rowSelection={rowSelection}
        dataSource={filteredCompanies}
        rowKey="id"
        columns={columns}
        locale={Locale.Table}
        ref={r => {
          this.table = r;
        }}
        loading={loading && { indicator: <LoadingIcon /> }}
      />
    );
  }

  renderCompanyNameCell = (company) => company.name;

  rendActionsCell = (company) => (
    <div className="actions-row">
        {this.state.archivedVisible ? (
          <Tooltip title={Locale.trans("archive.unarchive")}>
            <Button
              shape="circle"
              icon={<EyeOutlined />}
              onClick={() => this.archiving(company, true)}
            />
          </Tooltip>
        ) : (
          <Tooltip title={Locale.trans("archive.action")}>
            <Button
              shape="circle"
              icon={<EyeInvisibleOutlined />}
              onClick={() => this.archiving(company, true)}
            />
          </Tooltip>
        )}
      <Tooltip title={Locale.trans("edit")}>
        <Button
          type="primary"
          shape="circle"
          icon={<EditOutlined />}
          onClick={e => {
            this.editCompany(company);
            e.stopPropagation();
            e.preventDefault();
            return false;
          }}
        />
      </Tooltip>
    </div>
  );
}
