
import TokenContainer from 'services/TokenContainer';

/**
 * Abstract class that contains basic methods for Services (GET / POST / PATCH / DELETE).
 */
export default class ServiceBase {

    objectToQueryString(obj: { [key: string]: any }): string {
        return obj ? Object.keys(obj).filter(k => obj[k] !== null && obj[k] !== undefined).map(k => `${k}=${obj[k]}`).join('&') : "";
    }

    /**
     * Execute a custom request to the API.
     * @param {{url, method, data}} url => The url of the request | method => The method of the request. Default is GET. | data => The data of the request (optionnal)
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    async execute(options: any) {
        if (!options.url) {
            throw new Error('The URL is required');
        }

        const method = options.method || 'GET';

        const config = {
            method,
            crossOrigin: true,
            type: 'json',
            headers: {
                'X-Auth-Token': TokenContainer.get(),
                'Content-Type': 'application/json'
            },
            data: undefined,
            body: undefined as any,
        };
        if (options.data) {
            config.body = JSON.stringify(options.data);
        }

        const res = await fetch(options.url, config);

        if (!res.ok) {
            throw new Error(res.statusText);
        }

        return res.json();
    }
}
