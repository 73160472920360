import React from "react";

import { Table, Button, Tooltip } from "antd";
import TableColumnFilter from "components/TableColumnFilter.jsx";
import FilterIcon from "components/FilterIcon.jsx";
import LoadingIcon from "components/LoadingIcon.jsx";

import ShowReportModal from "views/Report/ShowReportModal.jsx";

import Locale from "locale/LocaleFactory";
import ArrayService from "services/utils/ArrayService";
import DateService from "services/utils/DateService";
import StringService from "services/utils/StringService";
import FilterService from "services/utils/FilterService";

import ReportActions from "actions/ReportActions";
import ReportStore from "stores/ReportStore";
import ReportService from "services/ReportService";
import { EyeInvisibleOutlined, EyeOutlined, PrinterOutlined } from "@ant-design/icons";

// Sorting Methods
function sortDateColumn(r1, r2) {
  return StringService.compareCaseInsensitive(r1.date, r2.date);
}

function sortMissionColumn(r1, r2) {
  return StringService.compareCaseInsensitive(r1.mission.name, r2.mission.name);
}

function sortAuthorColumn(r1, r2) {
  const s1 = `${r1.author.firstName} ${r1.author.lastName}`;
  const s2 = `${r2.author.firstName} ${r2.author.lastName}`;
  return StringService.compareCaseInsensitive(s1, s2);
}

/**
 * The list of the reports.
 */
export default class ReportList extends React.Component {
  constructor() {
    super();

    const reports = ReportStore.getReports();

    this.state = {
      loading: !reports.length,
      reports,

      filteredReports: [],

      filters: {
        date: [],
        mission: [],
        author: [],
      },

      reportToShow: null,
      archivedVisible: false,
    };
  }

  componentDidMount() {
    this.reportListener = ReportStore.addListener(this.receiveReports);
    this.loadReports();

    this.updateFilters();
    // Here we set the default sorted column
    // Temporary solution waiting for AntD to propose a native way to do it.
    /*const column = this.table.findColumn("date");
    this.table.toggleSortOrder("descend", column);*/
  }

  componentWillUnmount() {
    this.reportListener.remove();
  }

  loadReports = () => {
    this.setState({
      loading: true,
      archivedVisible: false,
    });
    ReportActions.reload().finally(() => {
      this.setState({
        loading: false,
      });
    });
  };

  loadArchivedReports = () => {
    this.setState({
      loading: true,
      archivedVisible: true,
    });
    ReportActions.reloadArchived().finally(() => {
      this.setState({
        loading: false,
      });
    });
  };

  receiveReports = () => {
    this.setState((prev) => {
      return {
        reports: prev.archivedVisible
          ? ReportStore.getArchivedReports()
          : ReportStore.getReports(),
      };
    }, this.updateFilters);
  };

  // Filters
  updateFilters = () => {
    const { reports } = this.state;
    const filteredReports = reports.filter(this.reportMatchFilters);
    this.setState({ filteredReports });
  };

  reportMatchFilters = (e) => {
    const { filters } = this.state;
    return (
      FilterService.matchFilter(filters.date, e.date) &&
      FilterService.matchFilter(filters.mission, e.mission.id) &&
      FilterService.matchFilter(filters.author, e.author.id)
    );
  };

  handleFilterChange = (name, values) => {
    let prevState = this.state.filters;
    prevState[name] = values;
    this.setState({ filters: prevState });
    this.updateFilters();
  };

  getReportDates = () =>
    ArrayService.unique(this.state.reports.map((c) => c.date));

  showReport = (report) => {
    this.setState({
      reportToShow: report,
    });
  };

  hideReport = () => {
    this.setState({
      reportToShow: null,
    });
  };

  render() {
    const { reportToShow, archivedVisible, loading } = this.state;
    return (
      <div className="report-list">
        {!loading && (
          <Button
            type="danger"
            style={{
              color: !archivedVisible && "#f04134",
              marginBottom: "1%",
              zIndex: 1,
              float: "right",
            }}
            ghost={!archivedVisible}
            icon={archivedVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            onClick={() => {
              archivedVisible ? this.loadReports() : this.loadArchivedReports();
            }}
          >
            {archivedVisible
              ? Locale.trans("archive.unarchived")
              : Locale.trans("archive.archived")}
          </Button>
        )}
        {this.renderReportTable()}
        {reportToShow &&
        <ShowReportModal
          report={reportToShow}
          onCancel={this.hideReport}
          visible={!!reportToShow}
        />}
      </div>
    );
  }

  renderReportTable() {
    const { filters, filteredReports, loading, reports } = this.state;
    const reportMissions = reports.map((r) => r.mission);
    const uniqueMissions = ArrayService.uniqueEntity(reportMissions);

    const columns = [
      {
        title: Locale.trans("report.date"),
        key: "date",
        sorter: sortDateColumn,
        defaultSortOrder: "descend",
        filterIcon: <FilterIcon active={filters.date.length > 0} />,
        render: this.renderReportDateCell,
        filterDropdown: (
          <TableColumnFilter
            name="date"
            selectedValues={filters.date}
            values={this.getReportDates().map((d) => ({
              text: DateService.formatApiToDisplay(d),
              value: d,
            }))}
            onChange={this.handleFilterChange}
          />
        ),
      },
      {
        title: Locale.trans("report.mission"),
        key: "mission",
        sorter: sortMissionColumn,
        filterIcon: <FilterIcon active={filters.mission.length > 0} />,
        render: this.renderReportMissionCell,
        filterDropdown: (
          <TableColumnFilter
            name="mission"
            selectedValues={filters.mission}
            values={uniqueMissions.map((m) => ({
              text: m.name,
              value: m.id,
            }))}
            onChange={this.handleFilterChange}
          />
        ),
      },
      {
        title: Locale.trans("report.author"),
        key: "author",
        sorter: sortAuthorColumn,
        filterIcon: <FilterIcon active={filters.author.length > 0} />,
        render: this.renderReportAuthorCell,
        filterDropdown: (
          <TableColumnFilter
            name="author"
            selectedValues={filters.author}
            values={ArrayService.uniqueEntity(
              filteredReports.map((r) => r.author)
            ).map((m) => ({
              text: `${m.firstName} ${m.lastName}`,
              value: m.id,
            }))}
            onChange={this.handleFilterChange}
          />
        ),
      },
      {
        title: null,
        key: "actions",
        width: "120px",
        render: this.rendActionsCell,
      },
    ];

    return (
      <Table
        dataSource={filteredReports}
        rowKey="id"
        columns={columns}
        locale={Locale.Table}
        ref={(r) => {
          this.table = r;
        }}
        loading={loading && { indicator: <LoadingIcon /> }}
      />
    );
  }

  renderReportDateCell = (report) =>
    DateService.formatApiToDisplay(report.date);

  renderReportMissionCell = (report) => {
    return report.mission.name;
  };

  renderReportAuthorCell = (report) => {
    return `${report.author.firstName} ${report.author.lastName}`;
  };

  rendActionsCell = (report) => (
    <div className="actions">
      {this.rendPrintCell(report)}
      <Tooltip title={Locale.trans("view")}>
        <Button
          type="primary"
          shape="circle"
          icon={<EyeOutlined />}
          onClick={(e) => {
            this.showReport(report);
            e.stopPropagation();
            e.preventDefault();
            return false;
          }}
        />
      </Tooltip>
    </div>
  );

  rendPrintCell = (report) => (
    <Tooltip title={Locale.trans("print")}>
      <Button
        type="primary"
        shape="circle"
        icon={<PrinterOutlined />}
        onClick={() => ReportService.download(report.id)}
      />
    </Tooltip>
  );
}
