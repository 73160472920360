import React, { useEffect } from 'react';
import { Form, Button } from 'antd';
import Locale from 'locale/LocaleFactory';
import FormItem from 'components/forms/FormItems';

interface IProps {
    skill?: any
    loading: boolean
    onSubmit: (establishment: any) => void
}
const SkillForm: React.FC<IProps> = ({ skill, loading, onSubmit }) => {
    const [form] = Form.useForm();

    const getEntityFromValues = (values: any) => {
        const entity: { [key: string]: any } = {};
        const keys = Object.keys(values);
        const ignoredKeys = [];

        for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            if (!k.endsWith('_ids') && k !== 'passwordConfirm') {
                if (k.endsWith('_id')) {
                    const tK = k.replace('_id', '');
                    entity[tK] = values[k];
                    ignoredKeys.push(tK);
                } else if (ignoredKeys.indexOf(k) === -1) {
                    entity[k] = values[k];
                }
            }
        }

        return entity;
    };
    const handleSubmit = (values: any) => {
        const establishment = getEntityFromValues(values);
        onSubmit(establishment);
    };

    const defaultValues = skill;
    useEffect(() => {
        form.setFieldsValue(defaultValues);
    }, [form, defaultValues]);

    return (
        <Form initialValues={defaultValues} onFinish={handleSubmit} layout="vertical" form={form}>
            <FormItem.Input
                id="name"
                required
                label={Locale.trans('skill.name')}
                form={form}
                hasFeedback
            />

            <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
            >
                {Locale.trans('save')}
            </Button>
        </Form>
    );
}

export default SkillForm;
