import ServiceBase from '../services/ServiceBase';
import BaseUrlConstants from '../constants/BaseUrlConstants';

const URL = `${BaseUrlConstants.BASE_URL}`;

class SubSkillService extends ServiceBase {
    constructor() {
        super(URL);
    }

    /**
     * Get a sub skill by unique identifier.
     * @param {number} subSkillid The identifier of the sub skill.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getById(subSkillid) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}sub-skills/${subSkillid}`,
            method: 'GET',
        });
    }

    /**
     * Get sub skills of a skill.
     * @param {number} skillId The identifier of the skill.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getBySkill(skillId) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}skills/${skillId}/sub-skills`,
            method: 'GET',
        });
    }

    /**
     * Get sub skills of a user has skill.
     * @param {number} userHasSkillId The identifier of the user has skill.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getByUserHasSkill(userHasSkillId) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}user-has-skills/${userHasSkillId}/sub-skills`,
            method: 'GET',
        });
    }

    /**
     * Get all unarchived sub skills.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getAll() {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}sub-skills?archived=false`,
            method: "GET"
        });
    }

    /**
     * Get all archived sub skills.
     *
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    getAllArchived() {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}sub-skills?archived=true`,
            method: "GET"
        });
    }

    /**
     * Post a new sub skill.
     * @param {Object} skill The skill to create.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    post(subSkill) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}sub-skills`,
            method: 'POST',
            data: subSkill,
        });
    }

    /**
     * Archive or unarchive an sub skill.
     * @param {number} subSkillId The identifier of the sub skill to archive or unarchive.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    archiving(subSkillId) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}sub-skills/${subSkillId}/archiving`,
            method: "POST"
        });
    }

    /**
     * Patch an existing resource. Only the properties that are set on the patch will be updated.
     * @param {number} id The identifier of the report.
     * @param {Object} patch The properties to update.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    patch(id, patch) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}sub-skills/${id}`,
            method: 'PATCH',
            data: patch,
        });
    }

    /**
     * Delete an existing host skill.
     * @param {number} id The identifier of the user.
     * @returns {Promise} A promise to handle the request ascynchronously.
     */
    remove(id) {
        return this.execute({
            url: `${BaseUrlConstants.BASE_URL}sub-skills/${id}`,
            method: 'DELETE',
        });
    }
}

const service = new SubSkillService();
export default service;