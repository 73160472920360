import React from 'react';
import FormItemBase from 'components/form-items/FormItemBase.jsx';
import { Form, Input } from 'antd';

export default class InputFormItem extends FormItemBase {
    render() {
        const { id, initialValue, readOnly } = this.props;
        // Form.Item extra props
        const { label, labelCol, wrapperCol, extra } = this.props;
        // Input props
        const {
            placeholder,
            autosize,
            disabled,
            maxLength,
            onChange,
        } = this.props;

        return (
            <Form.Item
                name={id}
                label={label}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                extra={extra}
                initialValue={initialValue}
                rules={this.getRules()}
            >
                <Input.TextArea
                    placeholder={placeholder}
                    autosize={autosize}
                    disabled={disabled}
                    readOnly={readOnly}
                    maxLength={maxLength}
                    onChange={onChange}
                    ref={n => (this.input = n)}
                />
            </Form.Item>
        );
    }
}
