import dispatcher from "../dispatchers/AppDispatcher";
import ActionsBase from "../actions/ActionsBase";

import ReportConstants from "../constants/ReportConstants";
import ReportService from "../services/ReportService";

import CacheManager from "../services/CacheManager";

const CACHE_DURATION = 30 * 1000;

class ReportActions extends ActionsBase {
    create = (report) => {
        const $this = this;
        return new Promise((resolve, reject) => {
                function handleSuccess(newReport) {
                    dispatcher.dispatch({
                        type: ReportConstants.RECEIVE_REPORT,
                        payload: {
                            report: newReport
                        }
                    });
                    resolve(newReport);
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                ReportService.post(report)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };

    edit = (reportId, report) => {
        const $this = this;
        return new Promise(
            (
                resolve,
                reject
            ) => {
                function handleSuccess(newReport) {
                    dispatcher.dispatch({
                        type: ReportConstants.RECEIVE_REPORT,
                        payload: {
                            report: newReport
                        }
                    });
                    resolve(newReport);
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                ReportService.patch(reportId, report)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };

    delete = (id) => {
        const $this = this;
        return new Promise(
            (resolve, reject) => {
                function handleSuccess() {
                    dispatcher.dispatch({
                        type: ReportConstants.DELETE_REPORT,
                        payload: {
                            id
                        }
                    });
                    resolve();
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                ReportService.remove(id)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };

    reload = () => {
        const $this = this;
        return new Promise((resolve, reject) => {
          function handleSuccess(reports) {
            dispatcher.dispatch({
              type: ReportConstants.RECEIVE_REPORTS,
              payload: {
                reports
              }
            });
            resolve();
          }
          function handleError(err) {
            $this.handleError(err, reject);
          }
          ReportService.getAll()
            .then(handleSuccess)
            .catch(handleError);
        });
    };

    reloadArchived = () => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(reports) {
                dispatcher.dispatch({
                    type: ReportConstants.RECEIVE_ARCHIVED_REPORTS,
                    payload: {
                        reports,
                    },
                });
                resolve();
            }
            function handleError(err) {
                $this.handleError(err, reject);
            }
            ReportService.getAllArchived()
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    changeCurrentReportId = (reportId) => {
        return new Promise(
            (resolve, reject) => {
                dispatcher.dispatch({
                    type: ReportConstants.CHANGE_CURRENT_REPORT,
                    payload: {
                        reportId
                    }
                });
                resolve();
            }
        );
    };

    reloadByUser = (user) => {
        const $this = this;
        return new Promise(
            (resolve, reject) => {
                if (
                    CacheManager.isCached(
                        "Report:reloadByUser",
                        user.id.toString(),
                        CACHE_DURATION
                    )
                ) {
                    resolve();
                    return;
                }

                function handleSuccess(reports) {
                    dispatcher.dispatch({
                        type: ReportConstants.RECEIVE_REPORTS_OF_USER,
                        payload: {
                            user,
                            reports
                        }
                    });
                    resolve();
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                ReportService.getByUser(user.id)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };

    reloadByMission = (mission) => {
        const $this = this;
        return new Promise(
            (resolve, reject) => {
                if (
                    CacheManager.isCached(
                        "Report:reloadByMission",
                        mission.id.toString(),
                        CACHE_DURATION
                    )
                ) {
                    resolve();
                    return;
                }

                function handleSuccess(reports) {
                    dispatcher.dispatch({
                        type: ReportConstants.RECEIVE_REPORTS_OF_MISSION,
                        payload: {
                            CACHE_DURATION,
                            reports
                        }
                    });
                    resolve();
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                ReportService.getByMission(mission.id)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };

    reloadById = (id) => {
        const $this = this;
        return new Promise(
            (resolve, reject) => {
                function handleSuccess(report) {
                    dispatcher.dispatch({
                        type: ReportConstants.RECEIVE_REPORT,
                        payload: {
                            report
                        }
                    });
                    resolve();
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                ReportService.getById(id)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };
}

const action = new ReportActions();
export default action;