import dispatcher from "../dispatchers/AppDispatcher";
import ActionsBase from "../actions/ActionsBase";

import OrganisationConstants from "../constants/OrganisationConstants";
import OrganisationService from "../services/OrganisationService";

import CacheManager from "../services/CacheManager";

const CACHE_DURATION = 30 * 1000;

class OrganisationActions extends ActionsBase {
  reload = () => {
    const $this = this;
    return new Promise((resolve, reject) => {
      if (CacheManager.isCached("Organisation:reload", "", CACHE_DURATION)) {
        resolve();
      }
      function handleSuccess(organisations) {
        dispatcher.dispatch({
          type: OrganisationConstants.RECEIVE_ORGANISATIONS,
          payload: {
            organisations
          }
        });
        resolve();
      }

      function handleError(err) {
        $this.handleError(err, reject);
      }

      OrganisationService.getAll()
        .then(handleSuccess)
        .catch(handleError);
    });
  };

  changeCurrentOrganisationId = organisationId => {
    return new Promise((resolve, reject) => {
      dispatcher.dispatch({
        type: OrganisationConstants.CHANGE_CURRENT_ORGANISATION,
        payload: {
          organisationId
        }
      });
      resolve();
    });
  };

  reloadById = id => {
    const $this = this;
    return new Promise((resolve, reject) => {
      function handleSuccess(organisation) {
        dispatcher.dispatch({
          type: OrganisationConstants.RECEIVE_ORGANISATION,
          payload: {
            organisation
          }
        });
        resolve();
      }

      function handleError(err) {
        $this.handleError(err, reject);
      }

      OrganisationService.getById(id)
        .then(handleSuccess)
        .catch(handleError);
    });
  };
}

const action = new OrganisationActions();
export default action;
