import React, { useEffect, useState } from "react";

import { Table, Button, Tooltip, Input } from "antd";
import TableColumnFilter from "components/TableColumnFilter.jsx";
import FilterIcon from "components/FilterIcon.jsx";
import CreateUserModal from "views/User/CreateUserModal.jsx";
import EditUserModal from "views/User/EditUserModal.jsx";
import SelectMissionModal from "views/Mission/SelectMissionModal.jsx";
import SelectUserDocumentsToPrintModal from "./SelectUserDocumentsToPrintModal.jsx";
import LoadingIcon from "components/LoadingIcon.jsx";
import ToastActions from "actions/ToastActions";

import Locale from "locale/LocaleFactory";
import UserService, { IUser } from "services/UserService";
import SecurityService from "services/SecurityService";
import Resource from "constants/Resource";
import Access from "constants/AccessLevel";

import UserActions from "actions/UserActions";

import MissionService from "services/MissionService";
import UserTypeService from "services/UserTypeService";
import { CopyOutlined, EditOutlined, EyeInvisibleOutlined, EyeOutlined, PlusOutlined, PrinterOutlined } from "@ant-design/icons";
import { Key } from "antd/lib/table/interface.js";

const UserList: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [concernedUser, setConcernedUser] = useState<IUser | null>(null);
  const [userToEdit, setUserToEdit] = useState<IUser | null>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [createUserVisible, setCreateUserVisible] = useState(false);
  const [editUserVisible, setEditUserVisible] = useState(false);
  const [selectMissionVisible, setSelectMissionVisible] = useState(false);
  const [selectUserDocumentsToPrintModalVisible, setSelectUserDocumentsToPrintModalVisible] = useState(false);

  const [tableParams, setTableParams] = useState({
    archived: false,
    name: null as string | null,
    types: [] as number[],

    __sort_by: 'name' as string | undefined,
    __sort_order: 'ascend' as string | undefined,
    __offset: 0,
    __limit: 10
  });

  const [missions, setMissions] = useState<any[]>([]);
  const [userTypes, setUserTypes] = useState<any[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [totalData, setTotalData] = useState<number>(0);

  const hasSelected = selectedRowKeys.length > 0;

  const onChangeParam = (newParams: any) => {
    setTableParams(prev => ({ ...prev, ...newParams }));
  }

  const editUser = (user: IUser): void => {
    setUserToEdit(user);
    setEditUserVisible(true);
  };

  const selectMission = (user: IUser): void => {
    setConcernedUser(user);
    setSelectMissionVisible(true);
  };

  const openSelectUserDocumentsModal = (user: IUser): void => {
    setConcernedUser(user);
    setSelectUserDocumentsToPrintModalVisible(true);
  };

  const hideSelectMissionModal = () => {
    setConcernedUser(null);
    setSelectMissionVisible(false);
  };

  const hideSelectUserDocumentsToPrintModal = () => {
    setConcernedUser(null);
    setSelectUserDocumentsToPrintModalVisible(false);
  }

  const isIntern = (user: IUser): boolean => {
    if (!user.types || user.types.length === 0) return true;
    for (let type of user.types) {
      if (
        type.title.toLowerCase() ===
        Locale.trans("userType.intern").toLowerCase()
      ) {
        return true;
      }
    }
    return false;
  }

  const onPrint = (user: IUser): void => {
    if (!user.missions) {
      return;
    }
    if (user.missions.length > 1) {
      selectMission(user);
    } else if (user.missions.length === 1) {
      let missionId = user.missions[0].mission.id;
      UserService.download(user.id, missionId);
    }
  }


  const handleError = (err: any) => {
    try {
      const resp = JSON.parse(err.response);
      ToastActions.createToastError(resp.message);
    } catch (e) {
      ToastActions.createToastError("Une erreur est survenue");
    }
  };

  const archiving = async (user: IUser, toasVisible: boolean = false) => {
    if (!user) {
      return;
    }

    setLoading(true);
    try {
      await UserActions.archiving(user.id);
      if (toasVisible) {
        ToastActions.createToastSuccess(
          tableParams.archived
            ? `Utilisateur "${user.firstName} ${user.lastName}" rétablit`
            : `Utilisateur "${user.firstName} ${user.lastName}" archivé`
        );
      }
    } catch (e) {
      handleError(e);
    }

    setLoading(false);
  };

  const archiveSelected = () => {
    const promises = selectedRowKeys.map((r) => {
      const user = users.find(u => u.id === r);
      return user && archiving(user, false);
    });
    if (promises) {
      return Promise.all(promises).then(() => {
        ToastActions.createToastSuccess(
          tableParams.archived ? "Utilisateurs rétablis" : "Utilisateurs archivés"
        );
      });
    }
  };

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      const missions = await MissionService.getAll({archived: false});
      if (isMounted) {
        setMissions(missions);
      }
    }
    fetchData();
    return () => {
      isMounted = false;
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      const userTypes = await UserTypeService.getAll();
      if (isMounted) {
        setUserTypes(userTypes);
      }
    }
    fetchData();
    return () => {
      isMounted = false;
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      setLoading(true);
      try {
        // TODO
        const res = await UserService.getAll(tableParams);
        if (isMounted) {
          setUsers(res.data);
          setTotalData(res.total);
        }
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    }
    fetchData();
    return () => {
      isMounted = false;
    }
  }, [tableParams]);

  return (
    <div className="user-list">
      {!loading && SecurityService.isGranted(Resource.USER, Access.CREATE) && (
        <Button type="primary" icon={<PlusOutlined />} onClick={() => setCreateUserVisible(true)}>
          Ajouter un utilisateur
        </Button>
      )}
      {!loading && (
        <Button
          danger
          style={{
            color: !tableParams.archived ? "#f04134" : undefined,
            marginBottom: "1%",
            float: "right",
          }}
          ghost={!tableParams.archived}
          icon={tableParams.archived ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          onClick={() => onChangeParam({ archived: !tableParams.archived })}
        >
          {tableParams.archived
            ? Locale.trans("archive.unarchived")
            : Locale.trans("archive.archived")}
        </Button>
      )}

      <Table
        rowSelection={{
          selectedRowKeys,
          onChange: setSelectedRowKeys,
        }}
        dataSource={users}
        rowKey="id"
        pagination={{
          current: (tableParams.__offset / tableParams.__limit) + 1,
          pageSize: tableParams.__limit,
          total: totalData
        }}
        columns={[
          {
            title: Locale.trans("user.name"),
            key: "name",
            render: (_, user) => `${user.firstName} ${user.lastName}`,
            sorter: true,
            defaultSortOrder: 'ascend',
            filterIcon: <FilterIcon active={tableParams.name && tableParams.name.length > 0} />,
            filterDropdown: (
              <Input placeholder="Rechercher" value={tableParams.name ?? ""} onChange={e => onChangeParam({ name: e.target.value, __offset: 0 })} />
            ),
          },
          {
            title: Locale.trans("user.type"),
            key: "type",
            render: (_, user) => user.types?.map((type) => type.title).join(" - "),
            filterIcon: <FilterIcon active={tableParams.types.length > 0} />,
            filterDropdown: (
              <TableColumnFilter
                name="type"
                selectedValues={tableParams.types}
                values={userTypes.map((r) => ({
                  text: r.title,
                  value: r.id,
                }))}
                onChange={(_: any, values: any) => onChangeParam({ types: values, __offset: 0 })}
              />
            ),
          },
          {
            title: null,
            key: "actions",
            width: "50px",
            render: (_, user) => <div className="actions-row">
              {tableParams.archived ? (
                <Tooltip title={Locale.trans("archive.unarchive")}>
                  <Button
                    shape="circle"
                    icon={<EyeOutlined />}
                    onClick={() => archiving(user, true)}
                  />
                </Tooltip>
              ) : (
                <Tooltip title={Locale.trans("archive.action")}>
                  <Button
                    shape="circle"
                    icon={<EyeInvisibleOutlined />}
                    onClick={() => archiving(user, true)}
                  />
                </Tooltip>
              )}
              <Tooltip title={Locale.trans("edit")}>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={(e) => {
                    editUser(user);
                    e.stopPropagation();
                    e.preventDefault();
                    return false;
                  }}
                />
              </Tooltip>
              {isIntern(user) && user.missions && user.missions.length > 0 && (
                <>
                  <Tooltip title={Locale.trans("certificate")}>
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<PrinterOutlined />}
                      onClick={() => onPrint(user)}
                    />
                  </Tooltip>
                  <Tooltip title={Locale.trans("documents")}>
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<CopyOutlined />}
                      onClick={() => openSelectUserDocumentsModal(user)}
                    />
                  </Tooltip>
                </>
              )}
            </div>,
          },
        ]}
        locale={Locale.Table}
        loading={loading && { indicator: <LoadingIcon /> }}
        onChange={(pagination, filters, sorter) => {
          setTableParams(prev => ({
            ...prev,
            __sort_by: (Array.isArray(sorter) ? sorter[0] : sorter).column?.key?.toString(),
            __sort_order: (Array.isArray(sorter) ? sorter[0] : sorter).order?.toString(),
            __limit: pagination.pageSize ?? 0,
            __offset: ((pagination.current ?? 0) - 1) * (pagination.pageSize ?? 0)
          }));

          // `dataSource` is useless since `pageSize` changed
          if (pagination.pageSize !== tableParams.__limit) {
            setUsers([]);
          }
        }}
      />

      {!loading && (
        <div
          className="actions-row"
          style={{ marginTop: !users || users.length === 0 ? "10px" : "-50px" }}
        >
          {selectedRowKeys.length > 0 && (
            <Button
              icon={tableParams.archived ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              danger
              onClick={archiveSelected}
              disabled={!hasSelected}
              loading={loading}
            >
              {tableParams.archived
                ? `Rétablir ${selectedRowKeys.length} utilisateur(s)`
                : `Archiver ${selectedRowKeys.length} utilisateur(s)`}
            </Button>
          )}
        </div>
      )}

      <CreateUserModal
        onCancel={() => setCreateUserVisible(false)}
        visible={createUserVisible}
      />
      <EditUserModal
        user={userToEdit}
        onCancel={() => {
          setEditUserVisible(false)
          setUserToEdit(null);
        }}
        visible={editUserVisible}
      />
      {concernedUser && (
        <>
          <SelectMissionModal
            user={concernedUser}
            missions={missions}
            onCancel={hideSelectMissionModal}
            visible={selectMissionVisible}
          />
          <SelectUserDocumentsToPrintModal
            user={concernedUser}
            onCancel={hideSelectUserDocumentsToPrintModal}
            visible={selectUserDocumentsToPrintModalVisible}
          />
        </>
      )}
    </div>
  );
}

export default UserList;