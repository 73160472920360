import React from "react";

import { Table, Button, Tooltip } from "antd";
import TableColumnFilter from "components/TableColumnFilter.jsx";
import FilterIcon from "components/FilterIcon.jsx";
import CreateSubSkillModal from "views/SubSkill/CreateSubSkillModal.jsx";
import EditSubSkillModal from "views/SubSkill/EditSubSkillModal.jsx";
import LoadingIcon from "components/LoadingIcon.jsx";
import ToastActions from "actions/ToastActions";

import Locale, { Locale as LocaleComponent } from "locale/LocaleFactory";
import ArrayService from "services/utils/ArrayService";
import StringService from "services/utils/StringService";
import FilterService from "services/utils/FilterService";
import SecurityService from "services/SecurityService";
import Resource from "constants/Resource";
import Access from "constants/AccessLevel";

import SkillActions from "actions/SkillActions";
import SkillStore from "stores/SkillStore";

import SubSkillActions from "actions/SubSkillActions";
import SubSkillStore from "stores/SubSkillStore";
import { EditOutlined, EyeInvisibleOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";

// Sorting Methods
function sortNameColumn(c1, c2) {
  return StringService.compareCaseInsensitive(c1.name, c2.name);
}
function sortSkillColumn(c1, c2) {
  const skill1 = c1.skill && SkillStore.getById(c1.skill.id);
  const skill2 = c2.skill && SkillStore.getById(c2.skill.id);
  const s1 = skill1 && skill1.name;
  const s2 = skill2 && skill2.name;
  return StringService.compareCaseInsensitive(s1, s2);
}

/**
 * The list of the host subskills.
 */
export default class SubSkillList extends React.Component {
  subSkillListener;
  archivedSubSkillListener;
  table;

  constructor() {
    super();

    const subSkills = SubSkillStore.getSubSkills();

    this.state = {
      loading: !subSkills.length,
      subSkills,

      filteredSubSkills: [],

      filterGlobal: "",
      filters: {
        name: [],
        skill: []
      },

      createSubSkillVisible: false,
      editSubSkillVisible: false,
      subSkillToEdit: null,
      archivedVisible: false,
      selectedRowKeys: [],
    };
  }

  componentDidMount() {
    this.skillListener = SkillStore.addListener(this.receiveSkills);
    this.subSkillListener = SubSkillStore.addListener(this.receiveSubSkills);
    this.archivedSubSkillListener = SubSkillStore.addListener(this.receiveArchivedSubSkills);
    this.loadSubSkills();
    SkillActions.reload();

    this.updateFilters();
    // Here we set the default sorted column
    // Temporary solution waiting for AntD to propose a native way to do it.
    /*const column = this.table.findColumn("reference");
    this.table.toggleSortOrder("descend", column);*/
  }
  componentWillUnmount() {
    this.subSkillListener.remove();
    this.archivedSubSkillListener.remove();
  }

  loadSubSkills = () => {
    this.setState({
      loading: true
    });
    SubSkillActions.reload().then(() => {
      this.setState({
        loading: false,
        archivedVisible: false,
        selectedRowKeys : [],
      });
    });
    this.subSkillListener = SubSkillStore.addListener(this.receiveSubSkills);
  };

  loadArchivedSubSkills = () => {
    this.setState({
      loading: true,
    });
    SubSkillActions.reloadArchived().then(() => {
      this.setState({
        loading: false,
        archivedVisible: true,
        selectedRowKeys : [],
      });
    });
    this.archivedSubSkillListener = SubSkillStore.addListener(this.receiveArchivedSubSkills);
  };

  receiveSkills = () => {
    this.forceUpdate();
  };

  receiveSubSkills = () => {
    const subSkills = SubSkillStore.getSubSkills();
    this.setState(
      {
        subSkills
      },
      this.updateFilters
    );
  };

  receiveArchivedSubSkills = () => {
    this.setState(
      {
        subSkills: SubSkillStore.getArchivedSubSkills()
      },
      this.updateFilters
    );
  };

  // Filters
  updateFilters = () => {
    const { subSkills } = this.state;
    const filteredSubSkills = subSkills.filter(this.subSkillMatchFilters);
    this.setState({ filteredSubSkills });
  };

  subSkillMatchFilters = (s) => {
    const { filters } = this.state;
    return (
      FilterService.matchFilter(filters.name, s.name) &&
      FilterService.matchFilter(filters.skill, s.skill && s.skill.id) &&
      this.matchGlobalSearch(s)
    );
  };

  searchGlobal = e => {
    this.setState({ filterGlobal: e.target.value.toLowerCase() });
    this.updateFilters();
  };

  matchGlobalSearch = (subSkill) => {
    const { filterGlobal } = this.state;
    return subSkill.name.toLowerCase().indexOf(filterGlobal) > -1;
  };

  handleFilterChange = (name, values) => {
    let prevState = this.state.filters;
    prevState[name] = values;
    this.setState({filters: prevState});
    this.updateFilters();
  };

  getSubSkillNames = () =>
    ArrayService.unique(this.state.subSkills.map(s => s.name));

  getSkills = () => SkillStore.getSkills();

  showCreateSubSkillModal = () => {
    this.setState({
      createSubSkillVisible: true
    });
  };
  hideCreateSubSkillModal = () => {
    this.setState({
      createSubSkillVisible: false
    });
  };

  editSubSkill = (subSkill) => {
    this.setState({
      editSubSkillVisible: true,
      subSkillToEdit: subSkill
    });
  };
  hideEditSubSkillModal = () => {
    this.setState({
      editSubSkillVisible: false,
      subSkillToEdit: null
    });
  };

  archiving = (subSkill, toasVisible) => {
    const { archivedVisible } = this.state;
    if (!subSkill) return;
    this.setState({
      loading: true
    });
    return new Promise((resolve, reject) => {
      SubSkillActions.archiving(subSkill.id).then(newSubSkill => {
        resolve(newSubSkill);
        this.setState({
          loading: false
        });
        if(toasVisible) {
          ToastActions.createToastSuccess(
            archivedVisible ? `Sous-compétence "${subSkill.name}" rétablit` : `Sous-compétence "${subSkill.name}" archivé` 
          );
        }
      }).catch(this.handleError);
    })
  };

  archiveSelected = () => {
    const { selectedRowKeys, archivedVisible } = this.state;
    const promises = selectedRowKeys.forEach(r => {
      this.setState({
        loading: true
      });
      const subSkill = archivedVisible ? SubSkillStore.getArchivedById(r) : SubSkillStore.getById(r);
      this.archiving(subSkill, false);
    });
    if(promises) {
      return Promise.all(promises).then(() => {
        ToastActions.createToastSuccess(
          archivedVisible ? "Sous-compétences rétablis" : "Sous-compétences archivés" 
        );
      });
    }
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  handleError = err => {
    this.setState({
      loading: false
    });
    try {
      const resp = JSON.parse(err.response);
      ToastActions.createToastError(resp.message);
    } catch (e) {
      ToastActions.createToastError("Une erreur est survenue");
    }
  };

  render() {
    const { subSkillToEdit, loading, filteredSubSkills, archivedVisible, selectedRowKeys } = this.state;
    const hasSelected = selectedRowKeys.length > 0;
    return (
      <div className="subskill-list">
        {!loading &&
          SecurityService.isGranted(Resource.SKILL, Access.CREATE) && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={this.showCreateSubSkillModal}
            >
              <LocaleComponent transKey="subSkill.add" />
            </Button>
        )}
        {!loading &&
          <Button
            type="danger"
            style={{color: !archivedVisible && "#f04134", marginBottom: "1%", float: "right"}}
            ghost={!archivedVisible}
            icon={archivedVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            onClick={() => {archivedVisible ? this.loadSubSkills() : this.loadArchivedSubSkills()} }
          >
              {archivedVisible ? Locale.trans("archive.unarchived") : Locale.trans("archive.archived")}  
          </Button>
        }
        {this.renderSubSkillTable()}

        {!loading && (
          <div
            className="actions-row"
            style={{
              marginTop:
                !filteredSubSkills || filteredSubSkills.length === 0
                  ? "10px"
                  : "-50px"
            }}
          >
            {selectedRowKeys.length > 0 && (
              <Button icon={archivedVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />} type="danger" onClick={this.archiveSelected} disabled={!hasSelected} loading={loading}>
                {archivedVisible ? `Rétablir ${selectedRowKeys.length} sous-compétence(s)` : `Archiver ${selectedRowKeys.length} sous-compétence(s)`}
              </Button>
            )}
          </div>
        )}

        <CreateSubSkillModal
          onCancel={this.hideCreateSubSkillModal}
          visible={this.state.createSubSkillVisible}
        />
        <EditSubSkillModal
          subSkill={subSkillToEdit}
          onCancel={this.hideEditSubSkillModal}
          visible={this.state.editSubSkillVisible}
        />
      </div>
    );
  }

  renderSubSkillTable() {
    const { filters, filteredSubSkills, selectedRowKeys, loading } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const columns = [
      {
        title: Locale.trans("subSkill.name"),
        key: "name",
        sorter: sortNameColumn,
        filterIcon: <FilterIcon active={filters.name.length > 0} />,
        render: this.renderSubSkillNameCell,
        filterDropdown: (
          <TableColumnFilter
            name="name"
            selectedValues={filters.name}
            values={this.getSubSkillNames().map(r => ({ text: r, value: r }))}
            onChange={this.handleFilterChange}
          />
        )
      },
      {
        title: Locale.trans("subSkill.skill"),
        key: "skill",
        sorter: sortSkillColumn,
        filterIcon: <FilterIcon active={filters.skill.length > 0} />,
        render: this.renderSkillCell,
        filterDropdown: (
          <TableColumnFilter
            name="skill"
            selectedValues={filters.skill}
            values={this.getSkills().map(s => ({ text: s.name, value: s.id }))}
            onChange={this.handleFilterChange}
          />
        )
      },
      {
        title: null,
        key: "actions",
        width: "50px",
        render: this.rendActionsCell
      }
    ];

    return (
      <Table
        rowSelection={rowSelection}
        dataSource={filteredSubSkills}
        rowKey="id"
        columns={columns}
        locale={Locale.Table}
        ref={r => {
          this.table = r;
        }}
        loading={loading && { indicator: <LoadingIcon /> }}
      />
    );
  }

  renderSkillCell = (subSkill) => {
    if (!subSkill.skill) {
      return null;
    }
    const skill = SkillStore.getById(subSkill.skill.id);
    if (!skill) {
      return null;
    }
    return skill.name;
  };

  renderSubSkillNameCell = (subSkill) => subSkill.name;

  rendActionsCell = (subSkill) => (
    <React.Fragment>
      <div className="actions-row">
        {this.state.archivedVisible ? (
            <Tooltip title={Locale.trans("archive.unarchive")}>
              <Button
                shape="circle"
                icon={<EyeOutlined />}
                onClick={() => this.archiving(subSkill, true)}
              />
            </Tooltip>
          ) : (
            <Tooltip title={Locale.trans("archive.action")}>
              <Button
                shape="circle"
                icon={<EyeInvisibleOutlined />}
                onClick={() => this.archiving(subSkill, true)}
              />
            </Tooltip>
          )}
        <Tooltip title={Locale.trans("edit")}>
          <Button
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            onClick={e => {
              this.editSubSkill(subSkill);
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
          />
        </Tooltip>
      </div>
    </React.Fragment>
  );
}
