import React from 'react';

import LoginStore from 'stores/LoginStore';
import { Navigate } from 'react-router-dom';

export default class PrivateRoute extends React.Component {
    componentDidMount() {
        this.loginListener = LoginStore.addListener(this.receiveLogin);
    }
    componentWillUnmount() {
        this.loginListener.remove();
    }
    receiveLogin = () => {
        this.forceUpdate();
    };
    render() {
        const { element, location } = this.props;
        return (
            LoginStore.isLoggedIn() ? (
                element
            ) : (
                <Navigate
                    to="/login"
                    state={{ from: location }}
                    replace
                />
            )
        );
    }
}
