import ServiceBase from "../services/ServiceBase";
import BaseUrlConstants from "../constants/BaseUrlConstants";

const URL = `${BaseUrlConstants.BASE_URL}`;

class DocumentsService extends ServiceBase {
  constructor() {
    super(URL);
  }

  /**
   * Download few documents
   * @param {array} reports The ID of the report.
   * @param {array} incidents The ID of the incident.
   * @param {array} reviews The ID of the reviews.
   */
  download(reports, incidents, reviews, certificatesOfCompetence, skills) {
    return this.execute({
        url: `${BaseUrlConstants.BASE_URL}documents/download`,
        method: "POST",
        data: {
          reports,
          incidents,
          reviews,
          certificatesOfCompetence,
          skills
        }
    });
  }
}

const service = new DocumentsService();
export default service;