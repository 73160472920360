import dispatcher from "../dispatchers/AppDispatcher";
import ActionsBase from "../actions/ActionsBase";

import JobConstants from "../constants/JobConstants";
import JobService from "../services/JobService";

class JobActions extends ActionsBase {
  create = job => {
    const $this = this;
    return new Promise((resolve, reject) => {
      function handleSuccess(newJob) {
        dispatcher.dispatch({
          type: JobConstants.RECEIVE_JOB,
          payload: {
            job: newJob
          }
        });
        resolve(newJob);
      }

      function handleError(err) {
        $this.handleError(err, reject);
      }

      JobService.post(job)
        .then(handleSuccess)
        .catch(handleError);
    });
  };

  edit = (jobId, job) => {
    const $this = this;
    return new Promise((resolve, reject) => {
      function handleSuccess(newJob) {
        dispatcher.dispatch({
          type: JobConstants.RECEIVE_JOB,
          payload: {
            job: newJob
          }
        });
        resolve(newJob);
      }

      function handleError(err) {
        $this.handleError(err, reject);
      }

      JobService.patch(jobId, job)
        .then(handleSuccess)
        .catch(handleError);
    });
  };

  archiving = (jobId) => {
    const $this = this;
    return new Promise((resolve, reject) => {
        function handleSuccess(newJob) {
            dispatcher.dispatch({
                type: JobConstants.RECEIVE_ARCHIVED_JOB,
                payload: {
                    job: newJob,
                },
            });
            resolve();
        }
        function handleError(err) {
            $this.handleError(err, reject);
        }
        JobService.archiving(jobId)
            .then(handleSuccess)
            .catch(handleError);
    });
  };

  delete = id => {
    const $this = this;
    return new Promise((resolve, reject) => {
      function handleSuccess() {
        dispatcher.dispatch({
          type: JobConstants.DELETE_JOB,
          payload: {
            id
          }
        });
        resolve();
      }

      function handleError(err) {
        $this.handleError(err, reject);
      }

      JobService.remove(id)
        .then(handleSuccess)
        .catch(handleError);
    });
  };

  reload = () => {
    const $this = this;
    return new Promise((resolve, reject) => {
      function handleSuccess(jobs) {
        dispatcher.dispatch({
          type: JobConstants.RECEIVE_JOBS,
          payload: {
            jobs
          }
        });
        resolve();
      }

      function handleError(err) {
        $this.handleError(err, reject);
      }

      JobService.getAll()
        .then(handleSuccess)
        .catch(handleError);
    });
  };

  reloadArchived = () => {
    const $this = this;
    return new Promise((resolve, reject) => {
        function handleSuccess(jobs) {
            dispatcher.dispatch({
                type: JobConstants.RECEIVE_ARCHIVED_JOBS,
                payload: {
                    jobs,
                },
            });
            resolve();
        }
        function handleError(err) {
            $this.handleError(err, reject);
        }
        JobService.getAllArchived()
            .then(handleSuccess)
            .catch(handleError);
    });
  };

  reloadById = id => {
    const $this = this;
    return new Promise((resolve, reject) => {
      function handleSuccess(job) {
        dispatcher.dispatch({
          type: JobConstants.RECEIVE_JOB,
          payload: {
            job
          }
        });
        resolve();
      }

      function handleError(err) {
        $this.handleError(err, reject);
      }

      JobService.getById(id)
        .then(handleSuccess)
        .catch(handleError);
    });
  };
}

const action = new JobActions();
export default action;
