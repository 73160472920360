
import dispatcher from '../dispatchers/AppDispatcher';
import ActionsBase from './ActionsBase';

import UserTypeConstants from '../constants/UserTypeConstants';
import UserTypeService from '../services/UserTypeService';

import CacheManager from '../services/CacheManager';

const CACHE_DURATION = 30 * 1000;

class UserTypeActions extends ActionsBase {
  reload = () => {
      const $this = this;
      return new Promise((resolve, reject) => {
              if (CacheManager.isCached('UserType:reload', null, CACHE_DURATION)) {
                  resolve();
                  return;
              }

              function handleSuccess(userTypes) {
                  dispatcher.dispatch({
                      type: UserTypeConstants.RECEIVE_USER_TYPES,
                      payload: {
                          userTypes,
                      },
                  });
                  resolve();
              }

              function handleError(err) {
                  $this.handleError(err, reject);
              }

              UserTypeService.getAll()
                  .then(handleSuccess)
                  .catch(handleError);
          },);
  };

  edit = (id, patch) => {
    const $this = this;
    return new Promise((resolve, reject) => {
      function handleSuccess(newUserType) {
        dispatcher.dispatch({
          type: UserTypeConstants.RECEIVE_USER_TYPE,
          payload: {
            userType: newUserType
          }
        });
        resolve(newUserType);
      }

      function handleError(err) {
        $this.handleError(err, reject);
      }

      UserTypeService.patch(id, patch)
        .then(handleSuccess)
        .catch(handleError);
    });
  };

  /*
    reloadById = (id) => {
        const $this = this;
        return new Promise(
            (resolve, reject) => {
                function handleSuccess(userType) {
                    dispatcher.dispatch({
                        type: UserTypeConstants.RECEIVE_USER_TYPE,
                        payload: {
                            userType
                        }
                    });
                    resolve();
                }

                function handleError(err) {
                    $this.handleError(err, reject);
                }

                UserTypeService.getById(id)
                    .then(handleSuccess)
                    .catch(handleError);
            }
        );
    };
    // */
}

const action = new UserTypeActions();
export default action;