
import EntityStoreBase from '../stores/EntityStoreBase';
import OrganisationConstants from '../constants/OrganisationConstants';

class OrganisationStore extends EntityStoreBase {
    getInitialState() {
        return {
            currentOrganisationId: null,
            entities: [],
        };
    }

    reduce(state, action) {
        const { type, payload } = action;
        let newState = Object.assign({}, state);

        switch (type) {
        case OrganisationConstants.RECEIVE_ORGANISATION:
            newState = this._receiveEntity(state, payload.organisation);
            break;
        case OrganisationConstants.RECEIVE_ORGANISATIONS:
            newState = this._receiveMany(state, payload.organisations);

            break;
        case OrganisationConstants.CHANGE_CURRENT_ORGANISATION:
            newState.currentOrganisationId = payload.organisationId;
            break;
        case 'clearAll':
            newState = this.getInitialState();
            break;
        default:
            newState = state;
        }
        return newState;
    }

  _receiveMany = (state, organisations) => {
      const newState = Object.assign({}, state);
      // We remove all the entities that have the same ID as the new organisations
      newState.entities = state.entities.filter(e => organisations.findIndex(a => e.id === a.id) === -1,);
      newState.entities.push(...organisations);
      return newState;
  };

  // /// Public Methods  /////
  /**
   * Get a organisation by ID.
   * @param {number} id The identifier.
   * @return {?Organisations} The organisation, or NULL if no entity is found.
   */
  getById = id => this.getState().entities.find(e => e.id === id);

  /**
   * Get all the organisations.
   */
  getAll = () => this.getState().entities;

  /**
   * Get the identifier of the current organisation.
   * @return {[type]} [description]
   */
  getCurrentOrganisationId = () =>
      this.getState().currentOrganisationId ||
    (this.getState().entities.length ? this.getState().entities[0].id : null);

  /**
   * Get the identifier of the current organisation.
   * @return {[type]} [description]
   */
  getCurrentOrganisation = () => this.getById(this.getCurrentOrganisationId());
}

const store = new OrganisationStore();
export default store;
