import React from "react";
import { Modal } from "antd";

import MissionForm from "components/forms/MissionForm";

import ToastActions from "actions/ToastActions";

import MissionActions from "actions/MissionActions";

import UserTypeActions from "actions/UserTypeActions";
import UserTypeStore from "stores/UserTypeStore";

import SkillActions from "actions/SkillActions";
import SkillStore from "stores/SkillStore";

import JobActions from "actions/JobActions";
import JobStore from "stores/JobStore";

import PartnerActions from "actions/PartnerActions";
import PartnerStore from "stores/PartnerStore";

import EstablishmentActions from "actions/EstablishmentActions";
import EstablishmentStore from "stores/EstablishmentStore";

import HostCompanyActions from "actions/HostCompanyActions";
import HostCompanyStore from "stores/HostCompanyStore";
import Locale, { Locale as LocaleComponent } from "locale/LocaleFactory";

/**
 * The modal to create a new mission.
 */
export default class CreateMissionModal extends React.Component {
  jobListener;
  partnerListener;
  skillListener;
  establishmentListener;
  hostCompaniListener;

  constructor() {
    super();
    this.state = {
      loading: false,
      fields: {},
      jobs: JobStore.getJobs(),
      partners: PartnerStore.getPartners(),
      userTypes: UserTypeStore.getAll(),
      skills: SkillStore.getSkills(),
      establishments: EstablishmentStore.getEstablishments(),
      hostCompanies: HostCompanyStore.getHostCompanies(),
    };
  }

  componentDidMount() {
    this.jobListener = JobStore.addListener(this.receiveJobs);
    this.partnerListener = PartnerStore.addListener(this.receivePartners);
    this.userTypeListener = UserTypeStore.addListener(this.receiveUserTypes);
    this.skillListener = SkillStore.addListener(this.receiveSkills);
    this.establishmentListener = EstablishmentStore.addListener(
      this.receiveEstablishments
    );
    this.hostCompaniListener = HostCompanyStore.addListener(
      this.receiveHostCompanies
    );
    JobActions.reload();
    PartnerActions.reload();
    UserTypeActions.reload();
    SkillActions.reload();
    EstablishmentActions.reload();
    HostCompanyActions.reload();
  }

  componentWillUnmount() {
    this.jobListener.remove();
    this.partnerListener.remove();
    this.userTypeListener.remove();
    this.skillListener.remove();
    this.establishmentListener.remove();
    this.hostCompaniListener.remove();
  }

  receiveJobs = () => {
    this.setState({
      jobs: JobStore.getJobs(),
    });
  };

  receivePartners = () => {
    this.setState({
      partners: PartnerStore.getPartners(),
    });
  };

  receiveUserTypes = () => {
    this.setState({
      userTypes: UserTypeStore.getAll(),
    });
  };

  receiveSkills = () => {
    this.setState({
      skills: SkillStore.getSkills(),
    });
  };

  receiveEstablishments = () => {
    this.setState({
      establishments: EstablishmentStore.getEstablishments(),
    });
  };

  receiveHostCompanies = () => {
    this.setState({
      hostCompanies: HostCompanyStore.getHostCompanies(),
    });
  };

  handleFormChange = (changedFields) => {
    this.setState(({ fields }) => ({
      fields: { ...fields, ...changedFields },
    }));
  };

  handleSubmit = (mission) => {
    this.setState({
      loading: true,
    });
    MissionActions.create(mission)
      .then((newMission) => {
        this.setState({
          fields: {},
          loading: false,
        });
        ToastActions.createToastSuccess(
          Locale.trans("mission.add.success", { name: newMission.name })
        );
        this.props.onCancel();
      })
      .catch(this.handleError);
  };

  handleError = (err) => {
    this.setState({
      loading: false,
    });
    try {
      const resp = JSON.parse(err.response);
      ToastActions.createToastError(resp.message);
    } catch (e) {
      ToastActions.createToastError("Une erreur est survenue");
    }
  };

  render() {
    const { visible, onCancel } = this.props;
    const {
      fields,
      loading,
      userTypes,
      skills,
      establishments,
      hostCompanies,
      jobs,
      partners,
    } = this.state;
    return (
      visible && <Modal
        title={<LocaleComponent transKey="mission.add" />}
        open
        onCancel={onCancel}
        footer={null}
        bodyStyle={{ marginBottom: 200 }}
      >
        <MissionForm
          jobs={jobs}
          partners={partners}
          userTypes={userTypes}
          skills={skills}
          establishments={establishments}
          hostCompanies={hostCompanies}
          onChange={this.handleFormChange}
          fields={fields}
          onSubmit={this.handleSubmit}
          loading={loading}
        />
      </Modal>
    );
  }
}
