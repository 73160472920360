const values = {
    RECEIVE_REVIEW: 'RECEIVE_REVIEW',
    RECEIVE_REVIEWS: 'RECEIVE_REVIEWS',
    RECEIVE_ALL_REVIEWS: 'RECEIVE_ALL_REVIEWS',
    RECEIVE_REVIEWS_OF_USER: 'RECEIVE_REVIEWS_OF_USER',
    RECEIVE_REVIEWS_OF_MISSION: 'RECEIVE_REVIEWS_OF_MISSION',
    CHANGE_CURRENT_REVIEW: 'CHANGE_CURRENT_REVIEW',
    DELETE_REVIEW: 'DELETE_REVIEW',
    RECEIVE_ARCHIVED_REVIEWS: 'RECEIVE_ARCHIVED_REVIEWS',
}
export default values;