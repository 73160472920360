import React, { useEffect } from "react";
import { Form, Button, Row, Col } from "antd";
import Locale from "locale/LocaleFactory";
import FormItem from "components/forms/FormItems";
import AvatarUpload from "components/AvatarUpload.jsx";

import ToastActions from "actions/ToastActions";
import TokenContainer from "services/TokenContainer";
import BaseUrlConstants from "constants/BaseUrlConstants";

interface IProps {
  userTypes: any[]
  loading: boolean
  user?: any
  onSubmit: (user: any) => void
}

const UserForm: React.FC<IProps> = ({ userTypes, loading, user, onSubmit }) => {
  const [form] = Form.useForm();

  const isEditing = !!user;

  const getEntityFromValues = (values: any) => {
    const entity: { [key: string]: any } = {};
    const keys = Object.keys(values);
    const ignoredKeys = [];

    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      if (!k.endsWith("_ids") && k !== "passwordConfirm") {
        if (k.endsWith("_id")) {
          const tK = k.replace("_id", "");
          entity[tK] = values[k];
          ignoredKeys.push(tK);
        } else if (ignoredKeys.indexOf(k) === -1) {
          entity[k] = values[k];
        }
      }
    }
    return entity;
  };

  const handleSubmit = (values: any) => {
    const user = getEntityFromValues(values);
    onSubmit(user);
  };

  const checkPassword = (rule: any, value: any, callback: (err?: string) => void) => {
    if (value && value !== form.getFieldValue('plainPassword')) {
      callback(Locale.trans('user.error.passwordMatch'));
    } else {
      callback();
    }
  };

  const defaultValues = user;
  useEffect(() => {
    if(defaultValues) {
    form.setFieldsValue({
      ...defaultValues,
      types: defaultValues.types?.map((t: any) => t.id.toString()) ?? [],
    });
    } else {
      form.resetFields();
    }
  }, [form, defaultValues]);

  return (
    <Form initialValues={defaultValues} onFinish={handleSubmit} layout="vertical" form={form}>
      <Row>
        {isEditing && <Col span={8}>
          <AvatarField user={user} />
        </Col>}
        <Col span={isEditing ? 16 : 24}>
          <FormItem.Input
            id="firstName"
            required
            label={Locale.trans("user.firstName")}
            form={form}
            hasFeedback
          />
          <FormItem.Input
            id="lastName"
            required
            label={Locale.trans("user.lastName")}
            form={form}
            hasFeedback
          />
        </Col>
      </Row>
      <FormItem.Select
        id="types"
        required
        showSearch
        multiple
        allowClear
        label={Locale.trans("user.type")}
        options={userTypes.map((c) => ({ value: c.id.toString(), label: c.title }))}
        form={form}
        hasFeedback
      />
      <FormItem.Input
        id="job"
        label={Locale.trans("user.job")}
        form={form}
      />
      <FormItem.Input
        id="email"
        label={Locale.trans("user.email")}
        form={form}
      />
      <FormItem.Input
        id="phoneNumber"
        label={Locale.trans("user.phoneNumber")}
        form={form}
      />

      <FormItem.TextArea
        id="address"
        autosize={{ minRows: 1, maxRows: 10 }}
        label={Locale.trans("user.address")}
        form={form}
      />
      <FormItem.Input
        id="cp"
        label={Locale.trans("user.postalCode")}
        form={form}
      />
      <FormItem.Input
        id="town"
        label={Locale.trans("user.city")}
        form={form}
      />

      <FormItem.Input
        id="username"
        required
        label={Locale.trans("user.username")}
        form={form}
      />

      <FormItem.Input
        id="plainPassword"
        type="password"
        required={!isEditing}
        label={Locale.trans("user.password")}
        form={form}
        hasFeedback
      />
      <FormItem.Input
        id="passwordConfirm"
        type="password"
        required={!isEditing}
        label={Locale.trans("user.passwordConfirm")}
        rules={[
          {
            validator: checkPassword,
          },
        ]}
        form={form}
        hasFeedback
      />

      <Button
        type="primary"
        htmlType="submit"
        className="login-form-button"
        loading={loading}
      >
        {Locale.trans("save")}
      </Button>
    </Form>
  );
}

interface IAvatarFieldProps {
  user?: any
}
const AvatarField: React.FC<IAvatarFieldProps> = ({ user }) => {
  if (!user || !user.id) {
    return null;
  }

  const token = TokenContainer.get().replace(/\+/g, "%2B");
  const url = `${BaseUrlConstants.BASE_URL}users/${user.id}/profile-picture?X-Auth-Token=${token}`;

  return (
    <AvatarUpload
      imageUrl={url}
      action={url}
      onSuccess={() => {
        ToastActions.createToast("Photo mise à jour", null, "success");
      }}
      onError={() => {
        ToastActions.createToastError("Le téléchargement de la photo a échoué.");
      }}
    />
  );
}

export default UserForm;
