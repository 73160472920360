import React from "react";
import { Modal } from "antd";

import ReportStore from "stores/ReportStore";
import ReportActions from "actions/ReportActions";
import IncidentStore from "stores/IncidentStore";
import IncidentActions from "actions/IncidentActions";
import ReviewStore from "stores/ReviewStore";
import ReviewActions from "actions/ReviewActions";
import DocumentList from "views/document/DocumentList";

/**
 * The modal to select mission documents to print.
 */
export default class SelectMissionDocumentsToPrintModal extends React.Component {
  constructor(props) {
    super();
    const reports = ReportStore.getByMission(props.mission);
    const incidents = IncidentStore.getByMission(props.mission);
    const reviews = ReviewStore.getByMission(props.mission);
    this.state = {
      reports,
      incidents,
      reviews,
      loading: true,
    };
  }

  componentDidMount() {
    this.reportListener = ReportStore.addListener(this.receiveReports);
    this.setState({ loading: true });
    ReportActions.reloadByMission(this.props.mission).then(() => {
      this.setState({ loading: false });
    });

    this.incidentListener = IncidentStore.addListener(this.receiveIncidents);
    this.setState({ loading: true });
    IncidentActions.reloadByMission(this.props.mission).then(() => {
      this.setState({ loading: false });
    });

    this.reviewListener = ReviewStore.addListener(this.receiveReviews);
    this.setState({ loading: true });
    ReviewActions.reloadByMission(this.props.mission).then(() => {
      this.setState({ loading: false });
    });
  }

  componentWillUnmount() {
    this.reportListener.remove();
    this.incidentListener.remove();
    this.reviewListener.remove();
  }

  receiveReports = () => {
    this.setState({ reports: ReportStore.getByMission(this.props.mission) });
  };

  receiveIncidents = () => {
    this.setState({
      incidents: IncidentStore.getByMission(this.props.mission),
    });
  };

  receiveReviews = () => {
    this.setState({ reviews: ReviewStore.getByMission(this.props.mission) });
  };

  render() {
    const { mission, visible, onCancel } = this.props;
    const { loading, reports, incidents, reviews } = this.state;
    return (
      <Modal
        title="Sélectionnez les documents à imprimer"
        open={mission && visible}
        onCancel={onCancel}
        footer={null}
      >
        <DocumentList
          mission={mission}
          users={mission.users.filter(u => u.type.id === 3).map(u => ({ ...u.user, mission }))}
          loading={loading}
          reports={reports}
          incidents={incidents}
          reviews={reviews}
        />
      </Modal>
    );
  }
}
