import React from "react";
import { Modal } from "antd";

import StringService from "services/utils/StringService";
import MissionStore from "stores/MissionStore";

import ArticleStore from "stores/ArticleStore";
import ArticleActions from "actions/ArticleActions";

import MediaStore from "stores/MediaStore";
import MediaActions from "actions/MediaActions";

import BaseUrlConstants from "constants/BaseUrlConstants";
import LoginStore from "stores/LoginStore";
import Locale from "locale/LocaleFactory";

/**
 * The modal to show a review.
 */
export default class ShowReviewModal extends React.Component {
  componentDidMount() {
    this.missionListener = MissionStore.addListener(this.receiveMissions);
    this.mediaListener = MediaStore.addListener(this.receiveMedias);
    this.articleListener = ArticleStore.addListener(this.receiveArticles);

    const { review } = this.props;
    if (review) {
      review.article.forEach((a) => {
        MediaActions.reloadByArticle(a);
      });
      ArticleActions.reloadByReview(review);
    }
  }

  componentWillUnmount() {
    this.missionListener.remove();
    this.mediaListener.remove();
    this.articleListener.remove();
  }

  receiveUsers = () => {
    this.forceUpdate();
  };

  receiveMissions = () => {
    this.forceUpdate();
  };

  receiveMedias = () => {
    this.forceUpdate();
  };

  receiveArticles = () => {
    this.forceUpdate();
  };

  render() {
    const { review, visible, onCancel } = this.props;

    if (!review) {
      return null;
    }
    return (
      <Modal
        title={`${review.title} - ${review.user.firstName} ${review.user.lastName}`}
        open={visible}
        onCancel={onCancel}
        footer={null}
        bodyStyle={{ marginBottom: 200 }}
      >
        {this.renderContent()}
      </Modal>
    );
  }

  renderContent() {
    const { review } = this.props;
    const mission = MissionStore.getById(review.mission.id);
    const author = review.author;

    return (
      <div className="report-container">
        {this.renderArticles()}
        <p className="report-footer">
          {author &&
            `Rédigé par : ${author.firstName} ${author.lastName.toUpperCase()}`}
          <br />
          {mission && `${Locale.trans("mission.name")} : ${mission.name}`}
        </p>
      </div>
    );
  }

  renderArticles() {
    const { review } = this.props;
    const articles = ArticleStore.getByReview(review);
    if (articles.length === 0) {
      return <p>Aucun article</p>;
    }
    return (
      <div className="report-article-container">
        <div className="report-article-list">
          {articles
            .sort((a, b) => (a.articleOrder > b.articleOrder ? 1 : -1))
            .map((article) => (
              <div className="report-content">
                <p className="report-title">{article.title}</p>
                <br />
                {article.hasGoals ? (
                  <ul>
                    {article.content.split("|").map((goal) => (
                      <li key={goal}>{goal}</li>
                    ))}
                  </ul>
                ) : (
                  StringService.nl2br(article.content)
                )}
                <br />
                {this.renderMedias(article)}
              </div>
            ))}
        </div>
      </div>
    );
  }

  renderMedias(article) {
    const medias = MediaStore.getByArticle(article);
    const token = LoginStore.getJwt();
    const formattedToken = token ? token.replace(/\+/g, "%2B") : "";
    const url = `${BaseUrlConstants.BASE_URL}medias/__ID__/file?X-Auth-Token=${formattedToken}`;

    if (medias.length > 0) {
      return (
        <div className="report-media-container">
          <div className="report-media-title">Pièces jointes</div>
          <div className="report-media-list">
            {medias[0] &&
              medias.map((media) => (
                <a key={media.id} href={url.replace("__ID__", media.id)}>
                  {media.title}
                </a>
              ))}
          </div>
        </div>
      );
    }
  }
}
