import React from "react";
import { Modal, DatePicker, Button } from "antd";

import Locale from "locale/LocaleFactory";
import EmargementService from "services/EmargementService";

import UserStore from "stores/UserStore";
import MissionStore from "stores/MissionStore";
import EmargementStore from "stores/EmargementStore";
import EmargementActions from "actions/EmargementActions";

import LoadingIcon from "components/LoadingIcon.jsx";
import { PrinterOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

/**
 * The modal to select an emargements period.
 */
export default class SelectEmargementsPeriodModal extends React.Component {
  constructor(props) {
    super();
    const emargements = props.groupBy === "none" ? EmargementStore.getByUser(props.archived ? UserStore.getArchivedById(props.id) : UserStore.getById(props.id)) : EmargementStore.getByMission(props.archived ? MissionStore.getArchivedById(props.id) : MissionStore.getById(props.id)) ;
    this.state = {
      loading: !emargements.length,
      selectedPeriod: [],
      emargements,
    };
  }

  componentDidMount() {
    this.setState({loading: true});
    this.emargementListener = EmargementStore.addListener(this.receiveEmargements);
    if(this.props.groupBy === "none") {
      EmargementActions.reloadByUser(this.props.archived ? UserStore.getArchivedById(this.props.id) : UserStore.getById(this.props.id)).then(() => { this.setState({ loading: false })});
    } else {
      EmargementActions.reloadByMission(this.props.archived ? MissionStore.getArchivedById(this.props.id) : MissionStore.getById(this.props.id)).then(() => { this.setState({ loading: false })});
    }
  }

  componentWillUnmount() {
    this.emargementListener.remove();
  }

  receiveEmargements = () => {
    if(this.props.groupBy === "none") {
      this.setState({emargements: EmargementStore.getByUser(this.props.archived ? UserStore.getArchivedById(this.props.id) : UserStore.getById(this.props.id))});
    } else {
      this.setState({emargements: EmargementStore.getByMission(this.props.archived ? MissionStore.getArchivedById(this.props.id) : MissionStore.getById(this.props.id))});
    }
  };

  onSubmit() {
    const { id, groupBy, onCancel } = this.props;
    const { selectedPeriod } = this.state;
    if(groupBy === "none") {
      EmargementService.downloadByIntern(id, selectedPeriod)
    } else {
      const startDate = selectedPeriod.length > 0 ? selectedPeriod[0].format('YYYY-MM-DD') : null;
      const endDate = selectedPeriod.length > 1 ? selectedPeriod[1].format('YYYY-MM-DD') : null;
      EmargementService.download(groupBy, startDate, endDate, undefined, undefined, [id]);
    }
    onCancel();
  }

  render() {
    const { id, visible, onCancel } = this.props;
    const { selectedPeriod, emargements, loading } = this.state;

    let emargementsToPrint = emargements;
    if(selectedPeriod.length > 0) {
        emargementsToPrint = emargements.filter(e => {
        const date = new Date(e.emargementDate).getTime();
        const startPeriod = new Date(selectedPeriod[0]).getTime();
        const endPeriod = new Date(selectedPeriod[1]).getTime();
        return (startPeriod < date && date < endPeriod);
      });
    }

    return (
      <Modal
        title="Sélectionnez une période"
        visible={id && visible}
        onCancel={onCancel}
        footer={null}
      >
        {loading && <LoadingIcon /> }
        <RangePicker
          onChange={(period) => this.setState({ selectedPeriod: period })}
          format="DD/MM/YYYY"
        />
        {emargementsToPrint.length <= 0 && 
          <p align="center" style={{color: "red", fontSize: "12px", marginTop: "5px"}}>
            Aucun émargement enregistré sur la période sélectionnée.
          </p>
        }
        <Button
          type="primary"
          htmlType="submit"
          style={{ marginTop: "10px" }}
          className="login-form-button"
          disabled={emargementsToPrint.length <= 0}
          icon={<PrinterOutlined />}
          onClick={() => this.onSubmit() }
        >
          {Locale.trans("print")}
        </Button>
      </Modal>
    );
  }
}
