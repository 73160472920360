import React from "react";

import { Table, Button, Tooltip } from "antd";
import TableColumnFilter from "components/TableColumnFilter.jsx";
import FilterIcon from "components/FilterIcon.jsx";
import EditUserTypeModal from "./EditUserTypeModal.jsx";
import LoadingIcon from "components/LoadingIcon.jsx";
import ToastActions from "actions/ToastActions";

import Locale from "locale/LocaleFactory";
import ArrayService from "services/utils/ArrayService";
import StringService from "services/utils/StringService";
import FilterService from "services/utils/FilterService";

import UserTypeActions from "actions/UserTypeActions";
import UserTypeStore from "stores/UserTypeStore";
import { EditOutlined } from "@ant-design/icons";

// Sorting Methods
function sortTitleColumn(c1, c2) {
  return StringService.compareCaseInsensitive(c1.title, c2.title);
}

/**
 * The list of the host entities.
 */
export default class UserTypeList extends React.Component {
  constructor() {
    super();

    const entities = UserTypeStore.getAll();

    this.state = {
      loading: !entities.length,
      entities,

      filteredEntities: [],

      filters: {},
      editVisible: false,
      entityToEdit: null,
    };
  }

  componentDidMount() {
    this.userTypeListener = UserTypeStore.addListener(this.receiveData);
    this.loadData();
    this.updateFilters();
    // Here we set the default sorted column
    // Temporary solution waiting for AntD to propose a native way to do it.
    /*const column = this.table.findColumn("reference");
    this.table.toggleSortOrder("descend", column);*/
  }

  componentWillUnmount() {
    this.userTypeListener.remove();
  }

  loadData = () => {
    this.setState({
      loading: true,
    });
    UserTypeActions.reload().then(() => {
      this.setState({
        loading: false,
      });
    });
    this.userTypeListener = UserTypeStore.addListener(this.receiveData);
  };

  receiveData = () => {
    const entities = UserTypeStore.getAll();
    this.setState(
      {
        entities,
      },
      this.updateFilters
    );
  };

  // Filters
  updateFilters = () => {
    const { entities } = this.state;
    const filteredEntities = entities.filter(this.matchFilters);
    this.setState({ filteredEntities });
  };

  matchFilters = (c) => {
    const { filters } = this.state;
    return FilterService.matchFilter(filters.title, c.title);
  };

  handleFilterChange = (name, values) => {
    let prevState = this.state.filters;
    prevState[name] = values;
    this.setState({ filters: prevState });
    this.updateFilters();
  };

  getTitles = () =>
    ArrayService.unique(this.state.entities.map((c) => c.title));

  edit = (t) => {
    this.setState({
      editVisible: true,
      entityToEdit: t,
    });
  };
  hideeditModal = () => {
    this.setState({
      editVisible: false,
      entityToEdit: null,
    });
  };

  handleError = (err) => {
    this.setState({
      loading: false,
    });
    try {
      const resp = JSON.parse(err.response);
      ToastActions.createToastError(resp.message);
    } catch (e) {
      ToastActions.createToastError("Une erreur est survenue");
    }
  };

  render() {
    const { entityToEdit } = this.state;
    return (
      <div className="user-type-list">
        {this.renderTable()}
        <EditUserTypeModal
          userType={entityToEdit}
          onCancel={this.hideeditModal}
          visible={this.state.editVisible}
        />
      </div>
    );
  }

  renderTable() {
    const { filters, filteredEntities, loading } = this.state;
    const columns = [
      {
        title: Locale.trans("userType.title"),
        key: "title",
        sorter: sortTitleColumn,
        filterIcon: (
          <FilterIcon active={filters.title && filters.title.length > 0} />
        ),
        render: (t) => t.title,
        filterDropdown: (
          <TableColumnFilter
            name="title"
            selectedValues={filters.title}
            values={this.getTitles().map((r) => ({ text: r, value: r }))}
            onChange={this.handleFilterChange}
          />
        ),
      },
      {
        title: null,
        key: "actions",
        width: "50px",
        render: this.rendActionsCell,
      },
    ];

    return (
      <Table
        dataSource={filteredEntities}
        rowKey="id"
        columns={columns}
        locale={Locale.Table}
        ref={(r) => {
          this.table = r;
        }}
        loading={loading && { indicator: <LoadingIcon /> }}
      />
    );
  }

  rendActionsCell = (t) => (
    <div className="actions-row">
      <Tooltip title={Locale.trans("edit")}>
        <Button
          type="primary"
          shape="circle"
          icon={<EditOutlined />}
          onClick={(e) => {
            this.edit(t);
            e.stopPropagation();
            e.preventDefault();
            return false;
          }}
        />
      </Tooltip>
    </div>
  );
}
