import React, { useEffect } from "react";
import { Form, Button, Row, Col } from "antd";
import Locale from "locale/LocaleFactory";
import FormItem from "components/forms/FormItems";
import AvatarUpload from "components/AvatarUpload";
import TokenContainer from "services/TokenContainer";
import BaseUrlConstants from "constants/BaseUrlConstants";
import ToastActions from "actions/ToastActions";
import UserSelectFormItem from "components/form-items/UserSelectFormItem";

interface IProps {
  partner?: any
  loading: boolean
  userTypes?: any[]
  onSubmit: (establishment: any) => void
}
const PartnerForm: React.FC<IProps> = ({ partner, userTypes, loading, onSubmit }) => {
  const [form] = Form.useForm();

  const getEntityFromValues = (values: any) => {
    const entity: { [key: string]: any } = {};
    const keys = Object.keys(values);
    const ignoredKeys = [];

    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      if (!k.endsWith("_ids") && k !== "user") {
        if (k.endsWith("_id")) {
          const tK = k.replace("_id", "");
          entity[tK] = values[k];
          ignoredKeys.push(tK);
        } else if (ignoredKeys.indexOf(k) === -1) {
          entity[k] = values[k];
        }
      } else if (k.startsWith("user_")) {
        if (!entity.user) {
          entity.user = [];
        }
        entity.user = entity.user.concat(values[k]);
      }
    }

    return entity;
  };

  const handleSubmit = (values: any) => {
    const establishment = getEntityFromValues(values);
    onSubmit(establishment);
  };

  const defaultValues = partner;
  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  const isEditing = !!partner;

  return (
    <Form initialValues={defaultValues} onFinish={handleSubmit} layout="vertical" form={form}>
      <Row>
        {isEditing && <Col span={8}><AvatarField partner={partner} /></Col>}
        <Col span={isEditing ? 16 : 24}>
          <FormItem.Input
            id="name"
            required
            label={Locale.trans("partner.name")}
            form={form}
            hasFeedback
          />
        </Col>
      </Row>
      <FormItem.Input
        id="email"
        label={Locale.trans("partner.email")}
        form={form}
        hasFeedback
      />
      <FormItem.Input
        id="phoneNumber"
        label={Locale.trans("partner.phoneNumber")}
        form={form}
        hasFeedback
      />

      <FormItem.TextArea
        id="address"
        autosize={{ minRows: 1, maxRows: 10 }}
        label={Locale.trans("partner.address")}
        form={form}
      />
      <FormItem.Input
        id="cp"
        label={Locale.trans("partner.postalCode")}
        form={form}
      />
      <FormItem.Input
        id="town"
        label={Locale.trans("partner.city")}
        form={form}
      />

      {userTypes?.filter(u => u.isVisibleOnPartner)
        .map((u) => <UserField userType={u} partner={partner} />)}


      <Button
        type="primary"
        htmlType="submit"
        className="login-form-button"
        loading={loading}
      >
        {Locale.trans("save")}
      </Button>
    </Form>
  );
}

interface IUserFieldProps {
  userType: any
  partner?: any
}
const UserField: React.FC<IUserFieldProps> = ({ userType, partner }) => {
  const initialUsers = partner?.user
    .filter((u: any) =>
      !!u.types?.find((type: any) => type.id === userType.id)
    );
  return (
    <UserSelectFormItem
      key={userType.id}
      id={`user_${userType.id}_ids`}
      multiple
      label={userType.title}
      initialValue={initialUsers?.map((u: any) => u.id.toString()) ?? []}
      initialUsers={initialUsers}
      extraFilters={{
        archived: false,
        types: [userType.id]
      }}
    />
  );

  // if (!users || users.length === 0) return null;
  // let concernedUsers = users;
  // if (partner) concernedUsers = ArrayService.uniqueEntity(users.concat(partner.user));

  // const { getFieldValue } = form;
  // const initialValue = ((getFieldValue("user") || []) as any[])
  //   .map(u => concernedUsers.find(u1 => u1.id === u.id))
  //   .filter(
  //     u =>
  //       u.types &&
  //       u.types.length > 0 &&
  //       u.types.some((type: any) => type.id === userType.id)
  //   )
  //   .map(s => s.id.toString());
  // const options = concernedUsers
  //   .filter(
  //     u =>
  //       u.types &&
  //       u.types.length > 0 &&
  //       u.types.some((type: any) => type.id === userType.id)
  //   )
  //   .sort(cmpUsersByName)
  //   .map(u => ({
  //     value: u.id,
  //     label: `${u.lastName} ${u.firstName}`
  //   }));
  // return (
  //   <FormItem.Select
  //     key={userType.id}
  //     id={`user_${userType.id}_ids`}
  //     multiple
  //     label={userType.title}
  //     initialValue={initialValue}
  //     options={options}
  //     form={form}
  //   />
  // );
};

interface IAvatarFieldProps {
  partner?: any
}
const AvatarField: React.FC<IAvatarFieldProps> = ({ partner }) => {
  if (!partner || !partner.id) {
    return null;
  }

  const token = TokenContainer.get().replace(/\+/g, "%2B");
  const url = `${BaseUrlConstants.BASE_URL}partners/${partner.id}/picture?X-Auth-Token=${token}`;

  return (
    <AvatarUpload
      imageUrl={url}
      action={url}
      onSuccess={() => {
        ToastActions.createToast("Photo mise à jour", null, "success");
      }}
      onError={() => {
        ToastActions.createToastError(
          "Le téléchargement de la photo a échoué."
        );
      }}
    />
  );
}

export default PartnerForm;
