import React from "react";
import { Modal } from "antd";

import UserForm from "components/forms/UserForm";

import ToastActions from "actions/ToastActions";

import UserActions from "actions/UserActions";

import UserTypeActions from "actions/UserTypeActions";
import UserTypeStore from "stores/UserTypeStore";

/**
 * The modal to create a new user.
 */
export default class CreateUserModal extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      fields: {
        types: { value: [] },
      },
      userTypes: UserTypeStore.getAll(),
    };
  }

  componentDidMount() {
    this.userTypeListener = UserTypeStore.addListener(this.receiveUserTypes);
    UserTypeActions.reload();
  }

  componentWillUnmount() {
    this.userTypeListener.remove();
  }

  receiveUserTypes = () => {
    this.setState({
      userTypes: UserTypeStore.getAll(),
    });
  };

  handleFormChange = (changedFields) => {
    this.setState(({ fields }) => ({
      fields: { ...fields, ...changedFields },
    }));
  };

  handleSubmit = (user) => {
    this.setState({
      loading: true,
    });
    UserActions.create(user)
      .then((newUser) => {
        this.setState({
          fields: {},
          loading: false,
        });
        ToastActions.createToastSuccess(
          `Utilisateur "${newUser.firstName} ${newUser.lastName}" créé`
        );
        this.props.onCancel();
      })
      .catch(this.handleError);
  };

  handleError = (err) => {
    this.setState({
      loading: false,
    });
    try {
      const resp = JSON.parse(err.response);
      ToastActions.createToastError(resp.message);
    } catch (e) {
      ToastActions.createToastError("Une erreur est survenue");
    }
  };

  render() {
    const { visible, onCancel } = this.props;
    const { fields, userTypes, loading } = this.state;
    return (
      <Modal
        title="Ajouter un Utilisateur"
        open={visible}
        onCancel={onCancel}
        footer={null}
      >
        <UserForm
          onChange={this.handleFormChange}
          fields={fields}
          userTypes={userTypes}
          onSubmit={this.handleSubmit}
          loading={loading}
        />
      </Modal>
    );
  }
}
