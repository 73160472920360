
import React from 'react';
import { Modal } from 'antd';

import MissionForm from 'components/forms/MissionForm';

import SecurityService from 'services/SecurityService';
import DateService from 'services/utils/DateService';
import Access from 'constants/AccessLevel';
import Resource from 'constants/Resource';

import ToastActions from 'actions/ToastActions';

import MissionActions from 'actions/MissionActions';

import UserTypeActions from 'actions/UserTypeActions';
import UserTypeStore from 'stores/UserTypeStore';

import SkillActions from 'actions/SkillActions';
import SkillStore from 'stores/SkillStore';

import JobActions from 'actions/JobActions';
import JobStore from 'stores/JobStore';

import PartnerActions from 'actions/PartnerActions';
import PartnerStore from 'stores/PartnerStore';

import EstablishmentActions from 'actions/EstablishmentActions';
import EstablishmentStore from 'stores/EstablishmentStore';

import HostCompanyActions from 'actions/HostCompanyActions';
import HostCompanyStore from 'stores/HostCompanyStore';
import Locale, { Locale as LocaleComponent } from 'locale/LocaleFactory';
import dayjs from 'dayjs';

/**
 * The modal to edit a mission.
 */
export default class EditMissionModal extends React.Component {
    jobListener;
    partnerListener;
    skillListener;
    establishmentListener;
    hostCompaniListener;

    constructor(props) {
        super();
        this.state = {
            loading: false,
            fields: this.getFieldsFromEntity(props.mission),
            jobs: JobStore.getJobs(),
            partners: PartnerStore.getPartners(),
            userTypes: UserTypeStore.getAll(),
            skills: SkillStore.getSkills(),
            establishments: EstablishmentStore.getEstablishments(),
            hostCompanies: HostCompanyStore.getHostCompanies(),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.visible && !this.props.visible) {
            this.setState({
                fields: this.getFieldsFromEntity(nextProps.mission),
            });
        }
    }

    componentDidMount() {
        this.jobListener = JobStore.addListener(this.receiveJobs);
        this.partnerListener = PartnerStore.addListener(this.receivePartners);
        this.userTypeListener = UserTypeStore.addListener(this.receiveUserTypes);
        this.skillListener = SkillStore.addListener(this.receiveSkills);
        this.establishmentListener = EstablishmentStore.addListener(this.receiveEstablishments);
        this.hostCompaniListener = HostCompanyStore.addListener(this.receiveHostCompanies);
        JobActions.reload();
        PartnerActions.reload();
        UserTypeActions.reload();
        SkillActions.reload();
        EstablishmentActions.reload();
        HostCompanyActions.reload();
    }

    componentWillUnmount() {
        this.jobListener.remove();
        this.partnerListener.remove();
        this.userTypeListener.remove();
        this.skillListener.remove();
        this.establishmentListener.remove();
        this.hostCompaniListener.remove();
    }

    receiveJobs = () => {
        this.setState({
            jobs: JobStore.getJobs(),
        });
    };

    receivePartners = () => {
        this.setState({
            partners: PartnerStore.getPartners(),
        });
    };

    receiveUserTypes = () => {
        this.setState({
            userTypes: UserTypeStore.getAll(),
        });
    };

    receiveSkills = () => {
        this.setState({
            skills: SkillStore.getSkills(),
        });
    };

    receiveEstablishments = () => {
        this.setState({
            establishments: EstablishmentStore.getEstablishments(),
        });
    };

    receiveHostCompanies = () => {
        this.setState({
            hostCompanies: HostCompanyStore.getHostCompanies(),
        });
    };

    getFieldsFromEntity = (entity) => {
        if (!entity) {
            return {};
        }
        const fields = {};
        const keys = Object.keys(entity);
        for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            fields[k] = { value: entity[k] };
        }

        if (entity.periodStart && entity.periodEnd) {
            fields.period = {
                value: [
                    dayjs(DateService.parseApi(entity.periodStart)),
                    dayjs(DateService.parseApi(entity.periodEnd)),
                ],
            };
        }
        return fields;
    };

    handleFormChange = (changedFields) => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields },
        }));
    };

    handleSubmit = (mission) => {
        this.setState({
            loading: true,
        });

        MissionActions.edit(this.props.mission.id, mission)
        .then(() => {
            this.setState({
                fields: {},
                loading: false,
            });
            ToastActions.createToastSuccess(Locale.trans('mission.update.success', { name: mission.name}));
            this.props.onCancel();
        }).catch(this.handleError);
    };

    handleError = (err) => {
        this.setState({
            loading: false,
        });
        try {
            const resp = JSON.parse(err.response);
            ToastActions.createToastError(resp.message);
        } catch (e) {
            ToastActions.createToastError('Une erreur est survenue');
        }
    };

    render() {
        const { mission, visible, onCancel } = this.props;
        const {
            fields,
            loading,
            userTypes,
            skills,
            establishments,
            hostCompanies,
            jobs,
            partners,
        } = this.state;
        return (
            mission && visible && <Modal
                title={<LocaleComponent transKey="mission.update" />}
                open
                onCancel={onCancel}
                footer={null}
                bodyStyle={{ marginBottom: 200 }}
            >
                <MissionForm
                    jobs={jobs}
                    partners={partners}
                    mission={mission}
                    userTypes={userTypes}
                    skills={skills}
                    establishments={establishments}
                    hostCompanies={hostCompanies}
                    onChange={this.handleFormChange}
                    fields={fields}
                    onSubmit={this.handleSubmit}
                    loading={loading}
                    readOnly={!SecurityService.isGranted(Resource.MISSION, Access.EDIT)}
                />
            </Modal>
        );
    }
}
