import ArchivedEntityStoreBase from "../stores/ArchivedEntityStoreBase";
import EstablishmentConstants from "../constants/EstablishmentConstants";

class EstablishmentStore extends ArchivedEntityStoreBase {
    getInitialState() {
        return {
            currentEstablishmentId: null,
            entities: [],
            archived: [],
        };
    }

    reduce(state, action) {
        const { type, payload } = action;
        let newState = Object.assign({}, state);

        switch (type) {
            case EstablishmentConstants.RECEIVE_ESTABLISHMENTS_OF_MISSION:
                newState = this._receiveByMission(
                    state,
                    payload.mission,
                    payload.establishments
                );
                break;
            case EstablishmentConstants.RECEIVE_ESTABLISHMENT:
                newState = this._receiveEntity(state, payload.establishment);
                break;
            case EstablishmentConstants.RECEIVE_ARCHIVED_ESTABLISHMENT:
                newState = this._receiveArchivedEntity(state, payload.establishment);
                break;
            case EstablishmentConstants.RECEIVE_ESTABLISHMENTS:
                newState = this._receiveMany(state, payload.establishments);
                break;
            case EstablishmentConstants.RECEIVE_ALL_ESTABLISHMENTS:
                    newState = this._receiveAll(state, payload.establishments);
                    break;
            case EstablishmentConstants.RECEIVE_ARCHIVED_ESTABLISHMENTS:
                newState = this._receiveArchived(state, payload.establishments);
                break;
            case EstablishmentConstants.CHANGE_CURRENT_ESTABLISHMENT:
                newState.currentEstablishmentId = payload.establishmentId;
                break;
            case "clearAll":
                newState = this.getInitialState();
                break;
            default:
                newState = state;
        }
        return newState;
    }

    _receiveByMission = (state, mission, establishments) => {
        let newState = Object.assign({}, state);
        newState.entities = establishments;
        return newState;
    };

    _receiveMany = (state, establishments) => {
        let newState = Object.assign({}, state);
        newState.entities = state.entities.filter(
            e => establishments.findIndex(a => e.id === a.id) === -1
        );
        newState.entities.push(...establishments);
        return newState;
    };

    _receiveArchived = (state, establishments) => {
        let newState = Object.assign({}, state);
        newState.archived = state.archived.filter(
            e => establishments.findIndex(a => e.id === a.id) === -1
        );
        newState.archived.push(...establishments);
        return newState;
      };

    ///// Public Methods  /////
    /**
     * Get all the establishment of a mission.
     */
    getByMission = (mission) => {
        return this.getState().entities.filter(e => !!e.mission.find(a => a.id === mission.id));
    }

    /**
     * Get an establishment by ID.
     * @param {number} id The identifier.
     * @return {?Establishment} The establishment, or NULL if no entity is found.
     */
    getById = (id) => {
        return this.getState().entities.find(e => e.id === id);
    };

    /**
     * Get an archived establishment by ID.
     * @param {number} id The identifier of the archived establishment.
     * @return {?Establishment}    The archived establishment, or NULL if no entity is found.
     */
    getArchivedById = id => this.getState().archived.find(e => e.id === id);

    /**
     * Get all the establishments.
     */
    getAll = () => this.getState().entities.concat(this.getState().archived);

    /**
     * Get unarchived establishments.
     */
    getEstablishments = () => this.getState().entities;

    getArchivedEstablishments = () => this.getState().archived;

    /**
     * Get the identifier of the current establishment.
     * @return {[type]} [description]
     */
    getCurrentEstablishmentId = () => {
        return (
            this.getState().currentEstablishmentId ||
            (this.getState().entities.length
                ? this.getState().entities[0].id
                : null)
        );
    };

    /**
     * Get the identifier of the current establishment.
     * @return {[type]} [description]
     */
    getCurrentEstablishment = () => {
        return this.getById(this.getCurrentEstablishmentId());
    };
}

const store = new EstablishmentStore();
export default store;