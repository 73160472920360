import React from "react";
import { Modal } from "antd";

import StringService from "services/utils/StringService";
import DateService from "services/utils/DateService";
import MissionStore from "stores/MissionStore";

import ArticleStore from "stores/ArticleStore";
import ArticleActions from "actions/ArticleActions";

import MediaStore from "stores/MediaStore";
import MediaActions from "actions/MediaActions";

import BaseUrlConstants from "constants/BaseUrlConstants";
import LoginStore from "stores/LoginStore";
import Locale from "locale/LocaleFactory";

/**
 * The modal to show an incident.
 */
export default class ShowIncidentModal extends React.Component {
  componentDidMount() {
    this.articleListener = ArticleStore.addListener(this.receiveArticles);
    this.missionListener = MissionStore.addListener(this.receiveMissions);
    this.mediaListener = MediaStore.addListener(this.receiveMedias);

    const { incident } = this.props;
    if (incident) {
      incident.article.forEach(a => {
        MediaActions.reloadByArticle(a);
      });
      ArticleActions.reloadByIncident(incident);
    }
  }

  componentWillUnmount() {
    this.missionListener.remove();
    this.mediaListener.remove();
    this.articleListener.remove();
  }
  
  receiveArticles = () => {
    this.forceUpdate();
  };

  receiveUsers = () => {
    this.forceUpdate();
  };

  receiveMissions = () => {
    this.forceUpdate();
  };

  receiveMedias = () => {
    this.forceUpdate();
  };

  render() {
    const { incident, visible, onCancel } = this.props;

    if (!incident) {
      return null;
    }

    return (
      <Modal
        title="Rapport d'incident"
        open={visible}
        onCancel={onCancel}
        footer={null}
        bodyStyle={{ marginBottom: 200 }}
      >
        {this.renderContent()}
      </Modal>
    );
  }

  renderContent() {
    const { incident } = this.props;

    const mission = MissionStore.getById(incident.mission.id);
    const author = incident.author;
    return (
      <div className="report-container">
        <p className="report-title">{incident.title}</p>
        <p className="report-date">
          {Locale.trans("report.on")}{" "}
          {DateService.formatApiToDisplay(incident.date)}
        </p>

        {this.renderArticles()}
        <p className="report-footer">
          {author && `Rédigé par : ${author.firstName} ${author.lastName.toUpperCase()}`}
          <br/>
          {`Participants : ${incident.user.length > 0 ? incident.user.map(u => (
            u.firstName + " " +  u.lastName.toUpperCase()
          )).join(', '): '-'}`}
          <br/>
          {mission && `${Locale.trans("mission.name")} : ${mission.name}`}
        </p>
      </div>
    );
  }

  renderArticles() {
    const { incident } = this.props;

    const articles = ArticleStore.getByIncident(incident);
    if (articles.length === 0) {
      return <p>Aucun article</p>;
    }

    return (
      <div className="report-article-container">
        <div className="report-article-list">
          {articles.map(article => (
            <div key={article.id} className="report-content">
                {/*<p className="report-title">{article.title}</p>*/}
              {StringService.nl2br(article.content)}
              <br/>
              {this.renderMedias(article)}
              <br/>
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderMedias(article) {
    const medias = MediaStore.getByArticle(article);
    const token = LoginStore.getJwt();
    const formattedToken = token ? token.replace(/\+/g, "%2B") : "";
    const url = `${BaseUrlConstants.BASE_URL}medias/__ID__/file?X-Auth-Token=${formattedToken}`;

    if (medias.length > 0) {
      return (
        <div className="report-media-container">
          <div className="report-media-title">Pièces jointes</div>
          <div className="report-media-list">
            {medias[0] && medias.map(media => (
              <a key={media.id} href={url.replace("__ID__", media.id)}>
                {media.title}
              </a>
            ))}
          </div>
        </div>
      );
    }
  }
}
