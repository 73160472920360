
import React from "react";

import BaseUrlConstants from "constants/BaseUrlConstants";
import LoginStore from "stores/LoginStore";

const EmargementPicture = ({ emargement, className, ...props }) => {
  const token = LoginStore.getJwt();
  const formattedToken = token ? token.replace(/\+/g, "%2B") : "";
  const url = `${BaseUrlConstants.BASE_URL}emargements/${emargement.id}/file`;
  return (
    <div {...props} className={`emargement-picture ${className || ""}`}>
      <img
        alt="Signature"
        src={`${url}?X-Auth-Token=${formattedToken}`}
        style={{ width: 100 }}
      />
    </div>
  );
};

export default EmargementPicture;
