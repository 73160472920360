import React, { useState } from "react";
import { Route, Routes, Link, useLocation, useNavigate } from "react-router-dom";
import ScrollArea from "react-scrollbar";

import { Button, Menu, Layout, Popover, Avatar } from "antd";

import Locale, { Locale as LocaleComponent } from "locale/LocaleFactory";
import SecurityService from "services/SecurityService";
import Access from "constants/AccessLevel";
import Resource from "constants/Resource";

import LoginActions from "actions/LoginActions";
import LoginStore from "stores/LoginStore";

import Home from "views/Home.jsx";
import Configuration from "views/Configuration.jsx";
import MissionList from "views/Mission/MissionList.jsx";
import ReportList from "views/Report/ReportList.jsx";
import ReviewList from "views/Review/ReviewList.jsx";
import IncidentList from "views/Incident/IncidentList.jsx";
import CustomProperties from "react-custom-properties";
import ThemeStore from "stores/ThemeStore";
import { BookOutlined, FileTextOutlined, FolderOpenOutlined, HomeOutlined, LogoutOutlined, SettingOutlined, WarningOutlined } from "@ant-design/icons";

const { Header, Content, Sider } = Layout;

const AppLayout: React.FC = () => {
  const [menuCollapsed, setMenuCollapsed] = useState(localStorage.getItem("sidebar-collapsed") === "true");
  const location = useLocation();
  const navigate = useNavigate();

  const logout = () => {
    LoginActions.logoutUser();
    navigate("/login");
  };

  const handleMenuClick = (e: any) => {
    if (e.key !== location.pathname) {
      navigate(e.key);
    }
  };

  const onCollapse = (collapsed: boolean) => {
    setMenuCollapsed(collapsed);
    localStorage.setItem("sidebar-collapsed", collapsed.toString());
  };

  const user = LoginStore.getUser();
  const selectedMenu = location.pathname;

  const firstname = user.firstName.replace(
    /\w\S*/g,
    (tStr: string) => tStr.charAt(0).toUpperCase() + tStr.substr(1).toLowerCase()
  );
  const fullName = `${firstname} ${user.lastName.toUpperCase()}`;

  return (
    <>
      <CustomProperties global properties={ThemeStore.get()} />
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          collapsible
          collapsed={menuCollapsed}
          onCollapse={onCollapse}
        >
          <ScrollArea horizontal={false}>
            <Link to="/">
              <div className="logo">
                <img
                  src="images/logo.png"
                  alt="logo"
                  style={{ width: 100 }}
                />
              </div>
            </Link>

            <Menu
              theme="dark"
              onClick={handleMenuClick}
              selectedKeys={[selectedMenu]}
              mode="inline"
            >
              <Menu.Item key="/">
                <HomeOutlined />
                <span>Accueil</span>
              </Menu.Item>
              {SecurityService.isGranted(Resource.MISSION, Access.READ) && (
                <Menu.Item key="/mission">
                  <FolderOpenOutlined />
                  <span>
                    <LocaleComponent transKey="mission.name" />
                  </span>
                </Menu.Item>
              )}
              {SecurityService.isGranted(Resource.REPORT, Access.READ) && (
                <Menu.Item key="/report">
                  <FileTextOutlined />
                  <span>
                    <LocaleComponent transKey="report.name" />
                  </span>
                </Menu.Item>
              )}
              {SecurityService.isGranted(Resource.REPORT, Access.READ) && (
                <Menu.Item key="/incident">
                  <WarningOutlined />
                  <span>Incident</span>
                </Menu.Item>
              )}
              {SecurityService.isGranted(Resource.REVIEW, Access.READ) && (
                <Menu.Item key="/review">
                  <BookOutlined />
                  <span>{Locale.trans("review.name")}</span>
                </Menu.Item>
              )}
              {SecurityService.isGranted(Resource.CONFIGURATION, Access.EDIT) && (
                <Menu.Item key="/configuration">
                  <SettingOutlined />
                  <span>Configuration</span>
                </Menu.Item>
              )}
            </Menu>
          </ScrollArea>
        </Sider>
        <Layout>
          <Header
            style={{
              paddingLeft: menuCollapsed ? "130px" : "250px",
            }}
          >
            <div className="welcome-message">Bonjour {fullName}</div>
            <Popover
              overlayClassName="layout-user-menu"
              content={
                <React.Fragment>
                  <Button icon={<LogoutOutlined />} onClick={logout}>
                    {Locale.trans("login.logOut.button")}
                  </Button>
                </React.Fragment>
              }
              title={null}
              placement="bottomRight"
            >
              <Avatar size="large" className="profile-btn">
                {user.firstName.charAt(0).toUpperCase()}
                {user.lastName.charAt(0).toUpperCase()}
              </Avatar>
            </Popover>
          </Header>
          <Content
            style={{
              marginLeft: menuCollapsed ? "80px" : "200px",
            }}
          >
            <Routes>
              <Route path="/" element={<Home />} />
              {SecurityService.isGranted(Resource.MISSION, Access.READ) && (
                <Route path="/mission" element={<MissionList />} />
              )}
              {SecurityService.isGranted(Resource.REPORT, Access.READ) && (
                <Route path="/report" element={<ReportList />} />
              )}
              {SecurityService.isGranted(Resource.REPORT, Access.READ) && (
                <Route path="/incident" element={<IncidentList />} />
              )}
              {SecurityService.isGranted(Resource.REVIEW, Access.READ) && (
                <Route path="/review" element={<ReviewList />} />
              )}
              {SecurityService.isGranted(Resource.CONFIGURATION, Access.EDIT) && (
                <Route path="/configuration" element={<Configuration />} />
              )}
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

export default AppLayout;