import ActionsBase from "../actions/ActionsBase";

import DocumentsService from "../services/DocumentsService";
import BaseUrlConstants from "constants/BaseUrlConstants";
import TokenContainer from "services/TokenContainer";

class DocumentsActions extends ActionsBase {
  download = (reports, incidents, reviews, certificatesOfCompetence, skills) => {
    const $this = this;
    return new Promise((resolve, reject) => {
      function handleSuccess(res) {
        const url = `${BaseUrlConstants.BASE_URL}documents/download/${res}`;
        window.location = `${url}?X-Auth-Token=${encodeURIComponent(
          TokenContainer.get()
        )}`;

        resolve();
      }
      function handleError(err) {
        $this.handleError(err, reject);
      }
      DocumentsService.download(reports, incidents, reviews, certificatesOfCompetence, skills)
        .then(handleSuccess)
        .catch(handleError);
    });
  };
}

const action = new DocumentsActions();
export default action;
