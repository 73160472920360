import EntityStoreBase from "../stores/EntityStoreBase";

export default class ArchivedEntityStore extends EntityStoreBase {
  getInitialState() {
    return {
      entities: [],
      archived: [],
    };
  }

  _receiveEntity = (state, entity) => {
    let newState = Object.assign({}, state);
    newState.entities = state.entities.slice();
    newState.archived = state.archived.slice();
    if (entity.archived) {
      const i = newState.archived.findIndex((f) => f.id === entity.id);
      newState.archived[i] = entity;
    } else {
      const i = newState.entities.findIndex((f) => f.id === entity.id);
      if (i > -1) {
        newState.entities[i] = entity;
      } else {
        newState.entities.push(entity);
      }
    }
    return newState;
  };

  _receiveArchivedEntity = (state, entity) => {
    let newState = Object.assign({}, state);
    newState.entities = state.entities.slice();
    newState.archived = state.archived.slice();
    if (entity.archived) {
      const i = newState.entities.findIndex((f) => f.id === entity.id);
      newState.entities.splice(i, 1);
      newState.archived[i] = entity;
    } else {
      const i = newState.archived.findIndex((f) => f.id === entity.id);
      newState.archived.splice(i, 1);
      newState.entities[i] = entity;
    }
    return newState;
  };
}
