import React, { useEffect } from "react";
import { Form, Button, Row, Col } from "antd";
import Locale from "locale/LocaleFactory";
import FormItem from "components/forms/FormItems";
import AvatarUpload from "components/AvatarUpload.jsx";

import ToastActions from "actions/ToastActions";
import TokenContainer from "services/TokenContainer";
import BaseUrlConstants from "constants/BaseUrlConstants";
import UserSelectFormItem from "components/form-items/UserSelectFormItem";

interface IProps {
  hostCompany?: any
  loading: boolean
  userTypes?: any[]
  onSubmit: (company: any) => void
}

const HostCompanyForm: React.FC<IProps> = ({ hostCompany, userTypes, loading, onSubmit }) => {
  const [form] = Form.useForm();

  const isEditing = !!hostCompany;

  const getEntityFromValues = (values: any) => {
    const entity: { [key: string]: any } = {};
    const keys = Object.keys(values);
    const ignoredKeys = [];

    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      if (!k.endsWith("_ids") && k !== "user") {
        if (k.endsWith("_id")) {
          const tK = k.replace("_id", "");
          entity[tK] = values[k];
          ignoredKeys.push(tK);
        } else if (ignoredKeys.indexOf(k) === -1) {
          entity[k] = values[k];
        }
      } else if (k.startsWith("user_")) {
        if (!entity.user) {
          entity.user = [];
        }
        entity.user = entity.user.concat(values[k]);
      }
    }

    return entity;
  };

  const handleSubmit = (values: any) => {
    const company = getEntityFromValues(values);
    onSubmit(company);
  };

  const defaultValues = hostCompany;
  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  return (
    <Form initialValues={defaultValues} onFinish={handleSubmit} layout="vertical" form={form}>
      <Row>
        {isEditing && <Col span={8}><AvatarField hostCompany={hostCompany} /></Col>}
        <Col span={isEditing ? 16 : 24}>
          <FormItem.Input
            id="name"
            required
            label={Locale.trans("hostCompany.name")}
            form={form}
            hasFeedback
          />
        </Col>
      </Row>
      <FormItem.Input
        id="email"
        label={Locale.trans("hostCompany.email")}
        form={form}
        hasFeedback
      />
      <FormItem.Input
        id="phoneNumber"
        label={Locale.trans("hostCompany.phoneNumber")}
        form={form}
        hasFeedback
      />

      <FormItem.TextArea
        id="address"
        required
        autosize={{ minRows: 1, maxRows: 10 }}
        label={Locale.trans("hostCompany.address")}
        form={form}
      />
      <FormItem.Input
        id="cp"
        label={Locale.trans("hostCompany.postalCode")}
        form={form}
      />
      <FormItem.Input
        id="town"
        label={Locale.trans("hostCompany.city")}
        form={form}
      />

      {userTypes?.filter(u => u.isVisibleOnHostCompany)
        .map((u) => <UserSelectItem key={u.id} userType={u} hostCompany={hostCompany} />)}

      <Button
        type="primary"
        htmlType="submit"
        className="login-form-button"
        loading={loading}
      >
        {Locale.trans("save")}
      </Button>
    </Form>
  );
}

interface IAvatarFieldProps {
  hostCompany?: any
}
const AvatarField: React.FC<IAvatarFieldProps> = ({ hostCompany }) => {
  if (!hostCompany || !hostCompany.id) {
    return null;
  }

  const token = TokenContainer.get().replace(/\+/g, "%2B");
  const url = `${BaseUrlConstants.BASE_URL}host-companies/${hostCompany.id
    }/picture?X-Auth-Token=${token}`;

  return (
    <AvatarUpload
      imageUrl={url}
      action={url}
      onSuccess={() => {
        ToastActions.createToast("Photo mise à jour", null, "success");
      }}
      onError={() => {
        ToastActions.createToastError(
          "Le téléchargement de la photo a échoué."
        );
      }}
    />
  );
}

interface IUserSelectItemProps {
  userType: any
  hostCompany?: any
}

const UserSelectItem: React.FC<IUserSelectItemProps> = ({ userType, hostCompany }) => {

  const initialUsers = hostCompany?.user
    .filter((u: any) =>
      !!u.types?.find((type: any) => type.id === userType.id)
    );
  return (
    <UserSelectFormItem
      id={`user_${userType.id}_ids`}
      multiple
      label={userType.title}
      initialValue={initialUsers?.map((u: any) => u.id.toString()) ?? []}
      initialUsers={initialUsers}
      extraFilters={{
        archived: false,
        types: [userType.id]
      }}
    />
  );


  // if (!users || users.length === 0) return null;
  // let concernedUsers = users;
  // if (hostCompany) {
  //   concernedUsers = ArrayService.uniqueEntity(users.concat(hostCompany.user));
  // }

  // const initialValue = ((hostCompany?.user || []) as any[])
  //   .map(u => concernedUsers.find(u1 => u1.id === u.id))
  //   .filter(u =>
  //     u.types &&
  //     u.types.length > 0 &&
  //     u.types.some((type: any) => type.id === userType.id)
  //   )
  //   .map(s => s.id.toString());
  // const options = concernedUsers
  //   .filter(
  //     u =>
  //       u.types &&
  //       u.types.length > 0 &&
  //       u.types.some((type: any) => type.id === userType.id)
  //   )
  //   .sort(cmpUsersByName)
  //   .map(u => ({
  //     value: u.id,
  //     label: `${u.lastName} ${u.firstName}`
  //   }));
  // return (
  //   <FormItem.Select
  //     key={userType.id}
  //     id={`user_${userType.id}_ids`}
  //     multiple
  //     label={userType.title}
  //     initialValue={initialValue}
  //     options={options}
  //     form={form}
  //   />
  // );
}

export default HostCompanyForm;