import dispatcher from '../dispatchers/AppDispatcher';
import ActionsBase from '../actions/ActionsBase';

import EstablishmentConstants from '../constants/EstablishmentConstants';
import EstablishmentService from '../services/EstablishmentService';

import CacheManager from '../services/CacheManager';

const CACHE_DURATION = 30 * 1000;

class EstablishmentActions extends ActionsBase {
    create = (establishment) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(newEstablishment) {
                dispatcher.dispatch({
                    type: EstablishmentConstants.RECEIVE_ESTABLISHMENT,
                    payload: {
                        establishment: newEstablishment,
                    },
                });
                resolve(newEstablishment);
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            EstablishmentService.post(establishment)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    edit = (establishmentId, establishment) => {
        const $this = this;
        return new Promise((
            resolve,
            reject
        ) => {
            function handleSuccess(newEstablishment) {
                dispatcher.dispatch({
                    type: EstablishmentConstants.RECEIVE_ESTABLISHMENT,
                    payload: {
                        establishment: newEstablishment,
                    },
                });
                resolve(newEstablishment);
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            EstablishmentService.patch(establishmentId, establishment)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    archiving = (establishmentId) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(newEstablishment) {
                dispatcher.dispatch({
                    type: EstablishmentConstants.RECEIVE_ARCHIVED_ESTABLISHMENT,
                    payload: {
                        establishment: newEstablishment,
                    },
                });
                resolve();
            }
            function handleError(err) {
                $this.handleError(err, reject);
            }
            EstablishmentService.archiving(establishmentId)
                .then(handleSuccess)
                .catch(handleError);
        });
      };

    delete = (id) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess() {
                dispatcher.dispatch({
                    type: EstablishmentConstants.DELETE_ESTABLISHMENT,
                    payload: {
                        id,
                    },
                });
                resolve();
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            EstablishmentService.remove(id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    changeCurrentEstablishmentId = (establishmentId) => new Promise((resolve, reject) => {
        dispatcher.dispatch({
            type: EstablishmentConstants.CHANGE_CURRENT_ESTABLISHMENT,
            payload: {
                establishmentId,
            },
        });
        resolve();
    });

    reload = () => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(establishments) {
                dispatcher.dispatch({
                    type: EstablishmentConstants.RECEIVE_ESTABLISHMENTS,
                    payload: {
                        establishments,
                    },
                });
                resolve();
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            EstablishmentService.getAll()
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadArchived = () => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(establishments) {
                dispatcher.dispatch({
                    type: EstablishmentConstants.RECEIVE_ARCHIVED_ESTABLISHMENTS,
                    payload: {
                        establishments,
                    },
                });
                resolve();
            }
            function handleError(err) {
                $this.handleError(err, reject);
            }
            EstablishmentService.getAllArchived()
                .then(handleSuccess)
                .catch(handleError);
        });
      };

    reloadById = (id) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            function handleSuccess(establishment) {
                dispatcher.dispatch({
                    type: EstablishmentConstants.RECEIVE_ESTABLISHMENT,
                    payload: {
                        establishment,
                    },
                });
                resolve();
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            EstablishmentService.getById(id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };

    reloadByMission = (mission) => {
        const $this = this;
        return new Promise((resolve, reject) => {
            if (
                CacheManager.isCached(
                    'Establishment:reloadByMission',
                    mission.id.toString(),
                    CACHE_DURATION,
                )
            ) {
                resolve();
                return;
            }

            function handleSuccess(establishments) {
                dispatcher.dispatch({
                    type: EstablishmentConstants.RECEIVE_ESTABLISHMENTS_OF_MISSION,
                    payload: {
                        establishments,
                        mission,
                    },
                });

                resolve();
            }

            function handleError(err) {
                $this.handleError(err, reject);
            }

            EstablishmentService.getByMission(mission.id)
                .then(handleSuccess)
                .catch(handleError);
        });
    };
}

const action = new EstablishmentActions();
export default action;
