import React from "react";

import { Table, Button, Tooltip } from "antd";
import TableColumnFilter from "components/TableColumnFilter.jsx";
import FilterIcon from "components/FilterIcon.jsx";
import CreateJobModal from "views/Job/CreateJobModal.jsx";
import EditJobModal from "views/Job/EditJobModal.jsx";
import LoadingIcon from "components/LoadingIcon.jsx";
import ToastActions from "actions/ToastActions";

import Locale, { Locale as LocaleComponent } from "locale/LocaleFactory";
import ArrayService from "services/utils/ArrayService";
import StringService from "services/utils/StringService";
import FilterService from "services/utils/FilterService";
import SecurityService from "services/SecurityService";
import Resource from "constants/Resource";
import Access from "constants/AccessLevel";

import JobActions from "actions/JobActions";
import JobStore from "stores/JobStore";
import { EditOutlined, EyeInvisibleOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";

// Sorting Methods
function sortNameColumn(c1, c2) {
  return StringService.compareCaseInsensitive(c1.name, c2.name);
}

/**
 * The list of the jobs.
 */
export default class JobList extends React.Component {
  constructor() {
    super();

    const jobs = JobStore.getJobs();

    this.state = {
      loading: !jobs.length,
      jobs,

      filteredJobs: [],

      filterGlobal: "",
      filters: {
        name: []
      },

      createJobVisible: false,
      editJobVisible: false,
      jobToEdit: null,
      archivedVisible: false,
      selectedRowKeys: [],
    };
  }

  componentDidMount() {
    this.jobListener = JobStore.addListener(
      this.receiveJobs
    );
    this.archivedJobListener = JobStore.addListener(this.receiveArchivedJobs);
    this.loadJobs();
    this.updateFilters();
  }

  componentWillUnmount() {
    this.JobListener.remove();
    this.archivedJobListener.remove();
  }

  loadJobs = () => {
    this.setState({
      loading: true
    });
    JobActions.reload().then(() => {
      this.setState({
        loading: false,
        archivedVisible: false,
        selectedRowKeys : [],
      });
    });
    this.JobListener = JobStore.addListener(this.receiveJobs);
  };

  loadArchivedJobs = () => {
    this.setState({
      loading: true,
    });
    JobActions.reloadArchived().then(() => {
      this.setState({
        loading: false,
        archivedVisible: true,
        selectedRowKeys : [],
      });
    });
    this.archivedJobListener = JobStore.addListener(this.receiveArchivedJobs);
  };

  receiveJobs = () => {
    const jobs = JobStore.getJobs();
    this.setState(
      {
        jobs
      },
      this.updateFilters
    );
  };

  receiveArchivedJobs = () => {
    this.setState(
      {
        jobs: JobStore.getArchivedJobs()
      },
      this.updateFilters
    );
  };

  // Filters
  updateFilters = () => {
    const { jobs } = this.state;
    const filteredJobs = jobs.filter(this.jobMatchFilters);
    this.setState({ filteredJobs });
  };

  jobMatchFilters = (c) => {
    const { filters } = this.state;
    return (
      FilterService.matchFilter(filters.name, c.name) &&
      this.matchGlobalSearch(c)
    );
  };

  searchGlobal = e => {
    this.setState({filterGlobal: e.target.value.toLowerCase()});
    this.updateFilters();
  };

  matchGlobalSearch = (job) => {
    const { filterGlobal } = this.state;
    return job.name.toLowerCase().indexOf(filterGlobal) > -1;
  };

  handleFilterChange = (name, values) => {
    let prevState = this.state.filters;
    prevState[name] = values;
    this.setState({filters: prevState});
    this.updateFilters();
  };

  getJobNames = () =>
    ArrayService.unique(this.state.jobs.map(c => c.name));

  showCreateJobModal = () => {
    this.setState({
      createJobVisible: true
    });
  };
  hideCreateJobModal = () => {
    this.setState({
      createJobVisible: false
    });
  };

  editJob = (job) => {
    this.setState({
      editJobVisible: true,
      jobToEdit: job
    });
  };

  hideEditJobModal = () => {
    this.setState({
      editJobVisible: false,
      jobToEdit: null
    });
  };

  archiving = (job, toasVisible) => {
    const { archivedVisible } = this.state;
    if (!job) return;
    this.setState({
      loading: true
    });
    return new Promise((resolve) => {
        JobActions.archiving(job.id).then(newJob => {
            resolve(newJob);
            this.setState({
              loading: false
            });
            if(toasVisible) {
                ToastActions.createToastSuccess(
                    archivedVisible ? `Métier "${job.name}" rétablit` : `Métier "${job.name}" archivé` 
                );
            }
        }).catch(this.handleError);
    })
  };

  archiveSelected = () => {
    const { selectedRowKeys, archivedVisible } = this.state;
    const promises = Promise.all(selectedRowKeys.forEach(r => {
      this.setState({
        loading: true
      });
      const job = archivedVisible ? JobStore.getArchivedById(r) : JobStore.getById(r);
      this.archiving(job, false);
    }));
    if(promises) {
      return Promise.all(promises).then(() => {
        ToastActions.createToastSuccess(
          archivedVisible ? "Métiers rétablis" : "Métiers archivés" 
        );
      });
    }
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  handleError = err => {
    this.setState({
      loading: false
    });
    try {
      const resp = JSON.parse(err.response);
      ToastActions.createToastError(resp.message);
    } catch (e) {
      ToastActions.createToastError("Une erreur est survenue");
    }
  };

  render() {
    const { jobToEdit, loading, filteredJobs, archivedVisible, selectedRowKeys } = this.state;
    const hasSelected = selectedRowKeys.length > 0;
    return (
      <div className="job-list">
        {!loading &&
          SecurityService.isGranted(Resource.JOB, Access.CREATE) && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={this.showCreateJobModal}
            >
              <LocaleComponent transKey="job.add" />
            </Button>
        )}
        {!loading &&
          <Button
            type="danger"
            style={{color: !archivedVisible && "#f04134", marginBottom: "1%", float: "right"}}
            ghost={!archivedVisible}
            icon={archivedVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            onClick={() => {archivedVisible ? this.loadJobs() : this.loadArchivedJobs()} }
          >
            {archivedVisible ? Locale.trans("archive.unarchived") : Locale.trans("archive.archived")}  
          </Button>
        }
        {this.renderJobTable()}

        {!loading && (
          <div
            className="actions-row"
            style={{
              marginTop:
                !filteredJobs || filteredJobs.length === 0
                  ? "10px"
                  : "-50px"
            }}
          >
            {selectedRowKeys.length > 0 && (
              <Button icon={archivedVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />} type="danger" onClick={this.archiveSelected} disabled={!hasSelected} loading={loading}>
                {archivedVisible ? `Rétablir ${selectedRowKeys.length} métier(s)` : `Archiver ${selectedRowKeys.length} métier(s)`}
              </Button>
            )}
          </div>
        )}

        <CreateJobModal
          onCancel={this.hideCreateJobModal}
          visible={this.state.createJobVisible}
        />
        <EditJobModal
          job={jobToEdit}
          onCancel={this.hideEditJobModal}
          visible={this.state.editJobVisible}
        />
      </div>
    );
  }

  renderJobTable() {
    const { filters, filteredJobs, selectedRowKeys, loading } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const columns = [
      {
        title: Locale.trans("job.name"),
        key: "name",
        sorter: sortNameColumn,
        filterIcon: <FilterIcon active={filters.name.length > 0} />,
        render: this.renderJobNameCell,
        filterDropdown: (
          <TableColumnFilter
            name="name"
            selectedValues={filters.name}
            values={this.getJobNames().map(r => ({ text: r, value: r }))}
            onChange={this.handleFilterChange}
          />
        )
      },
      {
        title: null,
        key: "actions",
        width: "50px",
        render: this.rendActionsCell
      }
    ];

    return (
      <Table
        rowSelection={rowSelection}
        dataSource={filteredJobs}
        rowKey="id"
        columns={columns}
        locale={Locale.Table}
        ref={r => {
          this.table = r;
        }}
        loading={loading && { indicator: <LoadingIcon /> }}
      />
    );
  }

  renderJobNameCell = (job) => job.name;

  rendActionsCell = (job) => (
    <div className="actions-row">
        {this.state.archivedVisible ? (
          <Tooltip title={Locale.trans("archive.unarchive")}>
            <Button
              shape="circle"
              icon={<EyeOutlined />}
              onClick={() => this.archiving(job, true)}
            />
          </Tooltip>
        ) : (
          <Tooltip title={Locale.trans("archive.action")}>
            <Button
              shape="circle"
              icon={<EyeInvisibleOutlined />}
              onClick={() => this.archiving(job, true)}
            />
          </Tooltip>
        )}
      <Tooltip title={Locale.trans("edit")}>
        <Button
          type="primary"
          shape="circle"
          icon={<EditOutlined />}
          onClick={e => {
            this.editJob(job);
            e.stopPropagation();
            e.preventDefault();
            return false;
          }}
        />
      </Tooltip>
    </div>
  );
}